<template>
  <v-app-bar
    :color="$store.getters.menuColor"
    height="40"
    class="menu-toolbar elevation-0"
    overlay-opacity="0"
    dense
    text
    hide-overlay
    dark
    clipped-left
  >
    <router-link
      :to="'/'"
    >
      <img
        alt="Aava Portal"
        src="@/assets/AAVA_logo_white.svg"
        class="aava-portal-logo"
      >
    </router-link>
    <v-btn
      v-if="$vuetify.breakpoint.mdAndDown"
      :color="$store.state.showMenuNavigationDrawer ? 'grey lighten-2' : $store.getters.menuColor"
      :light="$store.state.showMenuNavigationDrawer"
      :class="'ml-2 toolbar-btn elevation-0 ' + ($store.state.showMenuNavigationDrawer ? 'grey--text' : '')"
      height="40"
      tile
      @click="$store.state.showMenuNavigationDrawer = !$store.state.showMenuNavigationDrawer"
    >
      <v-icon small>
        fa-bars
      </v-icon>
    </v-btn>
    <div>
      <ShortcutsTopMenu />
    </div>
    <template v-if="showTopMenu">
      <v-toolbar-items
        v-for="(menuItem, index) in $store.state.menuItems"
        :key="index"
        :style="level1Style"
        class="level1"
      >
        <!-- v-toolbar-item slot-scope="{ hover }" style="position: relative;">
        <v-hover>
        </v-hover>
        </v-toolbar-item -->
        <v-btn
          :ripple="false"
          :input-value="hoverIndex === index"
          :data-test="'menu1-' + menuItem.label"
          class="menu-item"
          text
          tile
          dark
          @mouseenter="mouseEnter($event, index)"
          @click.stop="toggleShowMenu($event, index)"
        >
          <div
            :style="level1BtnContentStyle"
            class="level1-content"
          >
            {{ menuItem.label }}
          </div>
        </v-btn>
        <div
          v-if="menuItem.children && menuItem.children.length > 0"
          class="arrow-icon"
          @click.stop="toggleShowMenu($event, index)"
        >
          <v-icon small>
            fa-caret-down
          </v-icon>
        </div>
        <div
          v-if="showMenu && hoverIndex === index"
          :style="submenuStyle"
          class="level2 elevation-2"
        >
          <div
            v-for="(child, childIndex) in menuItem.children"
            :key="childIndex"
            class="menu-item-container"
            @mouseenter="mouseEnterSubmenu($event, childIndex)"
            @click.stop="openMenuItem($event, child)"
          >
            <router-link
              :to="internalUrl(child)"
              :data-test="'menu2-' + (child.url || child.label)"
              class="menu-item"
            >
              <div
                v-if="child.children && child.children.length > 0"
                class="arrow-icon"
              >
                <v-icon small>
                  fa-caret-right
                </v-icon>
              </div>
              <div style="float: left;display: contents;">
                {{ child.label }}
              </div>
            </router-link>
            <div
              v-if="childIndex === hoverIndex2"
              :style="thirdLevelMenuStyle"
              class="level3 elevation-2"
            >
              <router-link
                v-for="(child2, child2Index) in child.children"
                :key="child2Index"
                :to="internalUrl(child2)"
                :data-test="'menu3-' + child2.url"
                class="menu-item"
                @click.native.stop="openMenuItem($event, child2)"
              >
                {{ child2.label }}
              </router-link>
            </div>
          </div>
        </div>
      </v-toolbar-items>
    </template>
    <v-spacer />
    <v-chip
      v-if="$vuetify.breakpoint.lgAndUp"
      small
      @click="$store.state.showUserMenuDrawer = !$store.state.showUserMenuDrawer"
    >
      <v-icon
        class="mr-2"
        x-small
      >
        fa-user
      </v-icon>
      {{ userNameComputed }}
    </v-chip>
    <v-btn
      :color="$store.state.showUserMenuDrawer ? 'grey lighten-2' : $store.getters.menuColor"
      :light="$store.state.showUserMenuDrawer"
      :class="'ml-2 elevation-0 toolbar-btn ' + ($store.state.showUserMenuDrawer ? 'grey--text' : '')"
      data-cy="user-menu-tgl"
      height="40"
      tile
      @click="$store.state.showUserMenuDrawer = !$store.state.showUserMenuDrawer"
    >
      <v-icon small>
        fa-ellipsis-v
      </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script lang="ts">
import axios from 'axios'
import ShortcutsTopMenu from '@/components/Shortcuts/ShortcutsTopMenu.vue'
import { Types } from '@/types/AppTypes'

export default {
  name: 'MenuToolbar',

  components: {
    ShortcutsTopMenu,
  },

  data () {
    return {
      showMenu: false,
      hoverIndex: null as number | null,
      hoverIndex2: null as number | null,
      menuLeftOffset: 0,
      activeSubmenuWidth: 0,
      showThirdLevelOnRight: false,
    }
  },

  created () {
    document.addEventListener('click', this.closeMenu)
    document.addEventListener('keyup', this.keyUpListener)
  },

  destroyed () {
    document.removeEventListener('click', this.closeMenu)
    document.removeEventListener('keyup', this.keyUpListener)
  },

  computed: {
    showTopMenu () {
      const enoughSpaceForOneElement = 75
      return this.$vuetify.breakpoint.mdAndUp || this.maxMenuElementWidth > enoughSpaceForOneElement
    },

    maxMenuElementWidth () {
      const reservedSpace = this.$vuetify.breakpoint.lgAndUp
        ? 270 // 50 px for person first name
        : 200
      const maxWidth = (this.$store.state.innerWidth - reservedSpace) / (this.$store.state.menuItems.length || 1)
      return maxWidth > 100
        ? 300 // Likely many elements are less than 100 px width and there is plenty of space to show larger titles
        : maxWidth < 60
          ? 60 // Minimum to show 2-3 characters. With this size mobile navigation is also shown
          : maxWidth
    },

    level1Style () {
      return {
        maxWidth: this.maxMenuElementWidth + 'px',
      }
    },

    level1BtnContentStyle () {
      return {
        maxWidth: (this.maxMenuElementWidth - 35) + 'px',
      }
    },

    submenuStyle () {
      return {
        left: this.menuLeftOffset + 'px'
      }
    },

    thirdLevelMenuStyle () {
      const offset = 0
      const left = (this.activeSubmenuWidth + offset) + 'px'
      if (this.showThirdLevelOnRight) {
        return {
          right: left,
        }
      }
      return {
        left,
      }
    },

    userNameComputed () {
      return this.$store.state.userInfo?.person?.name?.split(' ')[0] || this.$store.state.userInfo.name
    },
  },

  methods: {
    keyUpListener (e: KeyboardEvent) {
      if (e.key !== 'Escape') { return }
      this.closeMenu()
    },

    toggleShowMenu (e: MouseEvent, index: number) {
      this.showMenu = !this.showMenu
      if (this.showMenu) {
        this.mouseEnter(e, index)
      }
    },

    openMenuItem (e: MouseEvent, item: Types.MenuItem) {
      if (item.separate) {
        this.closeMenu()
        if (item.url?.includes('system/')) {
          axios.post(window.location.origin + '/gorilla/' + item.url, '_blank')
            .then(() => {
              location.reload()
            })
        } else {
          // Otherwise, is compatibility view and open the url
          this.$router.push({ path: '/compatibility/' + item.url })
        }
      } else if (item.url && item.external_path) {
        this.closeMenu()
        window.open(window.location.origin + '/' + item.url)
      } else if (item.url) {
        this.closeMenu() // Close menu only if url exists
        // Use href link instead, to support opening in new tab/window Shift or Ctrl
        return
        // this.$router.push({ path: '/' + item.url })
      }
      e.preventDefault()
      e.stopPropagation()
    },

    mouseEnter (e: MouseEvent, index: number) {
      this.$nextTick(() => {
        const targetElement = e.target as HTMLElement
        this.menuLeftOffset = targetElement?.offsetLeft
        this.hoverIndex = index
        this.hoverIndex2 = null
      })
    },

    mouseEnterSubmenu (e: MouseEvent, index: number) {
      this.showThirdLevelOnRight = this.$store.state.innerWidth - e.pageX < 420
      this.$nextTick(() => {
        const targetElement = e.target as HTMLElement
        this.activeSubmenuWidth = targetElement?.clientWidth
        this.hoverIndex2 = index
      })
    },

    closeMenu () {
      this.hoverIndex = null
      this.hoverIndex2 = null
      this.showMenu = false
    },

    internalUrl (menuItem: Types.MenuItem) {
      if (menuItem.separate || menuItem.external_path) { return '' }
      return menuItem.url ? ('/' + menuItem.url) : ''
    },
  },
}
</script>

<style lang="scss">
.menu-toolbar {
  z-index: 200 !important; /* TODO test. was 500 but has to be less than 201 which is dialog */
  a:hover {
    text-decoration: none;
  }
  .toolbar-btn.v-btn {
    min-width: inherit !important;
  }
  .v-toolbar__items {
    .v-btn {
      border-radius: 0 !important;
    }
  }
  .v-btn {
    font-weight: 400 !important;
  }
  .level1 {
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    .level1-content {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .menu-item {
      position: relative;
      cursor: pointer;
      font-size: 13px;
      padding: 0 30px 0 10px !important;
      color: #fff !important;
      .v-btn__content {
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .arrow-icon {
      cursor: pointer;
      position: absolute;
      right: 9px;
      top: 9px;
    }
    .level2 {
      position: absolute;
      font-size: 13px;
      top: 40px;
      padding: 10px 0;
      background: white;
      z-index: 1;
      .menu-item-container {
        position: relative;
        background: white;
        &:hover {
          background: #eee;
        }
        .menu-item {
          display: block;
          min-width: 200px;
          padding: 3px 25px 3px 15px !important;
          line-height: 20px;
          height: 28px;
          color: #4a4a4a !important;
          .theme--dark.v-icon {
            color: #666 !important;
          }
          .arrow-icon {
            position: relative;
            right: -10px;
            top: 0;
            float: right;
          }
        }
        .level3 {
          top: -10px;
          position: absolute;
          padding: 10px 0 !important;
          background: white;
          z-index: 2;
          .menu-item {
            min-width: 110px;
            padding: 4px 15px;
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            &:hover {
              background: #eee;
            }
          }
        }
      }
    }
  }
}
.aava-portal-logo {
  height: 28px;
  padding-top: 4px;
  margin-right: 5px;
}
</style>
