<template>
  <div class="layout-profile-menu white elevation-1 text-left">
    <template>
      <MenuOption
        v-if="!!layoutProfile.global_view && !!layoutProfile.locked && $store.getters.isAdminUser"
        :label="$i18n.t('aava.actions.take_ownership')"
        icon="fa fa-user-check"
        data-cy="take-ownership"
        @click="takeOwnership"
      />
      <template v-if="isList">
        <MenuOption
          v-for="(componentOption, index) in visibleIndexComponentOptions"
          :key="index"
          :label="menuItemLabel(componentOption)"
          :icon="'fa fa-' + componentOption.icon"
          :class="'menu-btn-' + componentOption.component"
          @click="changeIndexComponent(componentOption)"
        />
        <div class="menu-separator-line" />
        <MenuOption
          :value="layoutProfile.timelineConfiguration.showItemCount"
          :label="$i18n.t('aava.actions.hide_item_count')"
          :toggle-label="$i18n.t('aava.actions.show_item_count')"
          icon="fa fa-hashtag"
          class="btn-show-count"
          @click="toggleShowCount"
        />
        <MenuOption
          v-if="layoutProfile.in_dashboard"
          :value="layoutProfile.in_dashboard"
          :label="$i18n.t('aava.actions.hide_dashboard')"
          :toggle-label="$i18n.t('aava.actions.show_dashboard')"
          icon="fa fa-star"
          toggle-icon="far fa-star"
          class="btn-hide-from-dashboard"
          @click="toggleInDashboard"
        />
        <v-menu
          v-else
          :z-index="160"
          right
          offset-x
          open-on-hover
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="popup-menu-el btn-show-on-dashboard"
              v-on="on"
            >
              <a href="javascript:;">
                <span
                  class="far fa-star"
                />
                {{ $i18n.t('aava.actions.show_dashboard') }}
              </a>
            </div>
          </template>
          <div class="popup-items-container">
            <div
              v-for="tab in $store.state.dashboardTabs"
              :key="tab.id"
              class="popup-menu-el btn-dashboard-tab"
              @click.stop="toggleInDashboard(tab)"
            >
              <span class="fa fa-plus" />
              {{ tab.name }}
            </div>
          </div>
        </v-menu>
      </template>
      <MenuOption
        :label="$i18n.t('aava.actions.edit')"
        :disabled="isLocked && layoutProfile.global_view"
        icon="fa fa-edit"
        class="btn-edit"
        @click="showLayoutProfileEditForm"
      />
      <MenuOption
        v-if="layoutProfile.action !== 'index'"
        :label="$i18n.t('aava.actions.edit_layout')"
        :disabled="isLocked || itemEdit || !!splitMode"
        icon="fa fa-edit"
        class="btn-edit-layout"
        @click="showItemLayoutEditMode"
      />
      <MenuOption
        :value="layoutProfile.publish"
        :label="$i18n.t('aava.actions.unpublish')"
        :toggle-label="$i18n.t('aava.actions.publish')"
        :disabled="isLocked"
        icon="fa fa-share-alt"
        class="btn-publish"
        @click="togglePublish"
      />
      <MenuOption
        v-if="metaInfo.view_admin"
        :value="!!layoutProfile.global_view"
        :label="$i18n.t('aava.actions.remove_global_view')"
        :toggle-label="$i18n.t('aava.actions.add_global_view')"
        :disabled="isLocked"
        icon="fa fa-users"
        class="btn-global-view"
        @click="toggleGlobalView"
      />
      <MenuOption
        v-if="isExportable"
        :label="$i18n.t('aava.actions.export_global_view')"
        :disabled="isLocked"
        icon="fa fa-file-export"
        class="btn-export-global-view"
        @click="exportGlobalView"
      />
      <MenuOption
        :label="$i18n.t('aava.actions.remove')"
        :disabled="isLocked && layoutProfile.global_view"
        icon="fa fa-trash"
        class="btn-delete"
        @click="deleteLayoutProfile"
      />
      <MenuOption
        v-if="notificationServiceIsActivated || !notificationServiceIsActivated"
        :label="$i18n.t('aava.no_se.title')"
        :disabled="false"
        icon="fa fa-bell"
        class="btn-nose"
        @click="showNotificationServices"
      />
    </template>
  </div>
</template>

<script lang="ts">
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import MenuOption from '@/components/App/MenuOption.vue'
import layoutEditMethods from '@/components/Item/LayoutEditor/layoutEditMethods'
import api from '@/store/api'
import i18n from '@/locale/i18n'
import { PropType } from 'vue'
import { LP } from '@/types/LP.types'
import { AxiosResponse } from 'axios'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

interface ComponentOption {
  component: string
  // fa icon name (without 'fa-')
  icon: string
  label: string
  // TODO add comment
  viewName?: string
  // Added to the main label. Ex card view has multiple views
  viewLabel?: string
}

const indexComponentOptions: ComponentOption[] = [{
  component: 'list',
  icon: 'list-alt',
  label: 'aava.index.list.title'
}, {
  component: 'timeline',
  icon: 'clock',
  label: 'aava.index.timeline.title'
}, {
  component: 'breakdown',
  icon: 'angle-double-right',
  label: 'aava.index.breakdown.title'
}, {
  component: 'pivot_table',
  icon: 'table',
  label: 'aava.index.pivottable.title'
}]

export default {
  name: 'LayoutProfileMenu',

  components: {
    MenuOption,
  },

  props: {
    layoutProfile: {
      type: Object as PropType<LP.LayoutProfile>,
      required: true,
    },
    itemEdit: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      indexComponentOptions,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    // Is exportable as global view
    isExportable () {
      return this.isSelectedItemLayoutProfile && // View must be selected
        this.metaInfo.view_admin && // Is view admin
        !this.$store.getters.isProductionBackend && // Not in production env
        this.layoutContainerFieldSets[this.selectedItemLayoutProfileId]?.length // Must have FieldSets
    },

    layoutProfileIdForEdit () {
      return this.layoutProfile.id
    },

    notificationServiceIsActivated () {
      return this.$store.state.systemConfigs?.notification_service === 'activated'
    },

    visibleIndexComponentOptions (): ComponentOption[] {
      const options: ComponentOption[] = indexComponentOptions.slice()
      this.cardViews.forEach(element => {
        options.push(
          {
            component: 'card_view',
            icon: 'columns',
            label: 'aava.index.cardview.title',
            viewName: element.name,
            viewLabel: element.label,
          })
      })
      return options
    },

    layoutProfilesComputed () {
      return this.isList
        ? this.$store.state.layoutProfiles
        : this.itemLayoutProfilesByModel[this.layoutProfile.controller]
    },

    isList () {
      return this.layoutProfile.action === 'index'
    },

    isLocked () {
      return this.layoutProfile.locked
    },

    selectedItemLayoutProfileId (): number {
      return this.selectedLayoutProfileIdByModel[this.objectClass]
    },

    isSelectedItemLayoutProfile () {
      return !this.isList && this.layoutProfile?.id === this.selectedItemLayoutProfileId
    },

    itemLayoutContainers () {
      return this.layoutContainers[this.selectedItemLayoutProfileId]
    },

    itemLayoutContainerFieldSets () {
      return this.layoutContainerFieldSets[this.selectedItemLayoutProfileId]
    },
  },

  methods: {
    ...methods,
    ...layoutEditMethods,

    menuItemLabel (componentOption: ComponentOption) {
      if (!componentOption.viewLabel) {
        return this.$i18n.t(componentOption.label)
      } else {
        return this.$i18n.t(componentOption.label) + ' - ' + componentOption.viewLabel
      }
    },

    changeIndexComponent (componentOptions: ComponentOption) {
      let viewOptions: any = null
      const forSelectedLayoutProfile = this.layoutProfile.id === this.selectedLayoutProfileId
      if (componentOptions.component === 'card_view') {
        viewOptions = { cardView: componentOptions.viewName }
      }
      this.$store.dispatch('switchLayoutProfileView', {
        id: this.layoutProfile.id,
        view: componentOptions.component,
        viewOptions,
        forSelectedLayoutProfile,
      })

      this.closeLayoutProfileMenu()
      if (forSelectedLayoutProfile) {
        // Change current list view only when change was made to selected layout profile
        this.layoutProfileView = componentOptions.component + (componentOptions.viewName || '')
      }
    },

    showLayoutProfileEditForm () {
      this.closeLayoutProfileMenu()
      this.$emit('showForm')
    },

    // Deletes Layout Profile for the list or show form
    deleteLayoutProfile () {
      const resource = this.layoutProfile.controller
      const selectedLayoutProfileId = this.layoutProfile.action === 'index'
        ? this.selectedLayoutProfileId
        : this.selectedLayoutProfileIdByModel[resource]
      if (selectedLayoutProfileId === this.layoutProfile.id) {
        this.selectPreviousLayoutProfile(this.layoutProfile.id)
      }
      this.$store.dispatch('deleteLayoutProfile', {
        layoutProfiles: this.layoutProfilesComputed,
        layoutProfileForDelete: this.layoutProfile,
      })
        .then(() => {
          this.$nextTick(() => {
            this.handleLastVisibleLayoutProfileDelete(resource)
            this.setVisibleLayoutProfileTabsCount()
          })
        })
      this.closeLayoutProfileMenu()
    },

    handleLastVisibleLayoutProfileDelete (resource: string) {
      // Get LPs without context = 'dialog'
      const withoutContext = JSON.parse(JSON.stringify(this.layoutProfilesComputed))
        .filter((layoutProfile: LP.LayoutProfile) => {
          return !(layoutProfile.locked && layoutProfile.global_view && layoutProfile.context === 'dialog')
        })
      if (withoutContext.length === 0) { // No visible profiles left
        // Some hidden profiles are left, must create new default one from the portal side
        if (this.layoutProfilesComputed.length) {
          this.$store.dispatch('createNewLayoutProfile', {
            data: {
              createDefault: true,
            },
            layoutProfile: {
              action: 'show',
              controller: resource,
            },
          })
            .then(() => {
              location.reload()
            })
        } else {
          // Last LP was deleted, reload page to trigger auto LP creation on back-end side
          location.reload()
        }
      }
    },

    selectPreviousLayoutProfile (id: number) {
      let selectIndex = null
      this.layoutProfilesComputed.forEach((item, index) => {
        if (item.id === id) {
          selectIndex = index === 0
            ? index + 1
            : index - 1
        }
      })
      if (selectIndex !== null && this.layoutProfilesComputed[selectIndex]) {
        if (this.layoutProfilesComputed[selectIndex].action === 'index') {
          this.selectLayoutProfile(this.layoutProfilesComputed[selectIndex])
        } else {
          this.selectItemLayoutProfile(this.layoutProfilesComputed[selectIndex])
        }
      }
    },

    toggleShowCount () {
      this.$store.dispatch('toggleLayoutProfileShowCount', this.layoutProfile.id)
        .then(() => {
          // Calling update tabs this because now tab can take more or less space
          this.setVisibleLayoutProfileTabsCount()
        })
      this.closeLayoutProfileMenu()
    },

    toggleInDashboard (tab = null) {
      this.$store.dispatch('toggleLayoutProfileInDashboard', {
        tab,
        layoutProfile: this.layoutProfile,
      })
      this.closeLayoutProfileMenu()
    },

    togglePublish () {
      this.$store.dispatch('toggleLayoutProfilePublish', this.layoutProfile)
        .then(() => {
          // Calling update tabs this because now tab can take more or less space
          this.setVisibleLayoutProfileTabsCount()
        })
      this.closeLayoutProfileMenu()
    },

    takeOwnership () {
      const cloneId = this.layoutProfile.id
      // 1. Get cloned LP data to include cloned_from (gorilla service is missing that)
      api.fetchItemInfo('layout_profiles', cloneId, []).then((response: AxiosResponse) => {
        const parentId = response.data.item?.cloned_from?.id
        if (!parentId) {
          return this.$store.dispatch('addFlashMessage', {
            message: 'Take LP ownership - missing parent',
            type: 'error',
          })
        }
        // 2. Get the owner of the original global view
        api.fetchItemInfo('layout_profiles', parentId, []).then((r2: AxiosResponse) => {
          const previousOwnerId = r2.data.item?.owner.id
          if (!previousOwnerId) {
            return this.$store.dispatch('addFlashMessage', {
              message: 'Take LP ownership - missing previousOwnerId',
              type: 'error',
            })
          }
          // 3. Take ownership of the parent (main global view)
          return api.sendPutWithPayloadRequest('/api/layout_profiles/' + parentId, [], {
            owner: { id: this.userInfo.id },
            user_account: { id: this.userInfo.id },
          }).then((r3: AxiosResponse) => {
            this.$store.dispatch('globalErrorDisplay', { response: r3, context: 'Take LP ownership' })
            // 4. Change owner for cloned - now to be owned by the original global view admin
            return api.sendPutWithPayloadRequest('/api/layout_profiles/' + cloneId, [], {
              owner: { id: previousOwnerId },
              user_account: { id: previousOwnerId },
            }).then((r4: AxiosResponse) => {
              this.$store.dispatch('globalErrorDisplay', { response: r4, context: 'Take LP ownership - change cloned owner' })
              this.closeLayoutProfileMenu()
              this.clearCacheAndReload()
              location.reload()
            })
          })
        })
      })
    },

    toggleGlobalView () {
      this.$store.dispatch('toggleLayoutProfileGlobalView', this.layoutProfile).then((response: AxiosResponse) => {
        this.$store.dispatch('globalErrorDisplay', { response, context: 'Global view toggle ' })
        if (response.data.status === 'ok') {
          this.$store.dispatch('showMessage', {
            message: 'OK',
            type: 'success',
            expires: 1000,
          })
        }
      })
      this.closeLayoutProfileMenu()
    },

    exportGlobalView () {
      const fields = this.layoutProfileItemsById[this.selectedItemLayoutProfileId]
      const exportJSON = this.getItemLayoutExportJSON(this.layoutProfile, this.itemLayoutContainers, this.itemLayoutContainerFieldSets, fields)
      api.sendLayoutProfileGlobalViewOperation(this.layoutProfile.id, 'export', exportJSON).then((response: AxiosResponse) => {
        this.$store.dispatch('globalErrorDisplay', { response, context: 'Global view export ' })
        if (response.data.status === 'ok') {
          this.$store.dispatch('showMessage', {
            message: i18n.t('aava.actions.export_global_view') + ' - OK',
            type: 'success',
            expires: 3000,
          })
        }
      })
      this.closeLayoutProfileMenu()
    },

    showItemLayoutEditMode () {
      this.itemLayoutEditMode = true
      this.suppressToolbar = true
      this.closeLayoutProfileMenu()
    },

    showNotificationServices () {
      this.noSeView = true
      this.closeLayoutProfileMenu()
    },

    closeLayoutProfileMenu () {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.layout-profile-menu {
  padding: 18px 0 12px 0;
  max-width: 200px;
  min-width: 200px;
  .col {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .v-input--selection-controls {
    margin: 0;
    padding: 0;
  }
}
.menu-separator-line {
  width: 100%;
  border-top: 1px solid #ddd;
  margin-top: 10px;
  height: 1px;
  padding-bottom: 10px;
  background: white;
}
</style>
