<template>
  <div>
    <v-menu
      v-model="showMenu"
      :z-index="160"
      offset-y
      close-on-click
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="'#ddd'"
          class="text-none"
          text
          tile
          depressed
          v-bind="attrs"
          v-on="on"
        >
          <i class="fa fa-bars icon-right-space" />
          {{ $vuetify.breakpoint.lgAndUp && splitMode !== 'vertical' ? $i18n.t('aava.actions.title') : '' }}
        </v-btn>
      </template>
      <div class="popup-items-container">
        <template v-if="runningAction">
          <v-progress-linear
            :indeterminate="true"
            height="3"
            color="teal"
          />
        </template>
        <template v-else>
          <v-menu
            v-for="(actionButton, index) in $store.getters.actionsForList"
            :key="index"
            :z-index="160"
            offset-x
            left
            open-on-hover
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                :class="'popup-menu-el ' + (!isEnabled(actionButton) ? 'menu-el-disabled' : '')"
                v-bind="attrs"
                v-on="on"
                @click.stop="clickHandler(actionButton)"
              >
                <template>
                  <span
                    v-if="actionButton.query && actionButton.query.type === 'list'"
                    class="fa fa-caret-left"
                  />
                  <a
                    href="javascript:;"
                  >
                    {{ actionButton.label }}
                  </a>
                </template>
              </div>
            </template>
            <template v-if="actionButton.query && actionButton.query.type === 'list'">
              <div class="popup-items-container">
                <div
                  v-for="(subButton, index2) in actionButton.query.values"
                  :key="index2"
                  :class="'popup-menu-el ' + (!isEnabled(actionButton) ? 'menu-el-disabled' : '')"
                  @click.stop="runApiActionWithValue(actionButton, subButton.value)"
                >
                  {{ subButton.label }}
                </div>
              </div>
            </template>
          </v-menu>
        </template>
      </div>
    </v-menu>
  </div>
</template>

<script lang="ts">
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import itemMetaActionMethods from '@/methods/item/itemMetaActionMethods'
import { MetaAction } from '@/types/LP.types'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'ListMetaActionsMenu',

  data () {
    return {
      runningAction: false,
      showMenu: false,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    ...methods,
    ...itemMetaActionMethods,

    isEnabled (actionButton: MetaAction) {
      return !actionButton.requires_ids || this.selectedItems.length
    },

    clickHandler (actionButton: MetaAction) {
      if (!this.isEnabled(actionButton)) {
        return
      }
      this.showMenu = false
      this.confirmSendAction(actionButton)
    },
  },
}
</script>
