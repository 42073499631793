<template>
  <v-dialog
    v-model="showListColumnsSelector"
    content-class="columns-selector-menu"
    @click.stop=""
  >
    <v-toolbar
      color="grey lighten-2"
      class="elevation-0"
      fixed
    >
      <v-toolbar-title class="pl-3">
        {{ $i18n.t('aava.index.columns') }}
      </v-toolbar-title>
      <v-spacer />
      <SwitchField
        v-if="!props.forHasMany"
        v-model="reloadListOnReturn"
        :label="$i18n.t('aava.userSettings.reload_list_on_return')"
        class="mr-3"
      />
      <v-toolbar-items>
        <v-btn
          color="grey darken-1"
          class="col-sel-save"
          text
          depressed
          @click="callSaveCallback"
        >
          <v-icon
            class="pr-2"
            color="green"
            small
          >
            fa-save
          </v-icon>
          {{ $i18n.t('aava.actions.save') }}
        </v-btn>
        <v-btn
          color="grey darken-1"
          class="col-sel-add-all"
          text
          depressed
          @click="showAll"
        >
          <v-icon
            class="pr-2"
            small
          >
            fa-plus
          </v-icon>
          {{ $i18n.t('aava.buttons.show_all') }}
        </v-btn>
        <v-btn
          color="grey darken-1"
          class="col-sel-remove-all"
          text
          depressed
          @click="hideAll"
        >
          <v-icon
            class="pr-2"
            small
          >
            fa-minus
          </v-icon>
          {{ $i18n.t('aava.buttons.show_none') }}
        </v-btn>
        <v-btn
          color="grey darken-1"
          class="col-sel-cancel"
          text
          tile
          depressed
          @click="showListColumnsSelector = false"
        >
          <v-icon
            small
            class="pr-2"
            color="red"
          >
            fa-times
          </v-icon>
          {{ $i18n.t('aava.actions.cancel') }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-container
      v-if="render"
      id="columns-selector-columns"
      class="elevation-0 pa-0 pt-2 white"
      fluid
    >
      <v-row>
        <v-col>
          <v-container>
            <v-row dense>
              <v-col
                cols="6"
                class="px-3"
              >
                <v-text-field
                  v-model="searchTermForVisibleColumns"
                  :label="$i18n.t('aava.index.visible_columns')"
                  autocomplete="off"
                  class="search-input-selected-cols"
                  outlined
                  rounded
                  hide-details
                  dense
                >
                  <template v-slot:prepend-inner>
                    <v-icon
                      class="pt-1 pr-1"
                      color="grey lighten-1"
                      small
                    >
                      fa-search
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                cols="6"
                class="px-3"
              >
                <v-text-field
                  v-model="searchTermForAvailableColumns"
                  :label="$i18n.t('aava.index.hidden_columns')"
                  class="search-input-available-cols"
                  autocomplete="off"
                  outlined
                  hide-details
                  dense
                  rounded
                >
                  <template v-slot:append-outer>
                    <v-btn
                      :text="!sortCondition"
                      class="ml-2"
                      height="40"
                      color="grey lighten-1"
                      rounded
                      dark
                      depressed
                      @click="toggleSortCondition"
                    >
                      <v-icon small>
                        {{ sortCondition === 'type' ? 'fa-sort-amount-down-alt' : 'fa-sort-alpha-down' }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:prepend-inner>
                    <v-icon
                      class="pt-1 pr-1"
                      color="grey lighten-1"
                      small
                    >
                      fa-search
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-container
                  v-if="selectedLayoutProfile && selectedLayoutProfile.timelineConfiguration"
                  id="selected-columns"
                  class="columns-selector-items"
                >
                  <ColumnsSelectorField
                    v-for="field in selectedColumns
                      .filter(field => (!searchTermForVisibleColumns ||
                        (field.translation || field.name).toLowerCase().includes(searchTermForVisibleColumns.toLowerCase())))"
                    :key="field.id"
                    :field="field"
                    :list-first-item="listFirstItem"
                    :search-term="searchTermForVisibleColumns"
                    :sample-item="sampleItem"
                    container-name="selected-columns"
                    @toggleFieldVisibility="toggleFieldVisibility"
                    @listEditableFieldToggleHandler="listEditableFieldToggleHandler"
                    @hasManyToggleClickHandler="hasManyToggleClickHandler"
                  />
                </v-container>
              </v-col>
              <v-col cols="6">
                <v-container
                  v-if="selectedLayoutProfile && selectedLayoutProfile.timelineConfiguration"
                  id="available-columns"
                  class="columns-selector-items"
                >
                  <ColumnsSelectorField
                    v-for="field in availableColumns
                      .filter(field => (!searchTermForAvailableColumns ||
                        (field.translation || field.name).toLowerCase().includes(searchTermForAvailableColumns.toLowerCase())))"
                    :key="field.id"
                    :field="field"
                    :list-first-item="listFirstItem"
                    :search-term="searchTermForAvailableColumns"
                    :sample-item="sampleItem"
                    container-name="available-columns"
                    @toggleFieldVisibility="toggleFieldVisibility"
                    @hasManyToggleClickHandler="hasManyToggleClickHandler"
                    @listEditableFieldToggleHandler="listEditableFieldToggleHandler"
                  />
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script lang="ts">
import Sortable from 'sortablejs'
import methods from '@/components/methods'
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import listQueries from '@/store/_listQueries'
import listViewAPI from '@/store/api'
import listFilters from '@/store/_listFilters'
import ColumnsSelectorField from '@/components/ListHeaderActions/ActionMenus/ColumnsSelectorField.vue'
import translateAttribute from '@/utilities/translateAttribute'
import SwitchField from '../../Form/SwitchField.vue'
import listCellEditMethods from '@/methods/listItem/listCellEditMethods'
import { AxiosResponse } from 'axios'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'ListColumnsSelectorDialog',

  components: {
    SwitchField,
    ColumnsSelectorField,
  },

  data () {
    return {
      render: true,
      saving: false,
      sampleItem: null,
      sortable1: null,
      sortable2: null,
      columns: [],
      searchTermForVisibleColumns: null,
      searchTermForAvailableColumns: null,
      sortCondition: 'name',
      reloadListOnReturn: true,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    props () {
      return this.columnsSelectorProps
    },

    layoutProfileItemsComputed () {
      return this.props.layoutProfileItems.map(field => {
        field.translation = translateAttribute(this.props.objectClass, field.name, this.locale, this.$i18n)
        return field
      }).filter(field => (
        // Filter out units, currencies and rates etc
        // Used when selecting columns for has-many, not needed for list, as LPIs are used there
        field.visible_on_show || !this.props.forHasMany
      )).sort((a, b) => a.sort_order < b.sort_order ? -1 : 1)
        .map((field, i) => {
          field.index = i
          return field
        })
    },

    selectedColumns () {
      return this.columns.filter(field => field.visible)
    },

    availableColumns () {
      if (this.sortCondition) {
        return this.columns.filter(field => !field.visible)
          .sort((a, b) => {
            const nameSort = (a.translation < b.translation) ? -1 : (a.translation > b.translation) ? 1 : 0
            if (this.sortCondition === 'name') {
              return nameSort
            }
            return (a.type < b.type) ? -1 : (a.type > b.type) ? 1 : nameSort
          })
      }
      return this.columns.filter(field => !field.visible)
    },

    listFirstItem () {
      return this.listItems[0]
    },
  },

  created () {
    if (!this.props.forHasMany) {
      this.reloadListOnReturn = 'doNotReloadListOnReturn' in this.props.selectedLayoutProfile.timelineConfiguration
        ? !this.props.selectedLayoutProfile.timelineConfiguration.doNotReloadListOnReturn
        : true
    }
    this.columns = JSON.parse(JSON.stringify(this.layoutProfileItemsComputed))
    this.getColumnsSelectorPreviewDataForAllFields()
    this.reSortColumns()
    this.createColumnsSortables()
  },

  methods: {
    ...methods,
    ...listCellEditMethods,

    showAll () {
      this.columns = this.columns.map(field => {
        field.visible = true
        return field
      })
    },

    hideAll () {
      this.columns = this.columns.map(field => {
        field.visible = false
        return field
      })
    },

    createColumnsSortables () {
      this.$nextTick(() => {
        this.sortable1 = null
        this.sortable2 = null
        const el1 = document.getElementById('selected-columns')
        const el2 = document.getElementById('available-columns')
        if (!el1 || !el2) { return }
        this.sortable1 = Sortable.create(el1, {
          group: 'shared',
          onEnd: this.columnDragEnd,
          chosenClass: 'sortable-chosen',
          ghostClass: 'sortable-ghost',
        })
        this.sortable2 = Sortable.create(el2, {
          group: 'shared',
          onEnd: this.columnDragEnd,
          chosenClass: 'sortable-chosen',
          ghostClass: 'sortable-ghost',
        })
      })
    },

    reSortColumns () {
      this.columns = JSON.parse(JSON.stringify(
        this.columns.sort((a, b) => a.sort_order < b.sort_order ? -1 : 1)
          .map((field, i) => {
            field.index = i
            return field
          })
      ))
    },

    columnDragEnd () {
      const elements: HTMLCollection = document.getElementsByClassName('field-chip-container')
      Array.from(elements).forEach((rawEl, newIndex) => {
        const el = rawEl as HTMLElement
        const currentIndex = el.dataset.currentIndex
        if (currentIndex !== undefined) {
          if (el.parentElement!.id !== el.dataset.parentId) {
            this.$set(this.columns[currentIndex], 'visible', !this.columns[currentIndex].visible)
          }
          this.$set(this.columns[currentIndex], 'sort_order', newIndex + 1)
        }
      })
      this.reSortColumns()
      this.reRender()
    },

    reRender () {
      const el = document.getElementById('columns-selector-columns')
      const scrollTop = (el && el.scrollTop) || 0
      this.render = false
      this.$nextTick(() => {
        this.render = true
        this.$nextTick(() => {
          this.createColumnsSortables()
          this.$nextTick(() => {
            // Restore scroll position after re-render
            const el = document.getElementById('columns-selector-columns')
            if (el) {
              el.scrollTop = scrollTop
            }
          })
        })
      })
    },

    getColumnsSelectorPreviewDataForAllFields () {
      const queries = listQueries.get(this.props.layoutProfileItems, this.selectedLayoutProfile, {
        locale: this.locale,
        includeAllFields: true,
      })
      listViewAPI.getListOneItem(
        this.props.objectClass,
        // listFilters.get(1, 0, this.$store.state),
        {},
        queries,
        // When selecting columns for item has-many, provide id of the item which is first in the has-many list
        // Could be empty, then last changed item is used
        this.props.forHasMany ? this.props.previewItemId : this.listFirstItem?.id
      ).then((response: AxiosResponse) => {
        this.sampleItem = response.data.items?.[0] || {}
      })
    },

    hasManyToggleClickHandler (toggleFieldName) {
      let hasManyFieldsWithData = this.selectedLayoutProfile.timelineConfiguration.hasManyFieldsWithData || []
      if (hasManyFieldsWithData.includes(toggleFieldName)) {
        hasManyFieldsWithData = hasManyFieldsWithData.filter(fieldName => fieldName !== toggleFieldName)
      } else {
        hasManyFieldsWithData.push(toggleFieldName)
      }
      this.$set(this.selectedLayoutProfile.timelineConfiguration, 'hasManyFieldsWithData', hasManyFieldsWithData)
    },

    listEditableFieldToggleHandler (field) {
      this.$set(field, 'editable_in_list', !field.editable_in_list)
    },

    closeMenu () {
      this.showListColumnsSelector = false
    },

    callSaveCallback () {
      this.closeMenu()
      // Different actions when opened from the main list, or from item has-many list
      this.props.callback({ columns: this.columns, reloadListOnReturn: this.reloadListOnReturn })
    },

    toggleFieldVisibility (field) {
      if (!field.visible) {
        this.moveAddedColumnToLastPlace(field)
      }
      this.$set(field, 'visible', !field.visible)
      this.reSortColumns()
      this.reRender()
    },

    toggleSortCondition () {
      this.sortCondition = this.sortCondition
        ? this.sortCondition === 'name'
          ? 'type'
          : ''
        : 'name'
    },

    moveAddedColumnToLastPlace (field) {
      // Get current max visible column order number
      const maxSortOrder = this.selectedColumns[this.selectedColumns.length - 1]?.sort_order
      if (!maxSortOrder) { return }
      if (field.sort_order < maxSortOrder) {
        this.columns.forEach(column => {
          if (column.sort_order > field.sort_order && column.sort_order <= maxSortOrder) {
            column.sort_order--
          }
        })
        field.sort_order = maxSortOrder
      }
    },
  },
}
</script>

<style lang="scss">
.columns-selector-menu {
  height: 90% !important;
}
#columns-selector-columns {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 64px) !important;
  .v-input__append-outer {
    margin: 0 !important;
  }
}
#selected-columns {
  .available-icon {
    display: none;
  }
}
#available-columns {
  .selected-icon {
    display: none;
  }
}
.columns-selector-items {
  .v-input--selection-controls {
    margin: 0;
    padding: 0;
  }
}
.columns-selector-loader {
  min-width: 300px;
  padding: 20px;
}
.columns-selector-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
}
.field-select-btn {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 50% !important;
  justify-content: left !important;
  background: rgba(150, 150, 150, 0.1) !important;
  .v-btn__content {
    text-align: left !important;
    overflow: hidden;
    display: block;
    padding-right: 20px;
    width: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.field-chip {
  background: white;
  transition: .15s linear !important;
  .fa-plus {
    color: #ccc !important;
  }
  .fa-minus {
    color: #ccc !important;
  }
}
.sortable-ghost {
  .field-chip {
    opacity: 1 !important;
    background: white !important;
    border: 1px solid grey !important;
    .v-chip__content {
      opacity: 1;
      display: flex !important;
      font-weight: 700;
    }
  }
}
.select-field-title {
  width: calc(50% - 24px);
  text-align: left;
  padding-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
