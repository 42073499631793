<template>
  <v-progress-linear
    v-if="noSeLoading"
    :indeterminate="true"
    height="3"
    color="teal"
  />
  <div
    v-else
    class="no-se-notifications"
  >
    <v-data-table
      :headers="headers"
      :items="noSeSharedNotifications"
      :loading="noSeLoading"
      :hide-default-footer="true"
      :no-data-text="$i18n.t('aava.no_se.no_shared_notifications')"
      class="elevation-1"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="blue"
          indeterminate
        />
      </template>
      <template
        v-slot:item="props"
      >
        <tr
          class="rowClass"
        >
          <td>
            <v-chip
              v-if="props.item.subscribed"
              class="ma-2"
              color="green darken-1"
              dark
              @click="subscribe(props.item, false)"
            >
              {{ $i18n.t('aava.no_se.subscribed') }}
            </v-chip>
            <v-chip
              v-if="!props.item.subscribed"
              class="ma-2"
              color="grey lighten-1"
              dark
              @click="subscribe(props.item, true)"
            >
              {{ $i18n.t('aava.no_se.not_subscribed') }}
            </v-chip>
          </td>
          <td>
            {{ props.item.owner }}
          </td>
          <td>
            {{ props.item.identifier }}
          </td>
          <td>
            <span
              v-if="props.item.event_trigger"
            >
              {{ $i18n.t('aava.no_se.type_event') }}
            </span>
            <span
              v-else
            >
              {{ $i18n.t('aava.no_se.type_group') }}
            </span>
          </td>
          <td>
            {{ props.item.description }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import { AxiosResponse } from 'axios'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'NoSeSharedNotifications',

  data () {
    return {
      tab: null,
      headers: [
        {
          text: this.$i18n.t('aava.no_se.status'),
          align: 'left',
          sortable: false
        },
        {
          text: this.$i18n.t('aava.no_se.owner'),
          align: 'left',
          sortable: false
        },
        {
          text: this.$i18n.t('aava.no_se.name'),
          align: 'left',
          sortable: false
        },
        {
          text: this.$i18n.t('aava.no_se.type'),
          align: 'left',
          sortable: false
        },
        {
          text: this.$i18n.t('aava.no_se.description'),
          align: 'left',
          sortable: false
        }
      ]
    }
  },

  computed: {
    ...mapFields(Object.keys(state))
  },

  methods: {
    subscribe (item, subscribe) {
      this.$store.dispatch('noSeSubscribe', { item, subscribe }).then((success: boolean) => {
        if (success) {
          item.subscribed = subscribe
        }
      })
    },
  },
}
</script>
