import { Types } from '@/types/AppTypes'
import api from '@/store/api'
import { AxiosResponse } from 'axios'
import util from '@/utilities/sharedUtilities'
import Vue from 'vue'

export function addNewHasManyItemToStore (item: Types.Item) {
  return new Promise(resolve => {
    const itemIndexWithSameId = this.items.findIndex((i: Types.Item) => i.id === item.id)
    // If item with same id already added, skip
    if (!item || (
      !(item.token ||
        (item.id && this.isSimpleMultiselect) // When edit-in-list for simple_multiselect
      ) &&
      itemIndexWithSameId > -1)) {
      return resolve(null)
    }
    // Set target object for reference field queries with parent props
    if (this.edit) {
      item.targetObject = this.parentItemWithoutDynamicFields
    }
    const currentCount = (this.parentItem[this.field.name][0]?.count) || 0
    this.autofocusLastItemFirstTextField = true
    Vue.set(this.items, this.items.length, item)
    if (this.isSimpleMultiselect) {
      Vue.set(this.parentItem, this.field.name, this.items.map(item => ({
        '@class': item['@class'],
        id: item.id,
        token: item.token,
      })))
    } else {
      // Update count
      Vue.set(this.parentItem, this.field.name, [{ count: currentCount + 1 }])
    }
    this.loadingNewFor = null
    resolve(null)
  })
}

export function addNewNestedRow ({
  // Used when user has focus in existing has-many row and wants to copy it
  copyFromIdOrToken = false,
  // When selecting multiple items from the popup of one field possible values, don't need to focus
  setFocusAfter = true,
  // When picking from the popup of one field possible values, also need to fill that field value for newly added has-many item
  // Aava-Vue-521 feature
  autoFillData = {},
}): Promise<Types.Item | null> {
  return new Promise(resolve => {
    if (!this.parentId) {
      return resolve(null)
    }
    api.fetchTokenFor({
      objectClass: this.parentResource,
      referenceClass: this.resource,
      objectToken: this.parentId,
      referenceField: this.field.name,
      queries: this.listQueries,
      copyFromIdOrToken,
    }).then((tokenResponse: AxiosResponse) => {
      this.$store.dispatch('globalErrorDisplay', { response: tokenResponse, context: 'Token for new has-many nested' })
      if (tokenResponse.data.item) {
        let item = tokenResponse.data.item
        Object.keys(autoFillData).forEach(key => {
          item[key] = autoFillData[key]
        })
        // TODO refactor
        let isObservedMember = false
        const changedFieldName = Object.keys(autoFillData)[0]
        if (Object.keys(autoFillData).length) {
          isObservedMember = item['@observed_members'].includes(changedFieldName)
        }
        if (Object.keys(autoFillData).length && isObservedMember) {
          // ---- //
          this.getFormItemOnDefaultsForChange(util.objectClassUnderscoredName(this.field.reference_class),
            item.token,
            true,
            {
              targetResource: this.parentResource,
              targetId: this.parentItem.token || this.parentItem.id,
              field: changedFieldName,
              item: this.getItemSavePayload(item, this.amc),
              targetField: this.field.name,
              queries: this.listQueries,
            })
            .then((response: AxiosResponse | false) => {
              if (response && response.data?.item) {
                item = response.data.item
                // Add changed field to the response as this is not returned by the API
                // item[changedFieldName] = this.item[changedFieldName]
                // Set targetObject for ~path query to work
                if (this.edit) {
                  item.targetObject = this.parentItemWithoutDynamicFields
                }
              }
              this.addNewHasManyItemToStore(item).then(() => {
                resolve(item)
              })
            })
          // ---- //
        } else {
          this.addNewHasManyItemToStore(item).then(() => {
            resolve(item)
          })
          if (setFocusAfter) {
            this.setFocusToNewlyAddedRow()
          }
        }
      } else {
        // Release add-new-nested btn
        this.loadingNewFor = null
        resolve(null)
      }
      // this.focusedRowIndex = this.itemsComputed.length - 1
    })
  })
}

// Add new has-many item picked from item picker
export function addNewSelectItemToStore (item: Types.Item | null = null) {
  if (item) {
    // Always add to the queue, so item-picker can show the loader
    // Until all requests related to adding new item are completed
    // and is ok to add the next item selected with multi-select item picker
    this.addQueue.push(item)
  } else {
    // Take the next item from the queue, if any left
    item = this.addQueue?.[0]
    if (!item) {
      return
    }
  }
  // Only continue when processing first item from the queue
  if (this.addQueue?.[0]?.id !== item.id) {
    return
  }
  this.processNewItemFromQueue(item).then(() => {
    // Now have to update has-many items for the parent

    if (this.isSimpleMultiselect) {
      this.$emit('callParentParentUpdate')
      this.addQueue.shift()
      // If user has selected next item(s) with multi-select item picker, add the next one
      if (this.addQueue[0]) {
        this.addNewSelectItemToStore()
      }
      return
    }

    this.updateItemAfterSelectingNewHasManyChild(this.field.name).then(() => {
      // Reload all has-many items with changeable fields and tokens
      this.initializeHasManyListComponent().then(() => {
        this.addQueue.shift()
        // If user has selected next item(s) with multi-select item picker, add the next one
        if (this.addQueue[0]) {
          this.addNewSelectItemToStore()
        }
      })
    })
  })
}
