import moment from 'moment'
import i18n from '@/locale/i18n'
import date_time_helper from '@/methods/date_time_helper'
import dashboardConfig from '@/components/Dashboard/dashboardConfig'
import { LP } from '@/types/LP.types'
import { ItemPicker } from '@/types/ItemPicker'

const inflect = require('i')(true)

// Overwrite module plural names where singular and plural are the same
const modelNameToPluralOverwrites = (modelName: string): string => {
  // Handle everything ending with _information
  if (modelName.endsWith('_information')) {
    return modelName.slice(0, -11) + 'informations'
  }

  const modelNamePluralOverwrites = {
    // fcl_container_information: 'fcl_container_informations', // Now handled with the coe above
    // cabin_passenger_information
    // passthrough_passengers_information
    cargo_equipment: 'cargo_equipments', // there is no word "equipments."
    attribute_metadata: 'attribute_metadatas',
  }

  return modelNamePluralOverwrites[modelName] || ''
}

export default {
  clone (list) {
    return JSON.parse(JSON.stringify(list))
  },

  isValidHttpsURL: (url) => {
    const urlPattern = /^https?:\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/
    return urlPattern.test(url)
  },

  isItemFieldValueChanged: (field, now, before) => {
    const isObjectValue = now?.['@class'] || before?.['@class']
    // Compare object values by comparing id and class
    // when selected from item picker, object may have more/less attributes than originally
    if (isObjectValue) {
      return !(now?.['@class'] === before?.['@class'] && now?.id === before?.id)
    }
    const type = field?.type || field?.attribute_type
    let changed
    switch (type) {
      case 'price':
      case 'quantity':
      case 'numeric':
      case 'decimal':
        // Important case to compare parsed float values
        // Helps to apply server side changes if user has actually not done any changes
        changed = now && before ? parseFloat(now) !== parseFloat(before) : !(!now && !before) // Both are empty, changed = false
        break
      default:
        changed = JSON.stringify(now) !== JSON.stringify(before) // For comparing arrays
    }
    if (changed) {
      // Temp debugging
      // console.log('=== value changed === ', type, before, '>', now, field)
    }
    return changed
  },

  // Clone the value without knowing the type and without leaving a reference
  // Faster than JSON.parse(JSON.stringify())
  // Also preservers undefined values and functions
  // Risk of not cloning second level objects, may need deepClone from lodash
  cloneValue: value => {
    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        return value.slice()
      } else {
        return { ...value }
      }
    } else {
      return value
    }
  },

  currentDateTime (locale: string) {
    return moment().locale(locale).format('lll')
  },

  isOldUIStateFilterValue (value) {
    return value && value !== '(' +
      value.split(' ')
        .map(text => parseInt(text.replace(/\D/g, '')))
        .join(' ') +
      ')'
  },

  getStateSearchTermFromSummary (summary: string) {
    return summary.replace(/ /g, '') + '^^'
  },

  objectClassUnderscoredName (className: string | null | undefined): string {
    if (!className) { return '' }
    if (className.toLowerCase() === 'any') { return 'any' }
    const pluralizedName = className ? inflect.pluralize(inflect.underscore(className)).toLowerCase() : ''
    return modelNameToPluralOverwrites(pluralizedName) || pluralizedName
  },

  objectClassUnderscoredNameSingular (className: string): string {
    if (className.toLowerCase() === 'any') {
      return 'any'
    }
    return className ? inflect.singularize(inflect.underscore(className)).toLowerCase() : ''
  },

  objectClassSingularName (className: string): string {
    if (!className) { return '' }
    return inflect.camelize(inflect.singularize(className))
  },

  clickOrDrag (
    e: MouseEvent | DragEvent,
    originalCoordinates: {
      x: number
      y: number
    },
  ) {
    if (Math.abs(originalCoordinates.x - e.pageX) < 10 && Math.abs(originalCoordinates.y - e.pageY) < 10) {
      return 'click'
    }
    return 'drag'
  },

  positionPopupMenu (
    e: MouseEvent | KeyboardEvent,
    id: string,
    left: number,
    top: number,
    {
      addOffset,
      attachEl,
      useAttachElMinWidth,
    }: ItemPicker.PositionPopupMenuOptions,
  ) {
    const el = document.getElementById(id)
    if (el) {
      let clientX = 0
      let clientY = 0
      if (e && 'clientX' in e) {
        // For MouseEvent
        clientX = e.clientX || 0
        clientY = (e && e.clientY) || 0
      }
      let targetLeft = clientX + left
      let targetTop = clientY + top
      if (addOffset && e && 'offsetX' in e) {
        // For MouseEvent
        targetLeft -= e && e.offsetX
        targetTop -= e && e.offsetY
      }

      // Attach to element with attachSelector
      if (attachEl) {
        targetTop = attachEl.getBoundingClientRect().top + attachEl.clientHeight + top
        targetLeft = attachEl.getBoundingClientRect().left + left
      }

      // When popup is behind view area, adjust position
      if (targetLeft + el.offsetWidth > window.innerWidth - 25) {
        targetLeft = window.innerWidth - el.offsetWidth - 25
      }
      if (targetTop > window.innerHeight + top - el.offsetHeight - 35) {
        const popupHeight = 261
        const fieldHeight = 50
        targetTop -= popupHeight + fieldHeight
        // JUN-2023 Not to cover the field, now popup is still attached but opened to the top
        // targetTop = window.innerHeight + top - el.offsetHeight - 35
      }

      el.style.left = targetLeft + 'px'
      el.style.top = targetTop + 'px'

      if (useAttachElMinWidth && attachEl) {
        el.style.minWidth = attachEl.clientWidth + 'px'
      }
    }
  },

  findLayoutProfileIndexById (state, id) {
    let layoutProfileIndex: number = -1
    state.layoutProfiles.forEach((item, index) => {
      if (item.id === id) {
        layoutProfileIndex = index
      }
    })
    return layoutProfileIndex
  },

  removeHTMLTagsFromString (text: string | any) {
    // TODO when typescipt removeHTMLTagsFromString elsewhere
    if (text !== '' && typeof text === 'string') {
      return text.replace(/<(.|\n)*?>/g, '')
    }
    return text
  },

  isEmptyString (string: string) {
    if (!string) {
      return true
    }
    return string.length === 0 || !string.trim()
  },

  userFriendlyLastModifiedTime (datetime: string) {
    const yesterday = moment().subtract(1, 'day')
    if (moment(datetime).isSame(moment(), 'day')) {
      return i18n.t('aava.dashboard.last_modified_short.today')
    } else if (moment(datetime).isSame(yesterday, 'day')) {
      return i18n.t('aava.dashboard.last_modified_short.yesterday')
    } else if (moment(datetime).isSame(moment(), 'week')) {
      return i18n.t('aava.dashboard.last_modified_short.this_week')
    } else {
      return date_time_helper.formatDate(datetime)
    }
  },

  isObject (item: any) {
    return typeof item === 'object' && item !== null
  },

  cloneObjectOrArray<T extends object | any[]> (list: T): T {
    return JSON.parse(JSON.stringify(list))
  },

  areObjectsEqual (object1: object, object2: object) {
    return JSON.stringify(object1) === JSON.stringify(object2)
  },

  isDecimalType (column_type: string) {
    return dashboardConfig.DECIMAL_TYPES.includes(column_type)
  },

  stripHtml (value) {
    return typeof value === 'string' ? value.replace(/<[^>]*>?/gm, '') : value
  },

  isAttachment: (field: LP.Item) => {
    if (field.widget === 'file') {
      return true
    }
    return field.is_attachment || ['files', 'flashs', 'pdfs', 'videos'].includes(field.name)
  },

  isImageType: (field: LP.Item) => {
    if (field.widget === 'image') {
      return true
    }
    return ['image', 'images'].includes(field.name)
  },
}
