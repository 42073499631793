<template>
  <v-menu bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        color="grey"
        text
        v-on="on"
      >
        <v-icon
          small
          class="mr-2"
        >
          fa-cog
        </v-icon>
        {{ timeLeftString }}
      </v-btn>
    </template>
    <div class="popup-items-container">
      <div
        v-for="( value, index ) in timeoutValues"
        :key="index"
        class="popup-menu-el"
        @click="setRefreshTimeout(value.value)"
      >
        <a href="javascript:;">
          <span
            v-if="value.value === $store.state.dashboardRefreshTimeout"
            class="fa fa-check"
          />
          {{ value.name }}
        </a>
      </div>
    </div>
  </v-menu>
</template>

<script lang="ts">
import dashboardMethods from './dashboardMethods'
import api from '../../store/api'
import { DashboardTypes } from '@/types/DashboardTypes'
import { AxiosResponse } from 'axios'

export default {
  name: 'DashboardSettings',

  data () {
    return {
      timeLeftString: '',
      timeoutValues: [
        { value: -1, name: this.$i18n.t('aava.dashboard.options.refresh_now') },
        { value: 1, name: '1 min' },
        { value: 2, name: '2 min' },
        { value: 5, name: '5 min' },
        { value: 15, name: '15 min' },
        { value: 30, name: '30 min' },
        { value: 0, name: this.$i18n.t('aava.dashboard.options.reload_off') },
      ]
    }
  },

  computed: {
    loading () {
      return parseInt(this.$store.state.dashboardRefreshTimeout) > 0
    },
  },

  mounted () {
    clearInterval(this.$store.state.dashboardRefreshTimer)
    this.$store.state.dashboardRefreshTimer = setInterval(this.timeLeft, 1000)
    this.timeLeft()
  },

  beforeDestroy () {
    clearInterval(this.$store.state.dashboardRefreshTimer)
  },

  methods: {
    ...dashboardMethods,

    timeLeft () {
      if (parseInt(this.$store.state.dashboardRefreshTimeout) > 0) {
        this.timeLeftString = this.calculateTimeLeft()
      } else {
        this.timeLeftString = this.$i18n.t('aava.dashboard.options.reload_off')
      }
    },

    // Calculates the time between the next refresh and current time.
    // this.$store.state.dashboardRefreshTime gives the last refresh
    // this.refreshTimeout gives the refresh interval as a string
    calculateTimeLeft () {
      const start = this.$store.state.dashboardRefreshTime
      const now: any = new Date()
      const gone = now - start // milliseconds
      let minutesLeft = parseInt(this.$store.state.dashboardRefreshTimeout) - Math.ceil((gone / 1000) / 60)
      let secondsLeft = 60 - Math.floor(gone / 1000) % 60

      if (minutesLeft < 0) { return '' }

      // instead of 0.60 show 1.00
      if (secondsLeft === 60) {
        minutesLeft++
        secondsLeft = 0
      }

      return this.$i18n.t('aava.dashboard.options.reload_in') +
        minutesLeft + '.' +
        secondsLeft.toString().padStart(2, '0')
    },

    setRefreshTimeout (value) {
      if (value === -1) {
        this.refreshDashboardItems()
      } else {
        this.$store.state.dashboardRefreshTimeout = value
        api.gorillaAddDashboardItem({
          type: 'option',
          key: 'dashboard_refresh',
          value,
        } as DashboardTypes.Option).then((response: AxiosResponse) => {
          this.$store.dispatch('globalErrorDisplay', { response, context: 'Dashboard option save' })
        })
      }
    },
  }
}
</script>
