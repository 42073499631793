<template>
  <div class="item-container">
    <MobileItemBottomToolbar
      v-if="item && $vuetify.breakpoint.xsOnly"
      :id="id"
      ref="itemToolbar"
      :edit="edit"
      :item="item"
      :item-meta="itemMeta"
      :resource="resource"
      :show-loader="showLoader"
      :save-form-item="saveFormItem"
      :modal-props="modalProps"
      :item-form-position="itemFormPosition"
      @sendItemMetaAction="sendItemMetaAction"
      @updateShowLoader="updateShowLoader"
      @refreshItem="refreshItem"
      @saveFormItem="saveFormItem"
      @openEditForm="openEditForm"
    />
    <v-progress-linear
      v-if="showLoader && !($refs.itemToolbar && $refs.itemToolbar.savingFormItem)"
      color="orange"
      class="item-main-loader"
      height="4"
      indeterminate
    />
    <div
      v-else-if="item.id || item.token"
      class="d-none item-is-loaded"
    />
    <ItemToolbar
      v-if="item && !suppressToolbar"
      :id="id"
      ref="itemToolbar"
      :edit="edit"
      :item="item"
      :item-meta="itemMeta"
      :resource="resource"
      :show-loader="showLoader"
      :save-form-item="saveFormItem"
      :modal-props="modalProps"
      :item-form-position="itemFormPosition"
      :filtered-item-layout-profiles="filteredItemLayoutProfileTabs"
      @updateShowLoader="updateShowLoader"
      @refreshItem="refreshItem"
    />
    <v-toolbar
      v-if="showLayoutProfileTabsToolbar"
      height="35"
      class="list-toolbar item-tabs-toolbar elevation-0"
      color="grey lighten-2"
      text
    >
      <LayoutProfileTabs
        v-if="showTabsOnSeparateToolbar"
        ref="tabsContainer"
        :item-layout-profiles="filteredItemLayoutProfileTabs"
        :for-item="true"
        :modal="modal"
        :item-edit="edit"
        :visible-item-layout-profile-tabs-count="visibleItemLayoutProfileTabsCount"
      />
      <v-spacer />

      <div style="position: relative; top: -10px; right: 0; display: flex;">
        <ItemStateLabel
          v-if="$vuetify.breakpoint.mdAndUp"
          :item="item"
        />
        <ItemMetaActions
          v-if="!isMobile && !modal"
          :item-meta="itemMeta"
          @sendItemMetaAction="sendItemMetaAction"
        />
        <ItemDownloadActions
          v-if="!isMobile && !modal"
          :download-links="item['@displayable_documents']"
        />
        <ItemHistoryBtn
          v-if="!isMobile && !modal"
          :item="item"
          :resource="resource"
        />
      </div>
    </v-toolbar>
    <ItemForm
      v-if="mountItemFormComponent && item && selectedLayoutProfileIdByModel[resource]"
      :id="id"
      ref="itemForm"
      v-model="item"
      :item-meta="itemMeta"
      :show-loader="showLoader"
      :target-resource="targetResource"
      :target-id="targetId"
      :target-field="targetField"
      :edit="edit"
      :resource="resource"
      :modal-props="modalProps"
      :item-form-position="itemFormPosition"
      @updateShowLoader="updateShowLoader"
      @setItemMeta="setItemMeta"
    />
  </div>
</template>

<script lang="ts">
import methods from './../components/methods'

import ItemToolbar from '@/components/Item/Toolbar/ItemToolbar.vue'
import ItemForm from './../components/Item/ItemForm.vue'
import LayoutProfileTabs from './../components/LayoutProfiles/LayoutProfileTabs.vue'

import { createHelpers } from 'vuex-map-fields'
import state from './../store/state'
import ItemMetaActions from '@/components/Item/Toolbar/ItemMetaActions.vue'
import ItemDownloadActions from '@/components/Item/Toolbar/ItemDownloadActions.vue'
import itemMetaActionMethods from '@/methods/item/itemMetaActionMethods'
import ItemHistoryBtn from '@/components/Item/ItemHistoryBtn.vue'
import MobileItemBottomToolbar from '@/components/Item/Toolbar/MobileItemBottomToolbar.vue'
import itemFormMethods from '@/components/Item/itemFormMethods'
import ItemStateLabel from './ItemStateLabel.vue'
import { Types } from '@/types/AppTypes'
import { ItemMeta } from '@/types/LP.types'
import { SaveFormItemProps } from '@/types/Item'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'Item',

  components: {
    ItemStateLabel,
    MobileItemBottomToolbar,
    ItemHistoryBtn,
    ItemDownloadActions,
    ItemMetaActions,
    ItemToolbar,
    LayoutProfileTabs,
    ItemForm,
  },

  // TODO - are all these props here necessary?
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    targetResource: {
      type: String,
      default: '',
    },
    targetId: {
      type: [String, Number],
      default: '',
    },
    targetField: {
      type: String,
      default: '',
    },
    itemAction: {
      type: String,
      default: '',
    },
    resource: {
      type: String,
      required: true,
    },
    modalProps: {
      type: Object,
      default: () => {},
    },
    itemFormPosition: {
      type: String as () => 'first' | 'second' | 'modal',
      default: 'first',
    },
  },

  data () {
    return {
      item: {} as Types.Item,
      itemMeta: null as ItemMeta | null,
      showLoader: true,
      visibleItemLayoutProfileTabsCount: 100,
      mountItemFormComponent: true,
    }
  },

  watch: {
    resource (value, before) {
      if (value) {
        this.loadItemLayoutProfiles().then()
        if (before) {
          this.mountItemFormComponent = false
          this.$nextTick(() => {
            this.mountItemFormComponent = true
          })
        }
      }
    },

    triggerToCalculateVisibleItemLayoutProfileTabs () {
      this.setVisibleItemLayoutProfileTabsCount()
    },

    isMyProfile () {
      // Special case for using Person class for my_profile when user may not have Person class rights
      this.clearItemCurrentData()
      this.loadItemLayoutProfiles().then()
    },
  },

  created () {
    this.loadItemLayoutProfiles().then(() => {
      this.setVisibleItemLayoutProfileTabsCount()
    })
  },

  destroyed () {
    if (this.itemLayoutEditMode) {
      this.suppressToolbar = false
      this.itemLayoutEditMode = false
      this.highlightedFieldName = ''
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    filteredItemLayoutProfileTabs () {
      return this.itemLayoutProfilesByModel?.[this.resource]?.filter(layoutProfile => {
        // Filter out LPs which are to be used as a dialog + user is not the owner (locked)
        // Aava-Vue-572
        return !(layoutProfile.locked && layoutProfile.global_view && layoutProfile.context === 'dialog')
      }) || []
    },

    showLayoutProfileTabsToolbar () {
      return this.$vuetify.breakpoint.mdAndUp && // Do not show on mobile
        this.item && !this.suppressToolbar && this.showTabsOnSeparateToolbar && !this.loadingLayoutProfiles &&
        this.splitMode !== 'horizontal' &&
        !this.modalProps?.layoutProfileContext // In modal dialog if fixed LP ID is set, do not show
    },

    isMobile () {
      return this.$vuetify.breakpoint.xsOnly
    },

    modal () {
      return this.itemFormPosition === 'modal'
    },

    splitProps () {
      return this.$route.params
    },

    showTabsOnSeparateToolbar () {
      return this.showItemTabsOnSeparateToolbar(this.itemLayoutProfilesByModel[this.resource])
    },

    edit () {
      return this.itemAction === 'edit'
    },

    isMyProfile () {
      return this.$route.params.specialAction === 'myProfile'
    },
  },

  methods: {
    ...methods,
    ...itemMetaActionMethods,
    ...itemFormMethods,

    // Detect how many Layout Profile tabs can fit
    setVisibleItemLayoutProfileTabsCount () {
      // Temporarily show all layout profile tabs
      // Reset value in store
      this.visibleItemLayoutProfileTabsCount = 100
      let visibleTabs = 0
      let cumulatedWidth = 0
      const containerEl = this.$refs.tabsContainer?.$el
      if (!containerEl) { return }
      const offset = 200
      const tabsAreaWidth = containerEl.clientWidth - offset
      this.$nextTick(() => {
        // When is rendered, can measure width
        (this.itemLayoutProfilesByModel[this.resource] || []).forEach(profile => {
          const el = document.getElementById('layout_profile_tab_' + profile.id)
          if (el) {
            cumulatedWidth += el.clientWidth
            if (cumulatedWidth < tabsAreaWidth) {
              visibleTabs++
            }
          }
        })
        this.$nextTick(() => {
          this.visibleItemLayoutProfileTabsCount = visibleTabs || 0
        })
      })
    },

    updateShowLoader (value: boolean) {
      this.showLoader = value
      if (!value) {
        // Now item object state label is also present, re-measure how many item LP's can fit
        this.setVisibleItemLayoutProfileTabsCount()
      }
    },

    saveFormItem (props: SaveFormItemProps) {
      return this.$refs.itemForm.saveFormItem(props)
    },

    openEditForm (props) {
      return this.$refs.itemForm.openEditForm(props)
    },

    refreshItem (props) {
      return this.$refs.itemForm.initializeFormItem(props)
    },

    setItemMeta (meta: ItemMeta) {
      this.itemMeta = meta
    },

    loadItemLayoutProfiles () {
      return new Promise(resolve => {
        this.$store.dispatch('loadItemLayoutProfiles', this.resource)
          .then(result => {
            resolve(result)
          })
      })
    },
  },
}
</script>

<style lang="scss">
.item-container {
  position: relative;
  .item-main-loader {
    position: absolute;
    top: 0;
    z-index: 154; /* position behind split mode separator line */
  }
}
/* item action etc buttons are always visible - to avoid content jumping
differentiate disabled buttons as active are also grey */
.item-tabs-toolbar {
  .theme--light.v-btn.v-btn--disabled {
    .v-icon {
      color: #ccc !important;
    }
  }
}
</style>
