import ActionCable from 'actioncable'

export default {
  startCableConnection () {
    return new Promise(resolve => {
      this.doConnectCable(resolve).then()
    })
  },

  doConnectCable (resolve) {
    if (!this.authenticated) {
      resolve(null)
      return
    }
    if (this.cableState === 'CONNECTING') {
      setTimeout(() => {
        this.disconnectCable(resolve)
      }, 3 * 1000)
      return
    }
    if (this.cable) {
      resolve(this.cable)
      return
    }

    this.cableState = 'CONNECTING'

    if (this.systemConfigs.wsUrl) {
      // Connect to the webService if the wsUrl is defined
      this.cable = ActionCable.createConsumer(this.systemConfigs.wsUrl)
      this.cableState = 'CONNECTED'
      this.connectInfoChannel()
    } else {
      // console.warn('wsUrl not set', this.systemConfigs)
      this.cableState = null
      this.cable = null
    }
    resolve(this.cable)
  },

  connectInfoChannel () {
    this.infoChannel = this.cable.subscriptions.create('InfoChannel', {
      connected: () => {
        // console.log('cable-connected')
        this.initPolling()
      },
      disconnected: () => {
        // console.log('cable-disconnected')
        this.disconnectCable({ reason: 'disconnected' })
      },
      rejected: () => {},
      received: data => {
        // console.log('cable-received', data)
        if (data.connection_status) {
          this.pollAuthenticationResponseHandling(data)
          return
        }

        // Info channel is used in showing messages either as a Flash note or a marquee text
        const content = JSON.parse(data.body)
        if (content.options.user) {
          // if user is set, the note is shown only to the given user
          if (this.userInfo.name !== content.options.user) {
            return
          }
        }
        if (content.options.type === 'marquee') {
          // TODO
          console.log('show marquee ', content.message, content.options)
          // marquee(content.message, content.options)
        } else {
          // TODO
          console.log('show note ', content.message, content.options)
          // note(content.message, content.options || {});
        }
      }
    })
  },

  // if the session has expired, disconnect info channel and
  // trigger the login screen
  pollAuthenticationResponseHandling (data) {
    if (data.connection_status && data.connection_status === 'NOT_AUTHENTICATED') {
      this.disconnectCable(data)
      // TODO
      // this.channel('mainMenu').request('hide');
      // this.channel('login').request('show', {redirectBack: true, message: 'messages.session_expired'});
    }
  },

  disconnectCable (data) {
    this.$store.dispatch('showLoginDialogIfSessionHasExpired', {
      name: 'cable-disconnected',
      data,
    })
    if (this.cable) {
      this.cable.disconnect()
      this.cableState = 'DISCONNECTED'
      this.cable = null
    }
    this.clearPolling()
  },

  initPolling () {
    this.clearPolling()
    this.infoChannelPollInterval = setInterval(this.pollAuthentication, 15 * 1000) // 60
  },

  // sends a message that returns the authentication
  // status of this user's connection
  pollAuthentication () {
    this.infoChannel.perform('verify_authentication', { message: '' })
  },

  clearPolling () {
    if (this.infoChannelPollInterval) {
      clearInterval(this.infoChannelPollInterval)
    }
    this.infoChannelPollInterval = null
  },
}
