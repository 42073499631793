<template>
  <v-app :style="appStyle">
    <template v-if="userInfo && userInfo.id && supportedDataLoaded">
      <MenuNavigationDrawer />
      <ShortcutsList v-if="dashboardItemsLoaded" />
      <ReleaseInfo v-if="showReleaseInfo" />
      <UserLevelSettings v-if="showUserLevelSettingsForm" />
      <UserMenuDrawer />
      <RestartMessage />
      <FlashMessages />
      <MenuToolbar />
      <ItemPicker v-if="showItemPicker" />
      <AddressModal />
      <DateTimePickerMenu />
      <ItemContainerMenu v-if="dialogItemContainerMenu" />
      <ItemFieldMenu v-if="dialogItemFieldMenu" />
      <router-view />
    </template>
    <div
      v-else
      class="full-height-content-center"
    >
      <AavaLoader />
    </div>
    <LoginDialog v-if="showLoginDialog" />
  </v-app>
</template>

<script lang="ts">
import MenuToolbar from './components/App/MenuToolbar.vue'
import AavaLoader from './components/AavaLoader.vue'
import AddressModal from './components/AddressModal.vue'
import ItemPicker from './components/ItemPicker/ItemPicker.vue'
import ItemContainerMenu from './models/LayoutContainer/LC.menu.vue'
import ItemFieldMenu from './models/LayoutProfileItem/LPI.item.menu.vue'

import { createHelpers } from 'vuex-map-fields'
import state from './store/state'
import LoginDialog from '@/components/LoginDialog.vue'
import DateTimePickerMenu from '@/components/Form/DateTimePickerMenu.vue'
import FlashMessages from '@/components/App/FlashMessages.vue'
import MenuNavigationDrawer from '@/components/App/MenuNavigationDrawer.vue'
import ShortcutsList from '@/components/Shortcuts/ShortcutsList.vue'
import UserMenuDrawer from '@/components/App/UserMenuDrawer.vue'
import ReleaseInfo from '@/components/App/ReleaseInfo.vue'
import RestartMessage from './components/App/RestartMessage.vue'
import appMethods from '@/methods/list/appMethods'
import infoChannelMethods from '@/methods/list/infoChannelMethods'
import UserLevelSettings from './components/App/UserLevelSettings.vue'

const { mapFields }: any = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'App',

  components: {
    UserLevelSettings,
    ReleaseInfo,
    UserMenuDrawer,
    ShortcutsList,
    MenuNavigationDrawer,
    RestartMessage,
    FlashMessages,
    DateTimePickerMenu,
    LoginDialog,
    MenuToolbar,
    AavaLoader,
    ItemPicker,
    AddressModal,
    ItemContainerMenu,
    ItemFieldMenu,
  },

  data () {
    return {
      resizeTimer: null,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    appStyle () {
      return {
        // To fix mobile height, set height for the app container
        // Otherwise height is more and causes item bottom toolbar to be not fixed
        height: (this.innerHeight) + 'px !important',
      }
    },

    routeString () {
      return JSON.stringify(this.$route.params)
    },
  },

  watch: {
    routeString () {
      this.splitModeFile = {}
    },
  },

  created () {
    this.getSessionAndAppSupportingData().then(() => {
      this.startCableConnection()
    })
    window.addEventListener('resize', this.resizeHandler)
    window.addEventListener('serviceWorkerUpdated', this.refreshAppForNewVersion, { once: true })
    window.addEventListener('serviceWorkerUpdateFound', this.showAppNewVersionMessage, { once: true })
  },

  destroyed () {
    window.removeEventListener('resize', this.resizeHandler)
  },

  methods: {
    ...appMethods,
    ...infoChannelMethods,
  },
}
</script>

<style lang="scss">
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~quill/dist/quill.bubble.css';
:root {
  --row-height: 17px;

  --list_row_odd_background_color: #f2f2f2;
  --list_row_even_background_color: #fff;
  --list_actions_row_background_color: white;
  --list_header_row_background_color: white;

  --list_tab_default_background_color: #aaa;
  --list_selected_tab_background_color: white;
  --list_columns_separator_line_color: #E0E0E0;

  --overflow-bullet-position: -moz-calc(100% - 5px);
  --overflow-bullet-position: -webkit-calc(100% - 5px);
  --overflow-bullet-position: calc(100% - 5px);
  --header-actions-width: -moz-calc(100% - 20px);
  --header-actions-width: -webkit-calc(100% - 20px);
  --header-actions-width: calc(100% - 20px);

  --body-background-image: none;
  --body-background-position: center;
}

.content-item {
  height: calc(var(--row-height) + 13px);
}
.content-cell {
  height: calc(var(--row-height) + 13px);
}
.content-cell.cell-editing {
  height: calc(var(--row-height) + 13px) !important;
}
</style>

<style lang="scss">

@import './styles/vue-pivottable.css';
@import './styles/vtooltip.css';
@import './styles/fromOldAppTodo.css';
@import './styles/listEdit.scss';

body {
  /* should not be needed
  only reason for this currently is to hide scrollbar
  when mobile and has item bottom navigation hidden */
  overflow: hidden;
}
.v-btn {
  letter-spacing: normal !important;
  text-transform: none !important;
}
/* Disable Chrome input bg for autofill fields */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
/* For Firefox */
input:autofill {
  background: #fff; /* or any other */
}
#app {
  font-family: 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  font-size: 12px;
}
// To allow body app background image
.theme--light.v-application {
  background: transparent !important;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.content-center {
  justify-content: center;
  align-items: center;
}
.full-height-content-center {
  clear: both;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.v-text-field--outlined {
  fieldset {
    /* border: 1px solid !important; */
  }
}
.required-filled {
  fieldset {
    border: 1px solid #388E3C !important;
  }
}
.required-missing {
  fieldset {
    border: 1px solid #EF5350 !important;
  }
}
.item-toolbar-icon, .list-toolbar-icon {
  .v-icon {
    font-size: 20px !important;
    height: 20px !important;
    width: 20px !important;
  }
}
.mobile-toolbar-btn {
  .v-icon {
    font-size: 20px !important;
    height: 20px !important;
    width: 30px !important;
  }
}
.v-input__icon .fa-calendar {
  font-size: 16px !important;
  color: #aaa !important;
}
.popup-items-container {
  background: white;
  padding: 7px 0;
}
.reference-state-button {
  margin-left: 2px;
  font-size: 0.85em;
  border-radius: 5px;
  padding: 0 4px 0 4px;
  max-height: 17px; /* in list view */
}
.item-form-container {
  .reference-state-button {
    padding: 2px 4px 0 4px;
    max-height: 22px; /* item view */
  }
}
.link {
  cursor: pointer;
}
.link-important, .link-important div {
  cursor: pointer !important;
}
.no-link-decoration {
  text-decoration: none !important;
}

/* from old portal */
.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}
a.label:hover,
a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.label:empty {
  display: none;
}
.btn .label {
  position: relative;
  top: -1px;
}
.label-default {
  background-color: #777777;
}
.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #5e5e5e;
}
.label-primary {
  background-color: #fff13d;
}
.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #ffed0a;
}
.label-success {
  background-color: #5cb85c;
}
.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #449d44;
}
.label-info {
  background-color: #5bc0de;
}
.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #31b0d5;
}
.label-warning {
  background-color: #f0ad4e;
}
.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #ec971f;
}
.label-danger {
  background-color: #d9534f;
}
.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #c9302c;
}
.py-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.px-2px {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.empty-cell-take-space:after {
  content: '.';
  visibility: hidden;
}
</style>

<style lang="less">
.toolbar-tab, .layout-profile-tab {
  bottom: 0;
  color: white;
  padding: 5px 10px;
  background: var(--list_tab_default_background_color) !important;
  border-radius: 8px 8px 0 0 !important;
  margin: 0px 4px -5px 0;
  height: 35px;
  line-height: 23px;
  border-top: 1px solid #ddd !important;
  border-left: 1px solid #ddd !important;
  border-right: 1px solid #ddd !important;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
.toolbar-tab {
  margin-top: 13px;
}
.selected-toolbar-tab, .selected-layout-profile {
  background: var(--list_selected_tab_background_color) !important;
  border-top: 1px solid #ccc !important;
  border-left: 1px solid #ccc !important;
  border-right: 1px solid #ccc !important;
  /* margin: -10px 2px 0 2px !important; */
  font-size: 18px;
  border-bottom: 1px solid white;
}
.layout-profile-tab:hover:before {
  background-color: transparent !important;
}
.toolbar-tab, .layout-profile-tab, .layout-profiles-menu-el {
  font-size: 14px !important;
  .fa {
    font-size: 12px !important;
  }
  div {
    float: left;
  }
  div:not(:first-child) {
    padding-left: 7px;
  }
}
.toolbar-tab-menu-el, .layout-profiles-menu-el {
  position: relative;
  width: 100%;
  padding: 7px 10px;
  cursor: pointer;
  color: white;
  overflow: auto;
  font-weight: bold;
  min-height: 32px;
  line-height: 18px;
  &:not(:last-child) {
    border-bottom: 1px solid white;
  }
  &:hover {
    background: #f2f2f2;
  }
}
</style>

<style lang="scss">
.dashboard-tab-menu, .layout-profiles-menu {
  padding: 0 !important;
  .dashboard-tab, .layout-profile-tab {
    position: relative;
    justify-content: left !important;
    margin: 0 !important;
    border-radius: 0 !important;
    width: 100%;
  }
}
.text-no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
