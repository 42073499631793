<template>
  <div
    :class="classes"
    @click.stop="clickHandler"
  >
    <a href="javascript:;">
      <span
        :class="value ? icon : (toggleIcon || icon)"
      />
      {{ value ? label : toggleLabel }}
    </a>
  </div>
</template>

<script lang="ts">
export default {
  name: 'MenuOption',

  props: {
    value: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      required: true,
    },
    toggleLabel: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      required: true,
    },
    toggleIcon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes (): string[] {
      return [
        'popup-menu-el',
        this.disabled ? 'menu-el-disabled' : '',
      ]
    },
  },

  methods: {
    clickHandler () {
      if (this.disabled) { return }
      this.$emit('click')
    },
  },
}
</script>
