import api from './../../store/api'
import listQueries from '@/store/_listQueries'
import util from '@/utilities/sharedUtilities'
import Color from 'color'
import hasManyColumnResizeMethods from '../list/hasManyColumnResizeMethods'
import iconNameChanges from '@/utilities/iconNameChanges'
import { Field } from '@/types/FieldTypes'
import { EventAction, ProcessEventType } from '@/types/EventAction'
import { BaseItem, Types } from '@/types/AppTypes'
import { AxiosResponse } from 'axios'
import { EventResponse, RedirectTo } from '@/types/API.responses'
import { FormItem, LP, LPI } from '@/types/LP.types'
import Vue from 'vue'

export const getSelectedItemLP = (component: Vue, resource: string, selectedId: number): LP.LayoutProfile | null => {
  return component.$store.state.itemLayoutProfilesByModel[resource]
    ?.filter((lp: LP.LayoutProfile) => lp.id === selectedId)?.[0] || null
}

// Sort item process_events and process_actions by priority
// Used in the list and item view
export const sortItemActions = (actions: EventAction): EventAction[] => {
  return JSON.parse(JSON.stringify(actions))
    .sort((a, b) => {
      const noPrioritiesForBoth = !a.priority && !b.priority
      const bOnly = !a.priority && b.priority
      const aPriorityIsHigher = a.priority && b.priority && a.priority > b.priority
      return noPrioritiesForBoth || bOnly || aPriorityIsHigher ? 1 : -1
    })
}

export const getCombinedItemProcessEventsAndActions = (item: Types.Item): EventAction[] => {
  const addType = (type:ProcessEventType) => (item: EventAction): EventAction => ({ ...item, type })
  // @ts-ignore
  return sortItemActions([
    // @ts-ignore
    ...(item?.displayable_process_events || []).map(addType('processEvent')),
    // @ts-ignore
    ...(item?.displayable_process_actions || []).map(addType('processAction')),
  ])
}

export const getObjectStateBackgroundColor = (mainObjectState: Field.ObjectState): string => {
  if (!mainObjectState) { return '' }
  let color = '#FFF9C4'
  if (mainObjectState.background_color) {
    try {
      color = Color(mainObjectState.background_color).hex()
    } catch (e) {
      color = mainObjectState.background_color
    }
  } else if (mainObjectState.final_state) {
    color = '#B9F6CA'
  } else if (mainObjectState.initial_state) {
    color = '#FFCDD2'
  }
  return color
}

export const composeAddressSavePayload = (item: BaseItem, fieldName: string): Field.Address | null => {
  if (!item || !item[fieldName]) { return null }
  return {
    '@class': 'Address',
    id: item[fieldName].id,
    postal_code_string: item[fieldName].postal_code_string ?? null,
    postal_office_string: item[fieldName].postal_office_string ?? null,
    street_1: item[fieldName].street_1 ?? null,
    street_2: item[fieldName].street_2,
    street_3: item[fieldName].street_3,
    street_4: item[fieldName].street_4,
    token: item[fieldName].token,
    country: item[fieldName].country?.id || item[fieldName].country?.token
      ? {
          '@class': 'Country',
          id: item[fieldName].country?.id,
          token: item[fieldName].country?.token,
        } : undefined
  }
}

export function getItemSavePayload (item: BaseItem, fieldsByName: FormItem) {
  const payload: any = {
    '@class': item['@class'],
    // '~confirmations': 'required',
  }
  if (item.id) {
    payload.id = item.id
  }
  if (item.token) {
    payload.token = item.token
  }

  Object.keys(item).forEach(fieldName => {
    const field = fieldsByName?.[fieldName]
    const type = field?.type || field?.attribute_type
    if (field && (
      field.writable || // TODO  confirm this
        !('writable' in field)) && // in case of has-many field info comes from amc which does not have that attribute
      !field.dynamic
      // !['file'].includes(type) // 28.09.21 why was this here? Files are not saved when creating new item
    ) {
      // TODO - use case here
      if (field.widget === 'address' && field.type === 'reference' && item[fieldName]) {
        const address = composeAddressSavePayload(item, fieldName)
        if (address && item[fieldName].country) {
          address.country = {
            '@class': 'Country',
            id: item[fieldName].country.id
          } as Field.Reference
        }
        payload[fieldName] = address
      } else if (['reference', 'process', 'state', 'polymorphic_autocomplete'].includes(type)) {
        payload[fieldName] = item[fieldName] && item[fieldName].id
          ? {
              id: item[fieldName].id,
              '@class': item[fieldName]['@class'],
            }
          : null
      } else if (['price', 'quantity'].includes(type)) {
        const prefix = type === 'price' ? '_currency' : '_unit'
        if (item[fieldName + prefix]) {
          if (fieldsByName[fieldName + prefix]?.writable && fieldsByName[fieldName + prefix].unit_presentation !== 'fixed') {
            payload[fieldName + prefix] = {
              id: item[fieldName + prefix].id,
              '@class': item[fieldName + prefix]['@class'],
            }
          } else if (payload[fieldName + prefix]) {
            delete payload[fieldName + prefix] // Not writable, remove from the payload
          }
        }
        payload[fieldName] = item[fieldName]
      } else if (['image', 'file', 'pdf'].includes(type) || util.isAttachment(field) || util.isImageType(field)) {
        payload[fieldName] = item[fieldName].map(attachment => {
          return {
            '@class': 'Attachment',
            id: attachment.id,
          }
        })
      } else if (type === 'has_many_reference') {
        // Attach items from child has-many component
        // Has-many component is present, initiated and list items loaded
        let ref = this.$refs[fieldName]?.[0]?.$refs?.hasManyItems
        // Find ref for (multiselect) has-many inside has-many
        // If ref not find
        if (!ref) {
          const fieldRef = this.$refs.hasManyItemField
            ?.find(fieldComponent => fieldComponent.field?.name === field.name)
          if (fieldRef?.$refs?.hasManyItems) {
            ref = fieldRef?.$refs?.hasManyItems
          }
          // console.log(2, fieldRef, ref)
        }
        if (ref && !ref.loadingItems) {
          if (field.widget === 'simple_multiselect') {
            payload[fieldName] = (ref.items || []).map(msItem => ({
              '@class': msItem['@class'],
              id: msItem.id,
              token: msItem.token, // Can only select from existing (saved) items, so always id is present right?
            }))
            // console.log('::::::widget', field.widget, (ref.items || []).map(msItem => ({
            //   '@class': msItem['@class'],
            //   id: msItem.id,
            //   token: msItem.token, // Can only select from existing (saved) items, so always id is present right?
            // })))
          } else {
            payload[fieldName] = this.getHasManyItemSaveFields(
              ref.items,
              field.reference_class
            )
          }
        } else {
          // If has-many list items are not yet loaded by the component itself,
          // attach list items from the main item (which has id and token),
          // so we don't lose the items during the save
          // and make saving form possible without loading all the child lists
          payload[fieldName] = Array.isArray(item[fieldName])
            ? item[fieldName].map(hmItem => {
              return {
                '@class': hmItem['@class'],
                id: hmItem.id,
                token: hmItem.token,
              }
            })
            : item[fieldName]
        }
        // console.log('FOR SAVE', field.type, field.name, field.widget, payload[fieldName])
      } else if (type === 'password') {
        payload[fieldName] = item[fieldName]
        payload[fieldName + '_confirmation'] = item[fieldName + '_confirmation']
      } else {
        payload[fieldName] = item[fieldName]
      }
    }
  })
  // Hack for my_profile
  if (this.$route.params.specialAction === 'myProfile' && payload['@class'] === 'Person') {
    payload['@my_profile'] = true
  }
  return payload
}

export function getFormItemOnDefaultsForChange (resource: string, id: string | number, edit: boolean, {
  targetResource, targetId, targetField, item, field, queries,
}: {
  targetResource?: string,
  targetId?: string | number | null,
  targetField?: string,
  item: Types.Item,
  field: string,
  queries?: string[] | null,
}): Promise<AxiosResponse | false> {
  const selectedItemLayoutProfile = this.selectedLayoutProfileIdByModel && this.selectedLayoutProfileIdByModel[resource]
  const layoutProfileItems = selectedItemLayoutProfile &&
      this.layoutProfileItemsById[selectedItemLayoutProfile]
  queries = queries || listQueries.get(layoutProfileItems, selectedItemLayoutProfile, {
    view: 'item',
    edit,
    onDefaultsForChange: true,
    locale: this.locale,
    availableContentLocales: this.availableContentLocales,
    includeAllFields: true, // Have to include all fields for other Layout Profile tabs
  })

  // TODO - added may 2021, why?
  // queries = ['roles token'] // Overwrites existing queries. It actually broke defaults-for-change
  // Next time comment why something is added! When code itself is not telling the story
  // const uniqueIds = []
  // item.roles = item.roles ? item.roles.filter(role => {
  //   if (!role.id || !uniqueIds.includes(role.id)) {
  //     if (role.id) {
  //       uniqueIds.push(role.id)
  //     }
  //     return true
  //   }
  //   return false
  // }) : []
  return new Promise(resolve => {
    if (!item.token) {
      // DFC change was likely triggered too late, even after (main) item save.
      // Ignore it here, not much else can do as can't send DFC without token
      console.error('Item token missing for DFC', item.id, item)
      return resolve(false)
    }
    let url = '/api/' + resource + '/' + item.token + '/' + field
    if (targetResource && targetId && targetField) {
      url = url + '/for/' + targetResource + '/' + targetId + '/' + targetField
    }
    api.sendPutWithPayloadRequest(url, queries, item).then((response: AxiosResponse) => {
      this.$store.dispatch('globalErrorDisplay', { response, context: 'Save DFC item ' + resource })
      resolve(response)
    })
  })
}

export default {
  ...hasManyColumnResizeMethods,

  iconName (iconName) {
    return iconNameChanges[iconName] || iconName
  },

  openNewForm ({ resource = '' }) {
    const routerProps = this.$route.params
    if (routerProps?.targetResource && routerProps?.targetId) {
      this.$router.push({
        path: '/' + this.objectClass + '/for/' +
          routerProps.targetResource + '/' +
          routerProps.targetId + '/' +
          routerProps.targetField + '/' +
          // In case for alternative view
          (resource && resource !== this.objectClass ? resource + '/' : '') +
          'new/edit'
      })
    } else {
      this.$router.push({ path: '/' + (resource || this.objectClass) + '/new' })
    }
  },

  copyFrom () {
    this.$router.push({ path: '/' + this.objectClass + '/new/from/' + this.item.id })
  },

  getItemInfo (resource: string, id: number, queries: string[]) {
    return api.fetchItemInfo(resource, id, queries)
  },

  getFormItem (resource: string, id: string | number, edit: boolean, {
    targetResource,
    targetId,
    targetField
  }: {
    targetResource?: string,
    targetId?: string | number,
    targetField: string,
  }): Promise<{ response: AxiosResponse, item: Types.Item | null}> {
    return new Promise(resolve => {
      const selectedItemLayoutProfile = this.selectedLayoutProfileIdByModel[resource]
      const layoutProfileItems = this.layoutProfileItemsById[selectedItemLayoutProfile]
        .map(field => ({
          ...field,
          // Add AMC to LPI, for multiselect feature
          ...(this.amByModel?.[this.resource]?.[field.name] || {}),
        }))
      const queries = listQueries.get(layoutProfileItems, selectedItemLayoutProfile, {
        view: 'item',
        edit,
        locale: this.locale,
        availableContentLocales: this.availableContentLocales,
        includeAllFields: true,
      })
      if (edit) {
        resolve(this.getItemInfoForEdit(resource, id, queries, {
          targetResource, targetId, targetField,
        }))
      } else {
        api.fetchItemInfo(resource, id, queries).then((response: AxiosResponse) => {
          resolve({
            item: response.data.item ?? null,
            response,
          })
        })
      }
    })
  },

  // Get address tokens for address fields missing values
  // this must be done synchronous in order for api to return valid tokens
  // with async way tokens are returned, but can't be used on item save or when selecting country
  getItemAddressTokens (item: Types.Item) {
    const addresses = this.fields.filter((field: LPI) => field.widget === 'address')
    addresses
      .reduce((previousPromise: any, field: LPI) => {
        return previousPromise.then(() => {
          return !item[field.name]
            ? api.fetchTokenFor({
              objectClass: this.resource,
              referenceClass: util.objectClassUnderscoredName(field.reference_class),
              objectToken: (item.token || item.id),
              referenceField: field.name,
              queries: ['token', 'summary'],
            }).then((response: AxiosResponse) => {
              if (response.data.item) {
                item[field.name] = response.data.item
              }
            })
            : Promise.resolve() // When address is present, do nothing
        })
      }, Promise.resolve())
  },

  getItemInfoForEdit (resource: string, id: string | number, queries: string[], {
    targetResource, targetId, targetField,
  }: {
    targetResource?: string,
    targetId?: string | number,
    targetField?: string,
  }): Promise<{ response: AxiosResponse, item: Types.Item}> {
    const props = {
      targetResource,
      targetId,
      targetField,
      fromId: null,
    }
    if (this.$route.params?.itemAction === 'from') {
      props.fromId = this.$route.params?.specialAction
    }
    return new Promise(resolve => {
      api.fetchItemInfoForEdit(resource, id, queries, props)
        .then((response: AxiosResponse) => {
          this.$store.dispatch('globalErrorDisplay', { response, context: 'Item for edit ' + resource + '/' + id })
          // !!! Create an object that is returned
          // and can have address tokens updated after resolve()
          const item = response.data.item ? response.data.item : {} as Types.Item
          resolve({ item, response })
          // TODO-23 does it complete addresses?
          this.getItemAddressTokens(item)
        })
    })
  },

  getItemSavePayload,
  getFormItemOnDefaultsForChange,

  redirectToAfterProcessEventAction (response: AxiosResponse<EventResponse>) {
    this.handleRedirectToFromResponse(response.data.redirect_to)
    if (response.data.redirect_to) {
      this.$nextTick(() => {
        this.$store.dispatch('reloadListItems', { updateCounts: true }).then()
      })
    }
  },

  handleRedirectToFromResponse (redirectTo: RedirectTo | null) {
    if (!redirectTo) { return }
    const resource = redirectTo.resource
    if (redirectTo.message) {
      this.$store.dispatch('showMessage', {
        message: redirectTo?.message,
        type: 'info'
      }).then()
    }
    if (redirectTo.id) { // Specific item
      if (redirectTo.view === 'edit') { // edit
        // Reload if user is already on the same item edit form
        // Otherwise token is not valid
        const isSameEditForm = this.$route.params.itemAction === 'edit' && this.$route.params.id &&
          redirectTo.id === parseInt(this.$route.params.id) && redirectTo.resource === this.$route.params.resource
        if (isSameEditForm) {
          // Could be better solution without reload, but it is such an edge case
          setTimeout(() => {
            location.reload()
          }, 3000) // Give time for user to see error message
        } else {
          this.listItemOpenForEdit(redirectTo.id, { className: resource || this.objectClass })
        }
      } else { // Show
        this.listItemOpenById(redirectTo.id, { className: resource || this.objectClass })
      }
    } else if (resource) { // List / index
      const isSameResource = this.objectClass === resource
      const isAlreadyInListView = !this.$route.params.id
      if (isSameResource && isAlreadyInListView) { // Already have same list open, refresh
        this.reloadListItems()
      } else {
        this.$router.push({ path: '/' + resource })
      }
    }
  },

  toggleSplitModeFromItemView () {
    this.$store.state.splitMode = this.$store.state.splitMode !== 'vertical'
      ? 'vertical'
      : ''
    this.$store.state.splitModeAltered = true // To trigger events related with split mode change
    // When enabling split mode, move item to the left (as was in the old portal)
    if (this.$store.state.splitMode) {
      this.moveToLeftInSplitMode()
    } else {
      // Open item in no-split view
      const id = this.splitProps.firstItemId || this.splitProps.id || this.item.id
      this.$router.push({ path: '/' + (this.splitProps.firstResource || this.resource) + '/' + id })
    }
  },

  openEditForm () {
    if (!this.openAlternateSplitItemView('edit')) {
      this.listItemOpenForEdit(this.id, this.resource)
    }
  },

  openList () {
    const routerProps = this.$route.params
    // Still same resource in /for/ route?
    // If not, user has opened alternative resource item and is opening that list
    // So exit from /for/ route to regular route
    const stillSameMainResource = this.resource === routerProps.resource
    if (routerProps && routerProps.targetResource && routerProps.targetId && stillSameMainResource) {
      this.$router.push({
        path: '/' + this.resource + '/for/' +
          routerProps.targetResource + '/' +
          routerProps.targetId + '/' +
          routerProps.targetField
      })
    } else {
      this.$router.push({ path: '/' + this.resource })
    }
  },

  showUnfinishedDfcRequestsError () {
    this.$store.dispatch('showMessage', {
      message: this.$i18n.t('aava.messages.waiting_for_dfcs_to_finish'),
      type: 'error',
      expires: 3000,
    })
  },
}
