<template>
  <div class="form-static-list-field">
    <v-menu
      v-model="showOptions"
      :close-on-content-click="false"
      :open-on-click="false"
      :close-on-click="false"
      :close-delay="2000"
      :z-index="160"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="text"
          :label="label"
          :placeholder="isHasManyField ? label : ''"
          :class="classes"
          :single-line="isHasManyFieldAndNotFirstRow"
          :persistent-placeholder="true"
          :disabled="readOnly"
          :autofocus="autofocus"
          :clearable="options.length > 0 && !includeEmptyOption"
          clear-icon="fa-times"
          spellcheck="false"
          autocomplete="off"
          color="orange"
          outlined
          dense
          hide-details
          v-bind="attrs"
          v-on="on"
          @focus="focus"
          @blur="blur"
          @keydown="keydownHandler"
        >
          <template v-slot:append>
            <v-icon
              tabindex="-1"
              :disabled="readOnly"
              class="toggle-dropdown"
              @click="toggleMenu"
            >
              fa-caret-down
            </v-icon>
          </template>
        </v-text-field>
      </template>
      <div
        v-if="showOptions"
        class="select-menu elevation-5"
      >
        <div
          id="picker-options"
          :class="(field.name + '_picker') + ' picker-options'"
        >
          <div
            v-for="(option, index) in optionsComputed"
            :key="index"
            :class="(index === selectedOptionIndex ? 'preselected ' : '') +
              ' picker-item popup-menu-el'"
            @click="selectOption(option)"
          >
            {{ option.text }}
          </div>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script lang="ts">
// Menu open-close logic
// Set close-on-click and close-on-content-click = false
// Click or enter/tab on menu option closes the menu
// Click outside menu does not have to close the menu because blur event takes care of that
// Tested close-on-click = true and @click event on input to open menu
// but, it caused a conflict between click event and v-menu built in open logic -
// so first click on input flashed the menu for some ms

import api from '../../store/api'
import Label from '../../methods/label'
import { AxiosResponse } from 'axios'
import { LP } from '@/types/LP.types'

const inflect = require('i')()

export default {
  name: 'StaticDynamicList',

  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    openPickerOnFocus: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    field: {
      type: Object as () => LP.Item | {},
      required: true,
    },
    item: {
      type: Object,
      default: () => {},
    },
    options: { // Used only for static_list
      type: Array,
      default: () => { return [] },
    },
    includeEmptyOption: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: String,
      default: null,
    },
    layoutEditMode: {
      type: Boolean,
      default: false,
    },
    showItemPickerFor: {
      type: Function,
      default: () => {},
    },
    hasManyParentField: {
      type: Object,
      default: () => {},
    },
    isHasManyFieldAndNotFirstRow: {
      type: Boolean,
      default: false,
    },
    isHasManyField: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      changeTimer: null,
      showOptions: false,
      selectedOptionIndex: -1,
      dynamicOptions: [],
    }
  },

  computed: {
    staticOptions () {
      const options: any = []
      if (this.includeEmptyOption || !this.options.length) {
        options.push(this.emptyOption)
      }
      // For LP template selector options are passed as props
      if (this.options.length) { return [...options, ...this.options] }
      // For form field field.items must contain the options
      if (!this.field.items) { return [] }
      Object.entries(this.field.items).forEach(([key, fieldItem]) => {
        options.push({
          value: key,
          text: Label.is_translation_key(fieldItem)
            ? this.$i18n.t(fieldItem)
            : fieldItem
        })
      })
      return options
    },

    emptyOption () {
      return {
        value: null,
        text: this.$i18n.t('aava.values.not_selected')
      }
    },

    optionsComputed () {
      if (this.isDynamic) {
        return this.dynamicOptions
      }
      return this.staticOptions
    },

    isDynamic () {
      return this.field.type === 'dynamic_list'
    },

    text: {
      set (value) {
        // this.$emit('input', value)
      },
      get () {
        return this.optionsComputed.filter(option => option.value === this.value)?.[0]?.text
      },
    },
    classes () {
      return [
        this.field.mandatory ? (this.value ? ' required-filled ' : ' required-missing ') : '',
        (this.showOptions ? ' menu-open' : ''),
      ]
    },
  },

  watch: {
    // Select current value in options menu
    showOptions (value, before) {
      if (!value || !this.value) { return }
      this.selectedOptionIndex = this.optionsComputed.map(option => option.value)
        .indexOf(this.value)
    }
  },

  created () {
    // this.options includes all possible options for dynamic_list
    // For edit form to show initial value, set dynamicOptions
    this.dynamicOptions = this.staticOptions
    this.selectedOptionIndex = -1
    document.addEventListener('keydown', this.keyDownListener)
  },

  destroyed () {
    document.removeEventListener('keydown', this.keyDownListener)
  },

  methods: {
    toggleMenu () {
      this.showOptions = !this.showOptions
    },

    keydownHandler (e) {
      if ([
        'Tab', // For list-edit
      ].includes(e.code)) {
        this.$emit('keyPress', e)
      }
    },

    selectOption (option) {
      this.$emit('input', option.value)
      this.showOptions = false
      this.$emit('changeListener', 0)
    },

    blur () {
      setTimeout(() => {
        this.showOptions = false
      }, 300)
    },

    focus (e) {
      this.getDynamicOptions()
      if (this.openPickerOnFocus) {
        this.showOptions = true
      }
      setTimeout(() => {
        this.$store.dispatch('closeItemPicker')
      }, 100) // Give time for reference field option pick action and destroy
    },

    getDynamicOptions () {
      if (!this.isDynamic) { return }
      this.dynamicOptions = []
      let path = ''
      const forItem = this.item
      if (forItem?.targetObject) {
        const forObjectClass = (this.hasManyParentField && this.hasManyParentField.name) || this.resource
        // Path is needed for the api when requesting options for dynamic list options in has-many
        path = '~path=' + forItem.targetObject['@class'] +
          ':' + (forItem.targetObject.token || forItem.targetObject.id) + '%20' + forObjectClass + '&'
      }
      api.sendRequest('/api/' + this.resource + '/' + (this.item.token || this.item.id) + '/list_values/' + this.field.name, {}, [], path)
        .then((response: AxiosResponse) => {
          if (response.data.values) {
            this.dynamicOptions.push(this.emptyOption)
            Object.keys(response.data.values).forEach(key => {
              this.dynamicOptions.push({
                value: key,
                text: response.data.values[key],
              })
            })
          }
          this.$set(this, 'dynamicOptions', this.dynamicOptions)
          this.$store.dispatch('globalErrorDisplay', { response, context: 'Get dynamic list options' })
        })
    },

    keyDownListener (e) {
      if (!this.showOptions) { return }
      if (!this.optionsComputed || this.optionsComputed.length === 0) { return }
      if (e.key === 'ArrowDown') {
        this.selectedOptionIndex++
        if (this.selectedOptionIndex >= this.optionsComputed.length) {
          this.selectedOptionIndex = this.optionsComputed.length - 1
        }
      } else if (e.key === 'ArrowUp') {
        this.selectedOptionIndex--
        if (this.selectedOptionIndex < 0) {
          this.selectedOptionIndex = 0
        }
      } else if (['Enter', 'Tab'].includes(e.key) && this.optionsComputed[this.selectedOptionIndex]) {
        this.selectOption(this.optionsComputed[this.selectedOptionIndex])
      }
    },
  },
}
</script>

<style lang="scss">
.form-static-list-field {
  .v-input__icon--append .v-icon {
    font-size: 16px !important;
    color: #aaa;
  }
}
.preselected {
  background: #FFF59D;
}
</style>
