<template>
  <v-container
    v-if="!showLoader"
    id="item-field-selector"
    style="width: 260px;"
    class="pa-0"
  >
    <v-row no-gutters>
      <v-col
        cols="12"
        class="field-selector-search white pa-4 pb-2 text-center"
      >
        <v-btn
          v-if="selectedLayoutEditorFields.length > 0"
          color="green"
          dark
          class="mt-8 elevation-0"
          absolute
          top
          right
          fab
          small
          @click="showItemFieldMenu($event, null)"
        >
          {{ selectedLayoutEditorFields.length }}
        </v-btn>
        <v-text-field
          v-model="searchTerm"
          prepend-inner-icon="fa-search"
          clear-icon="fa-times"
          color="grey darken-2"
          placeholder="Search from available fields"
          :class="selectedLayoutEditorFields.length > 0 ? 'pr-14' : ''"
          outlined
          hide-details
          single-line
          dense
          clearable
        />
      </v-col>
      <v-col class="px-4 pb-3">
        <v-btn
          v-for="([categoryKey, category], i) in Object.entries(fieldCategories)"
          :key="i"
          v-tooltip.top="$t('aava.field_types.' + categoryKey)"
          class="filter-type-btn"
          icon
          :outlined="selectedCategory === category"
          small
          @click="selectCategory(category)"
        >
          <v-icon
            v-if="category.icon"
            x-small
          >
            {{ category.icon }}
          </v-icon>
          <template v-else>
            {{ category.text }}
          </template>
        </v-btn>
      </v-col>
      <v-col
        v-if="render"
        id="item-field-selector-inner"
        cols="12"
        class="px-4 pb-4 bt-2"
      >
        <v-chip
          v-for="(field, index) in fieldsComputed"
          :key="index"
          :data-field-id="field.id"
          :data-is-lpi="field.isLPI"
          :data-field-index="field.fieldIndex"
          :outlined_x="isFieldSelected(field) || (!!(field.visible && fieldInFieldSet(field)))"
          :dark="!!(field.visible && fieldInFieldSet(field))"
          :color="chipColor(field)"
          :class="fieldClasses(field)"
          text-color="grey darken-3"
          block
          @click.stop="showItemFieldMenu($event, field)"
        >
          <div class="type-icon">
            <v-icon
              v-if="fieldIcon(field)"
              x-small
              class=""
            >
              {{ fieldIcon(field) }}
            </v-icon>
            <template v-else>
              {{ fieldIconText(field) }}
            </template>
          </div>
          <div
            class="field-select"
            @click.stop="toggleLayoutEditorSelectedField(field.name)"
          >
            <i :class="(isFieldSelected(field) ? 'fa-solid fa-square-check' : 'fa-regular fa-square')" />
          </div>
          <div class="field-label">
            {{ field.label }}
          </div>
          <div
            v-if="field.visible && fieldInFieldSet(field)"
            class="eye-lookup"
            @click.stop="highlightField(field)"
          >
            <v-icon small>
              fa-eye
            </v-icon>
          </div>
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import methods from './../methods'
import state from './../../store/state'
import { createHelpers } from 'vuex-map-fields'
import translateAttribute from '@/utilities/translateAttribute'
import { FieldType, LPI } from '@/types/LP.types'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

interface FieldCategory {
  types: FieldType[]
  icon: string
  text?: string
}
type ExtendedFieldType = FieldType | 'list' | 'html'

type FieldCategoriesMap = {
  [key in ExtendedFieldType]?: FieldCategory
}

const fieldCategories: FieldCategoriesMap = {
  string: { types: ['string', 'search_string', 'password'], icon: '', text: 'A' },
  text: { types: ['text', 'richtext', 'password'], icon: '', text: '[A]' },
  numeric: { types: ['numeric', 'decimal', 'percentage'], icon: 'fa-1' },
  address: { types: ['address'], icon: 'fa-location-dot' },
  datetime: { types: ['datetime', 'date', 'date_range'], icon: 'fa-calendar' },
  reference: { types: ['static_list', 'dynamic_list', 'reference', 'polymorphic_autocomplete', 'link', 'belongs_to', 'has_one'], icon: 'fa-link' },
  list: { types: ['has_many_reference', 'has_many', 'has_and_belongs_to_many'], icon: 'fa-list-ol' },
  quantity: { types: ['quantity'], icon: 'fa-1' },
  boolean: { types: ['boolean'], icon: 'fa-check-square' },
  price: { types: ['price'], icon: 'fa-euro-sign' },
  file: { types: ['file', 'image', 'flash', 'video', 'pdf'], icon: 'fa-paperclip' },
  process: { types: ['process', 'state', 'process_events', 'process_state'], icon: 'fa-play' },
  html: { types: ['raw', 'raw_full'], icon: 'fa-code' },
}

export default {
  name: 'ItemFieldSelector',

  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
    render: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: String,
      default: null,
    },
    showItemFieldMenu: {
      type: Function,
      default: () => {},
    },
    toggleLayoutEditorSelectedField: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    return {
      searchTerm: null,
      selectedCategory: null,
      fieldCategories,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    firstSelectedField () {
      return this.fieldsComputed.filter(field => this.selectedLayoutEditorFields.includes(field.name))?.[0]
    },

    iconsByType () {
      const iconsByType = {}
      Object.keys(this.fieldCategories).forEach(category => {
        this.fieldCategories[category].types.forEach(type => {
          iconsByType[type] = this.fieldCategories[category]
        })
      })
      return iconsByType
    },

    visibleFieldSetIds () {
      const fieldSets = this.layoutContainerFieldSets[this.selectedLayoutProfileIdByModel[this.resource]] || []
      return fieldSets.map(i => i.id)
    },

    fieldsComputed () {
      const fields = this.fields.map(field => {
        field.label = this.getFieldLabel(field)
        return field
      })
      return fields
        .filter(field => {
          if (!field.isLPI) { // Skip form extra types here
            return false
          }
          // Exclude for non-aava (admin) users
          if (['readers', 'writers'].includes(field.name) && !this.$store.getters.isAdminUser) {
            return false
          }
          const searchMatch = !this.searchTerm || field.label.toString().toLowerCase().includes(this.searchTerm.toLowerCase())
          const typeMatch = !this.selectedCategory || this.selectedCategory.types.includes(field.type)
          return searchMatch && typeMatch
        })
        .sort((a, b) => a.label < b.label ? -1 : 1)
        .sort((a, b) => a.type > b.type ? -1 : 1)
    },
  },

  methods: {
    ...methods,

    highlightField (field) {
      if (!document.getElementsByClassName('form-field-' + field.name)?.[0]) { return }
      this.highlightedFieldName = field.name
      this.$vuetify.goTo('.form-field-' + field.name, {
        duration: 300,
        easing: 'easeInOutCubic',
        offset: 50,
        container: '.item-form-container',
      })
    },

    selectCategory (category) {
      this.selectedCategory = this.selectedCategory === category ? null : category
    },

    isFieldSelected (field) {
      return this.selectedLayoutEditorFields.includes(field.name)
    },

    chipColor (field) {
      if (this.isFieldSelected(field)) {
        // return 'red'
      }
      return field.visible && this.fieldInFieldSet(field) ? '#E0E0E0' : 'white'
    },

    fieldClasses (field: LPI) {
      return [
        'form-field-selectable',
        'field-set-field',
        'mb-1',
        'pl-0',
        'le-select-' + field.name,
        (this.isFieldSelected(field) && 'selected-layout-editor-field'),
      ]
    },

    getFieldLabel (field) {
      return translateAttribute(this.resource, field.name, this.locale, this.$i18n, 'show')
    },

    fieldInFieldSet (field) {
      return field.layout_container_field_set &&
        field.layout_container_field_set.id &&
        this.visibleFieldSetIds.includes(field.layout_container_field_set.id)
    },

    fieldIcon (field) {
      if (!this.iconsByType[field.type]?.icon && !this.iconsByType[field.type]?.text) {
        console.warn('Handle missing file type:', field.type)
        return 'fa-check'
      }
      return this.iconsByType[field.type].icon
    },

    fieldIconText (field) {
      return this.iconsByType[field.type]?.text
    },
  }
}
</script>

<style lang="scss">
#item-field-selector {
  min-width: 250px;
  .filter-type-btn {
    margin: 0 2px;
  }
  .field-set-field {
    .field-drag-handle, .item-show-value {
      display: none;
    }
  }
  border-left: 1px solid #ccc;
  .form-field-selectable {
    position: relative;
    width: 100%;
    .field-select {
      display: none;
      i {
        line-height: 30px;
        font-size: 18px;
      }
    }
    .type-icon, .field-select {
      text-align: center;
      width: 35px;
      padding-left: 5px;
      font-size: 12px;
    }
    .field-select {
      color: #aaa;
    }
    .eye-lookup {
      position: absolute;
      display: none;
      right: 10px;
      color: #888;
    }
  }
  .form-field-selectable:hover {
    .eye-lookup {
      display: block;
    }
    .field-select {
      display: block;
    }
    .type-icon {
      display: none;
    }
  }
  .selected-layout-editor-field {
    .field-select {
      display: block;
      color: #388E3C;
    }
    .type-icon {
      display: none;
    }
  }
  .fa-search {
    font-size: 14px;
  }
  .fa-times {
    font-size: 18px;
  }
  .field-selector-search {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
</style>
