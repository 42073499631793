<template>
  <div
    class="link color-btn"
    :style="buttonStyle === 'regular' ? ('background:' + valueComputed + ';') : ''"
  >
    <v-menu
      :z-index="160"
      max-width="300"
      content-class="color-select-menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="buttonStyle === 'fab'"
          :color="valueComputed"
          class="fab-color-select"
          depressed
          fab
          x-small
          v-bind="attrs"
          v-on="on"
        />
        <v-container
          v-else
          v-bind="attrs"
          v-on="on"
        >
          <v-row>
            <v-col
              style="color: white;"
              class="text-center"
            >
              {{ $i18n.t('aava.buttons.add') }} {{ $i18n.t('aava.general.color').toLowerCase() }}
            </v-col>
          </v-row>
        </v-container>
      </template>
      <v-container class="white pa-0 overflow-hidden">
        <v-row>
          <v-col cols="12">
            <v-container>
              <v-row>
                <v-col
                  v-for="(color, index) in presetColors"
                  :key="index"
                  :style="'background: ' + color"
                  cols="2"
                  class="text-center link"
                  @click="select(color)"
                >
                  <v-icon
                    :color="valueComputed === color ? 'white' : color"
                    small
                  >
                    fa-check
                  </v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-menu>
  </div>
</template>

<script lang="ts">
import colorPresets from '@/components/App/colorPresets'

export default {
  name: 'ColorPicker',

  props: {
    value: {
      type: String,
      default: undefined,
    },
    colorPreset: {
      type: String,
      default: 'buttonBg',
    },
    buttonStyle: {
      type: String,
      default: 'regular',
    },
  },

  data () {
    return {
      colorPicker: false,
    }
  },

  computed: {
    valueComputed: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value || '#AAAAAA'
      },
    },

    presetColors () {
      return colorPresets[this.colorPreset]
    },
  },

  methods: {
    select (color) {
      this.valueComputed = color
      this.$emit('change')
    },
  },
}
</script>

<style lang="scss">
.color-btn {
  display: inline-block;
  margin-left: 8px;
  .fab-color-select {
    border: 2px solid #999 !important;
    height: 24px !important;
    width: 24px !important;
  }
}
</style>
