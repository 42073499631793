<template>
  <div
    id="multi-edit-menu"
    class="multi-edit-menu popup-menu elevation-5"
  >
    <div class="multi-edit-title">
      {{ $i18n.t('aava.index.edit_many_selected') }}
    </div>
    <div
      v-if="savingInProgress"
      class="multi-edit-loader"
    >
      <v-progress-linear
        :indeterminate="true"
        height="3"
        color="teal"
      />
    </div>
    <div
      v-else
    >
      <div
        v-if="columnType === 'richtext'"
      >
        <richtext-editor
          v-model="searchTerm"
          :compact="true"
        />
      </div>
      <div
        v-else-if="columnType === 'static_list'"
      >
        <!-- TODO Support dynamic_list here? -->
        <v-autocomplete
          v-model="searchTerm"
          :items="staticListOptions"
          :label="$i18n.t(objectClass + '.attributes.'+ multiEditColumn)"
          required
          attach
          outlined
          hide-details
        />
      </div>
      <ReferenceField
        v-else-if="['polymorphic_autocomplete', 'reference'].includes(columnType)"
        v-model="selectedReferenceOption"
        :resource="objectClass"
        :field="columnInfo"
        :modal="true"
        :show-item-picker-for="showItemPickerFor"
        xs12
      />
      <div
        v-else-if="columnType === 'process_events'"
        xs12
      >
        <v-autocomplete
          v-model="searchTerm"
          attach
          :items="processEventActions"
          :label="$i18n.t(objectClass + '.attributes.'+ multiEditColumn)"
          required
          outlined
          hide-details
        >
          <template v-slot:item="{ item, title }">
            <div
              class="process-list-item"
            >
              <v-list-item-content
                v-tooltip.bottom="item.text && item.text.length > 30 ? item.text : ''"
              >
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </div>
          </template>
        </v-autocomplete>
      </div>
      <div
        v-else-if="['datetime', 'date'].includes(columnType)"
        xs12
      >
        <DateTime
          v-model="searchTerm"
          :date-only="columnType === 'date'"
        />
      </div>
      <div
        v-else-if="columnType === 'boolean'"
      >
        <v-switch
          v-model="searchTerm"
          :label="$i18n.t(objectClass + '.attributes.'+ multiEditColumn)"
          color="success"
        />
      </div>
      <div
        v-else
        class="multi-edit-field"
      >
        <v-text-field
          ref="multiEditValue"
          v-model="searchTerm"
          :label="$i18n.t(objectClass + '.attributes.'+ multiEditColumn)"
          outlined
          autofocus
          hide-details
        >
          <div
            v-if="['quantity', 'price'].includes(columnType)"
            slot="append"
            class="multi-edit-unit"
            @click="unitClickHandler($event)"
          >
            {{ unit.summary }}
          </div>
        </v-text-field>
      </div>
      <v-layout
        wrap
        class="multi-edit-buttons"
      >
        <v-flex xs6>
          <v-btn
            block
            text
            color="success"
            @click.stop="saveNewValues"
          >
            {{ $i18n.t('aava.actions.save') }}
          </v-btn>
        </v-flex>
        <v-flex xs6>
          <v-btn
            block
            text
            color="info"
            @click.stop="cancel"
          >
            {{ $i18n.t('aava.actions.cancel') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script lang="ts">
import RichtextEditor from './../Form/RichtextEditor.vue'
import ReferenceField from './../Form/ReferenceField.vue'
import DateTime from '../Form/DateTime.vue'
import listViewAPI from './../../store/api'
import Label from './../../methods/label'
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import { AxiosResponse } from 'axios'
import { ItemPicker } from '@/types/ItemPicker'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

const inflect = require('i')()

export default {
  name: 'MultiEditMenu',

  components: {
    DateTime,
    RichtextEditor,
    ReferenceField,
  },

  data () {
    return {
      searchTerm: '',
      referenceOptions: [],
      referenceOptionsTotal: 0,
      selectedReferenceOption: {},
      savingInProgress: false,
      processEventActions: [],
      unit: {
        summary: '-'
      },
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    columnType () {
      return this.columnInfo && this.columnInfo.type
    },

    pickerSelectedId () {
      return this.itemPickerSelectedOption.id
    },

    columnInfo () {
      if (!this.multiEditColumn || !this.layoutProfileItems) { return {} }
      return this.layoutProfileItems
        .find(column => column.name === this.multiEditColumn)
    },

    staticListOptions () {
      if (!this.columnInfo.items) { return [] }
      const options: any = [{
        value: null,
        text: this.$i18n.t('aava.values.not_selected')
      }]
      Object.keys(this.columnInfo.items).forEach(index => {
        options.push({
          value: index,
          text: Label.is_translation_key(this.columnInfo.items[index])
            ? this.$i18n.t(this.columnInfo.items[index])
            : this.columnInfo.items[index]
        })
      })
      return options
    }
  },

  watch: {
    pickerSelectedId (value) {
      if (!value) { return }
      switch (this.columnType) {
        case 'reference':
        case 'polymorphic_autocomplete':
          this.selectedReferenceOption = JSON.parse(JSON.stringify(this.itemPickerSelectedOption))
          this.searchTerm = this.itemPickerSelectedOption.summary
          break
        case 'quantity':
        case 'price':
          this.unit = JSON.parse(JSON.stringify(this.itemPickerSelectedOption))
      }
    },

    searchTerm (value) {
      switch (this.columnType) {
        case 'reference':
        case 'polymorphic_autocomplete':
          this.itemPickerSearchTerm = value
          if (!value) {
            this.selectedReferenceOption = {}
            this.itemPickerSelectedOption = {}
          }
      }
    },

    columnType () {
      this.initialize()
    }
  },

  created () {
    this.initialize()
    document.addEventListener('keyup', this.listenKeyUp)
  },

  destroyed () {
    this.itemPickerSearchTerm = ''
    document.removeEventListener('keyup', this.listenKeyUp)
  },

  methods: {
    ...methods,

    initialize () {
      this.processEventActions = []
      this.searchTerm = ''
      if (this.columnType) {
        if (this.columnType === 'process_events') {
          this.getProcessEventActions()
        }
      }
    },

    showImagePrompt (command) {
      const src = prompt('Enter the url of your image here')
      if (src !== null) {
        command({ src })
      }
    },

    getProcessEventActions () {
      listViewAPI.getMultiEditActionsFor(this.objectClass).then((result: AxiosResponse) => {
        this.processEventActions = result.data.actions?.map(action => {
          return {
            text: action.label,
            value: action.value
          }
        })
      })
    },

    blurInput () {
      this.$refs.multiEditValue.blur()
    },

    unitClickHandler (e) {
      if (!['quantity', 'price'].includes(this.columnType)) {
        return
      }
      const unitPrefix = this.columnType === 'quantity'
        ? 'unit'
        : 'currency'
      // TODO - add callback and use payload
      this.showItemPickerFor(e, {
        objectClass: inflect.pluralize(unitPrefix),
        forObjectClass: this.objectClass,
        forFieldName: this.columnInfo.name + '_' + unitPrefix,
        selectColumns: ['summary']
      } as ItemPicker.Props)
    },

    getNewSaveValues () {
      const newValues = {}
      const locale = this.$store.state.locale
      const multiLangPrefix = this.columnInfo.multi_language
        ? '_' + locale
        : ''
      if (!this.selectedItems || this.selectedItems.length < 1) { return }
      this.savingInProgress = true
      const unitPrefix = this.columnType === 'quantity'
        ? 'unit'
        : 'currency'
      switch (this.columnType) {
        case 'reference':
        case 'polymorphic_autocomplete':
          newValues[this.columnInfo.name + multiLangPrefix] = this.selectedReferenceOption
          break
        case 'quantity':
        case 'price':
          newValues[this.columnInfo.name] = this.searchTerm
          if (this.unit.id) {
            newValues[this.columnInfo.name + '_' + unitPrefix] = this.unit
          }
          break
        default:
          newValues[this.columnInfo.name + multiLangPrefix] = this.searchTerm
      }
      return newValues
    },

    saveNewValues () {
      const newValues = this.getNewSaveValues()
      if (this.columnType === 'process_events') {
        this.saveProcessEvents()
      } else {
        this.$store.dispatch('multiEditUpdateValue', {
          selected: this.selectedItems,
          updatedField: this.multiEditColumn,
          newValues
        }).then(updateResults => {
          this.handleResponses(updateResults)
          this.closeFormAndUpdateList()
        })
      }
    },

    saveProcessEvents () {
      listViewAPI.setMultiEditAction(this.objectClass, this.searchTerm,
        this.selectedItems.map(itemIndex => this.listItems[itemIndex].id)
      )
        .then(updateResult => {
          this.showExecuteMessages(updateResult)
          this.closeFormAndUpdateList()
        })
    },

    handleResponses (responses: AxiosResponse[]) {
      const messages: any = []
      let errorMessages = []
      let successCount = 0
      responses.forEach((response: AxiosResponse) => {
        if (response.data.status === 'ok') {
          successCount++
        } else if (response.data.messages) {
          if (errorMessages.length < 15) { // No need to show more errors, message body goes also too long
            errorMessages = errorMessages.concat(response.data.messages)
          }
        }
      })
      // Combine messages into single one, then user doesn't have to close manually one by one
      if (errorMessages.length > 0) {
        messages.push({
          message: errorMessages.map((message: any) => message?.text || message).join('<br>'),
          type: 'error',
        })
      }
      if (successCount > 0) {
        messages.push(
          {
            message: this.$i18n.t('aava.index.messages.items_updated',
              { items: successCount }),
            type: 'success'
          }
        )
      }
      this.$store.dispatch('addFlashMessages', messages)
    },

    closeFormAndUpdateList () {
      this.resetData()
      this.closeForm()
      this.reloadListItems()
    },

    cancel () {
      this.closeForm()
      this.resetData()
    },

    closeForm () {
      this.multiEditColumn = ''
    },

    // Keys are listened in the body but this component prevents keyup triggers
    listenKeyUp (e) {
      switch (e.key) {
        case 'Escape':
          // Close multi edit menu only when item picker is not open
          if (!this.showItemPicker) {
            this.closeForm()
          }
          e.stopPropagation()
          break
      }
    },

    resetData () {
      this.searchTerm = ''
      this.selectedReferenceOption = {}
      this.savingInProgress = false
      this.unit = {
        summary: '-'
      }
    }
  }
}
</script>

<style>
.multi-edit-loader {
  min-width: 100px;
  min-height: 50px;
  padding: 30px 15px;
}
.multi-edit-field {
  padding: 10px 0 5px 0;
  overflow: auto;
}
.multi-edit-menu {
  min-width: 350px;
  padding: 10px !important;
}
.multi-edit-buttons {
  width: 100%;
  float: left;
}
.multi-edit-title {
  line-height: 22px;
  font-size: 14px;
  padding-bottom: 5px;
}
.multi-edit-unit {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 0 4px 5px;
  color: #666;
}
.process-list-item {
  width: 100%;
}
</style>
