<template>
  <v-row>
    <v-col>
      <v-btn
        color="grey"
        class="float-right"
        icon
        @click="$emit('closeLayoutEditMode')"
      >
        <v-icon small>
          fa-times
        </v-icon>
      </v-btn>
      {{ layoutEditorNotAvailableMessage }}
    </v-col>
  </v-row>
</template>

<script lang="ts">
export default {
  name: 'LayoutEditorNotAvailable',

  computed: {
    layoutEditorNotAvailableMessage () {
      if (this.$store.state.splitMode) {
        return this.$i18n.t('aava.messages.exit_split_mode')
      }
      return this.$i18n.t('aava.messages.layout_editor_use_larger_resolution')
    },
  }
}
</script>
