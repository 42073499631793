import listItemMethods from './../methods/listItem/listItemMethods'
import listMethods from './../methods/list/listMethods'
import itemMethods from '@/methods/item/itemMethods'
import listFilterMethods from './../methods/list/listFilterMethods'
import listDisplayMethods from './../methods/list/listDisplayMethods'

import LPIMethods from './../models/LayoutProfileItem/LPI.methods'
import appMethods from '@/methods/list/appMethods'
import itemLayoutDragAndDropMethods from '@/components/Item/itemLayoutDragAndDrop.methods'
import itemPickerMethods from '@/components/ItemPicker/itemPicker.methods'
import LPMethods from '@/models/LayoutProfile/LP.methods'
import LCMethods from '@/models/LayoutContainer/LC.methods'
import LCFSMethods from '@/models/LayoutContainerFieldSet/LCFS.methods'
import { AxiosResponse } from 'axios'
import { EventResponseMessage } from '@/types/API.responses'

// TODO - limit importing all methods to so many components, which do not need that much

const exports = {
  ...LPIMethods,
  ...appMethods,

  ...listItemMethods,
  ...listFilterMethods,
  ...listMethods,
  ...listDisplayMethods,
  ...itemMethods,
  ...itemLayoutDragAndDropMethods,
  ...itemPickerMethods,
  ...LPMethods,
  ...LCMethods,
  ...LCFSMethods,

  // Handle message from Process Event or Action response
  showExecuteMessages (response: AxiosResponse) {
    const messages: { text: string, type: string }[] = []
    if (response.data.items?.length > 0) {
      messages.push({
        text: this.$i18n.t('aava.index.messages.items_executed', {
          items: response.data.items.length
        }),
        type: 'info',
      })
    }
    const messagesByType = {}
    let errorsWithoutText = 0
    if (response.data.messages?.length > 0) {
      // Organize messages by type and text
      response.data.messages.forEach((message: EventResponseMessage | string) => {
        if (!message) {
          errorsWithoutText++
          console.warn('Missing message, analyze response', response)
        } else {
          // Depending on string or object type
          // In case server returns "messages":[[]], set default type and text even if not string type
          const type = (typeof message === 'string' ? 'info' : message.type) ?? 'info'
          const text = (typeof message === 'string' ? message : message.text) ?? 'OK'
          if (!messagesByType[type]) {
            messagesByType[type] = {}
          }
          if (!(text in messagesByType[type])) {
            messagesByType[type][text] = 0
          }
          messagesByType[type][text]++
        }
      })
      Object.keys(messagesByType).forEach(type => {
        Object.keys(messagesByType[type]).forEach(text => {
          const count = messagesByType[type][text]
          messages.push({
            text: text + (count > 1 ? ' (' + count + ')' : ''),
            type,
          })
        })
      })
    }
    if (errorsWithoutText > 0) {
      messages.push({
        text: this.$i18n.t('aava.index.messages.failed_to_execute_items', {
          items: errorsWithoutText
        }),
        type: 'error',
      })
    }
    // Simple text message
    if (response.data.message) {
      messages.push({
        text: response.data.message,
        type: 'success',
      })
    }
    this.$store.dispatch('addFlashMessages', messages).then()
  },

  runActionCode (code) {
    try {
      // TODO - still in use? Better not to have it
      // eslint-disable-next-line
      eval(code)
    } catch (e) {
      if (e instanceof SyntaxError) {
        this.$store.dispatch('addFlashMessage', {
          message: 'Code error: ' + e.message,
          type: 'error',
        })
      }
    }
  },

  escapeKeyHandler () {
    this.$store.dispatch('clearSelected')
    this.$store.commit('hideMenuPopups')
  },
}

export default exports
