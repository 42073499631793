<template>
  <v-container
    class="item-form-container pa-0 align-content-start"
    fill-height
    fluid
  >
    <CompatibilityViewReports
      v-if="reportProps"
      :report-props="reportProps"
      :model-name="modelName"
    />
    <iframe
      v-else-if="!loading"
      id="iFrame"
      class="fill-height"
      style="width: 100%; height: 100%; border: none;"
      :src="viewUrl"
    />
  </v-container>
</template>

<script lang="ts">
import CompatibilityViewReports from '@/components/App/CompatibilityViewReports.vue'
import api from '@/store/api'
import { AxiosResponse } from 'axios'
export default {
  name: 'CompatibilityView',

  components: {
    CompatibilityViewReports,
  },

  props: {
    modelName: {
      type: String,
      default: '',
    },
    viewName: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      loading: true,
      content: '',
      reportProps: null,
    }
  },

  computed: {
    viewUrl () {
      return '/compatibility/' + this.modelName + '/' + this.viewName
    },
  },

  created () {
    this.getContent()
  },

  methods: {
    getContent () {
      this.loading = true
      api.sendRequest(this.viewUrl, {}, []).then((response: AxiosResponse) => {
        this.$store.dispatch('globalErrorDisplay', { response, context: 'Compatibility view' }).then(errorsFound => {
          if (!errorsFound) {
            if (response.data.reports) {
              this.reportProps = response.data
            }
          }
          this.loading = false
        })
      })
    },
  },
}
</script>
