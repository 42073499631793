<template>
  <v-btn
    :color="buttonStyle.color"
    :disabled="disabled"
    :href="'#' + (buttonStyle.path)"
    :class="'has-many-item-action-button ' + buttonKey + '-btn'"
    :target="modal ? '_blank' : ''"
    tabindex="-1"
    fab
    text
    icon
    small
    @click="$emit('click', $event)"
  >
    <v-icon x-small>
      fa {{ buttonStyle.icon }}
    </v-icon>
  </v-btn>
</template>

<script lang="ts">
import { HasManyActionButtonStyle, HasManyActionButtonType } from '@/types/AppTypes'

export default {
  name: 'HasManyItemActionBtn',

  props: {
    buttonKey: {
      type: String as () => HasManyActionButtonType,
      required: true,
    },
    buttonStyle: {
      type: Object as () => HasManyActionButtonStyle,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    readyForEditMode: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    disabled () {
      return (this.buttonStyle.disabled) || (this.edit && !this.readyForEditMode) || this.$store.state.itemLayoutEditMode
    },
  },
}
</script>
