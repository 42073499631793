<template>
  <div class="layout-profile-form white elevation-1 text-left">
    <v-container class="py-3 px-5 align-center elevation-0">
      <v-row>
        <v-col cols="12">
          <TextField
            v-model="formData.name"
            :field="{ type: 'string', name: 'name' }"
            :label="$i18n.t('layout_profiles.attributes.name')"
            :autofocus="true"
            @enter="saveForm"
          />
        </v-col>

        <v-col
          v-if="isList && !isNew"
          cols="12"
        >
          <StaticDynamicList
            v-model="formData.showViewLayoutProfileId"
            :options="itemLayoutProfileAsOptions"
            :label="$i18n.t('aava.tabs.show_view_lp')"
            :field="{}"
            :include-empty-option="true"
            spellcheck="false"
          />
        </v-col>

        <v-col
          v-if="isList && !isNew"
          cols="12"
        >
          <StaticDynamicList
            v-model="formData.editViewLayoutProfileId"
            :options="itemLayoutProfileAsOptions"
            :label="$i18n.t('aava.tabs.edit_view_lp')"
            :field="{}"
            :include-empty-option="true"
            spellcheck="false"
          />
        </v-col>

        <v-col
          v-if="isNew"
          cols="12"
        >
          <SwitchField
            v-model="formData.createDefault"
            :label="$i18n.t('aava.tabs.use_default_view')"
            class="switch-default-view"
          />
        </v-col>

        <v-col
          v-if="isNew"
          cols="12"
        >
          <StaticDynamicList
            v-model="formData.selectedTemplate"
            :options="layoutProfileTemplates"
            :label="$i18n.t('aava.tabs.clone')"
            :field="{}"
            :read-only="formData.createDefault || layoutProfileTemplates.length === 0"
            spellcheck="false"
          />
        </v-col>
        <v-col
          v-if="isNew"
          cols="12"
        >
          <SwitchField
            v-model="formData.doCopy"
            :read-only="!formData.selectedTemplate || formData.createDefault"
            :label="$i18n.t('aava.tabs.copy')"
          />
        </v-col>

        <v-col cols="12">
          <ColorPicker v-model="selectedHexColor" />
        </v-col>
        <v-col
          v-if="saving"
          cols="12"
        >
          <v-progress-linear
            :color="colors.progressBar"
            height="4"
            value="100"
            rounded
            indeterminate
          />
        </v-col>
        <template v-else>
          <v-col
            cols="6"
            class="pr-2"
          >
            <v-btn
              color="green"
              class="lp-save-btn"
              depressed
              dark
              small
              block
              @click.stop="saveForm"
            >
              {{ $i18n.t('aava.actions.save') }}
            </v-btn>
          </v-col>

          <v-col
            cols="6"
            class="pl-2"
          >
            <v-btn
              color="grey"
              class="lp-cancel-btn"
              depressed
              dark
              small
              block
              @click.stop="$emit('close')"
            >
              {{ $i18n.t('aava.actions.cancel') }}
            </v-btn>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import layoutEditMethods from '@/components/Item/LayoutEditor/layoutEditMethods'
import TextField from '@/components/Form/TextField.vue'
import StaticDynamicList from '@/components/Form/StaticDynamicList.vue'
import SwitchField from '@/components/Form/SwitchField.vue'
import ColorPicker from '@/components/App/ColorPicker.vue'
import layoutProfileMethods from '../../methods/listItem/layoutProfileMethods.js'
import { LP } from './../../types/LP.types'

const { mapFields }: any = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'LayoutProfileForm',

  components: {
    ColorPicker,
    SwitchField,
    StaticDynamicList,
    TextField,
  },

  props: {
    layoutProfile: {
      type: Object as PropType<LP.LayoutProfile>,
      default: () => { return {} },
    },
  },

  data () {
    return {
      saving: false,
      selectedHexColor: null,
      formData: {
        selectedColor: null,
        selectedTemplate: null,
        name: null,
        createDefault: false,
        doCopy: false,
        editViewLayoutProfileId: null,
        showViewLayoutProfileId: null,
      },
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    layoutProfileIdForEdit () {
      return this.layoutProfile.id
    },

    // props () {
    //   return this.layoutProfileFormProps
    // },

    layoutProfilesComputed (): LP.LayoutProfile[] {
      return this.isList
        ? this.layoutProfiles
        : this.itemLayoutProfilesByModel[this.layoutProfile.controller]
    },

    isNew () {
      return !this.layoutProfile.id
    },

    isList () {
      return this.layoutProfile.action === 'index'
    },

    selectedItemLayoutProfileId () {
      return this.selectedLayoutProfileIdByModel[this.objectClass]
    },

    isSelectedItemLayoutProfile () {
      return !this.isList && this.layoutProfile?.id === this.selectedItemLayoutProfileId
    },

    itemLayoutContainers () {
      return this.layoutContainers[this.selectedItemLayoutProfileId]
    },

    itemLayoutContainerFieldSets () {
      return this.layoutContainerFieldSets[this.selectedItemLayoutProfileId]
    },

    itemLayoutProfileAsOptions () {
      return (this.itemLayoutProfilesByModel[this.objectClass] || []).map(item => {
        return {
          value: item.id,
          text: item.name,
        }
      })
    }
  },

  watch: {
    layoutProfileIdForEdit (value) {
      // New LP add form opened, reset form data
      if (!value) {
        this.setFormData()
      }
    },
    'formData.createDefault' (value) {
      if (value) {
        this.$set(this.formData, 'selectedTemplate', null)
        this.$set(this.formData, 'doCopy', false)
      }
    },
  },

  created () {
    this.setFormData()
    this.getItemLayoutProfiles()
  },

  methods: {
    ...methods,
    ...layoutEditMethods,
    ...layoutProfileMethods,

    getItemLayoutProfiles () {
      this.$store.dispatch('loadItemLayoutProfiles', this.objectClass)
    },

    setFormData () {
      if (!this.layoutProfile.id) { return }
      this.selectedHexColor = this.layoutProfile.timelineConfiguration.backgroundColor
      this.formData.editViewLayoutProfileId = this.layoutProfile.timelineConfiguration.editViewLayoutProfileId
      this.formData.showViewLayoutProfileId = this.layoutProfile.timelineConfiguration.showViewLayoutProfileId
      this.formData.name = this.layoutProfile.name
    },

    saveForm () {
      if (this.layoutProfile.id) {
        this.updateLayoutProfile()
      } else {
        this.createNewLayoutProfile()
      }
    },

    closeLayoutProfileMenu () {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.layout-profile-form {
  padding: 18px 0 12px 0;
  width: 300px;
  .col {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .v-input--selection-controls {
    margin: 0;
    padding: 0;
  }
}
.vc-sketch {
  box-shadow: none !important;
  width: auto !important;
}
</style>
