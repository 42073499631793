<template>
  <v-dialog
    v-model="showReleaseInfo"
    class="white"
  >
    <v-container
      class="white"
      fluid
    >
      <v-row>
        <v-col cols="10">
          <h1>{{ $i18n.t('aava.menu_items.release_info') }}</h1>
        </v-col>
        <v-col
          cols="2"
          class="text-right"
        >
          <v-btn
            icon
            @click="$store.state.showReleaseInfo = false"
          >
            <v-icon>fa-times</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-for="(releaseNote, index) in releaseNotes"
        :key="index"
      >
        <v-col
          cols="12"
        >
          <h2>{{ releaseNote.instance }} {{ formatDate(releaseNote.release_date) }}</h2>
        </v-col>
        <v-col
          v-for="(note, i2) in releaseNote.release_notes"
          :key="i2 + '_'"
          cols="12"
          v-html="note['text_' + locale]"
        />
      </v-row>
      <v-row>
        <v-col
          v-if="currentPage === pages"
          cols="12"
        >
          <b>{{ $i18n.t('aava.release_info.no_more') }}</b>
        </v-col>
        <v-col cols="12">
          <v-btn
            :loading="loading"
            :disabled="currentPage === pages"
            color="grey darken-2"
            text
            outlined
            @click="showNextReleaseNote"
          >
            <v-icon
              class="pr-3"
              small
            >
              fa-angle-double-down
            </v-icon>
            {{ $i18n.t('aava.release_info.more') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script lang="ts">
import date_time_helper from '@/methods/date_time_helper'
import api from '@/store/api/'
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import { AxiosResponse } from 'axios'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ReleaseInfo',

  data () {
    return {
      loading: true,
      currentPage: 0,
      pages: 0,
      releaseNotes: [],
    }
  },

  computed: {
    // Use commonStateVarsUsed
    ...mapFields(Object.keys(state)),
  },

  created () {
    this.showNextReleaseNote()
  },

  methods: {
    formatDate (date: string) {
      return date_time_helper.formatDate(date)
    },

    showNextReleaseNote () {
      this.loading = true
      if (this.currentPage > 0 && this.currentPage > this.pages) {
        this.loading = false
        return
      }
      this.currentPage++
      api.fetchReleaseInfo(this.currentPage).then((response: AxiosResponse) => {
        this.pages = (response.data.last_page) || 0
        if (response.data.release_notes && Object.keys(response.data.release_notes).length > 0) {
          Object.keys(response.data.release_notes).forEach(instance => {
            this.releaseNotes.push({
              instance,
              release_date: response.data.release_date,
              release_notes: response.data.release_notes[instance],
            })
          })
          this.loading = false
        } else {
          this.showNextReleaseNote()
        }
      })
    },
  },
}
</script>
