<template>
  <div
    v-if="parentItem"
    :class="'item-has-many-items ' +
      (edit && !isPreventWriteMember ? 'item-has-many-items-edit' : 'item-has-many-items-show') +
      (itemLayoutEditMode ? ' no-text-select' : '')
    "
    @mousedown="setHasManyColumnWithChangeProps"
  >
    <h1 v-if="debug">
      {{ unfinishedDfcCount }}
    </h1>
    <div class="has-many-items-container">
      <!-- Horizontal scroll helper -->

      <div
        v-if="hmScrollLeft > 0 && !layoutEditMode"
        :style="{
          position: 'absolute',
          left: '10px',
          top: (hmClientHeight / 2 - 12) + 'px',
          zIndex: '1',
        }"
      >
        <v-btn
          fab
          class="elevation-0"
          color="rgba(0, 0, 0, 0.1)"
          @click="scrollToLeft"
        >
          <v-icon>fa-angle-left</v-icon>
        </v-btn>
      </div>

      <div
        v-if="hmScrollWidth - hmScrollLeft > hmClientWidth && !layoutEditMode"
        :style="{
          position: 'absolute',
          right: '10px',
          top: (hmClientHeight / 2 - 12) + 'px',
          zIndex: '1',
        }"
      >
        <v-btn
          fab
          class="elevation-0"
          color="rgba(0, 0, 0, 0.1)"
          @click="scrollToRight"
        >
          <v-icon>fa-angle-right</v-icon>
        </v-btn>
      </div>

      <!-- Label -->

      <div class="has-many-header">
        <div class="has-many-label">
          {{ label }}
        </div>
      </div>

      <!-- Add new buttons -->

      <div
        class="has-many-top-buttons"
      >
        <v-btn
          v-for="(button, buttonIndex) in buttonsComputed"
          :key="buttonIndex"
          :rounded="theme.button.round"
          :loading="loadingNewFor === button.button"
          :disabled="!!loadingNewFor || isPreventWriteMember"
          :class="'mr-3 has-many-add-button ' + button.button + (layoutEditMode ? ' d-none' : '')"
          color="grey lighten-2"
          tabindex="-1"
          depressed
          x-small
          @click="buttonClick(button, $event)"
        >
          <v-icon
            x-small
            class="pr-2"
          >
            fa fa-{{ buttonIcons[button.button] }}
          </v-icon>
          {{ $i18n.t('aava.buttons.' + button.button) }}
        </v-btn>
        <v-btn
          v-if="layoutEditMode"
          :small="theme.button.small"
          :color="colors.buttonLight"
          class="mr-3 hm-col-selector"
          tabindex="-1"
          icon
          @click="showColumnsMenu"
        >
          <v-icon small>
            fa-th-list
          </v-icon>
        </v-btn>
        <v-btn
          v-if="layoutEditMode"
          :small="theme.button.small"
          :color="colors.buttonLight"
          tabindex="-1"
          class="hm-open-am"
          icon
          @click="openAttributeMetadata(field.metadata_id)"
        >
          <v-icon small>
            fa fa-edit
          </v-icon>
        </v-btn>
        <div
          v-else
          :class="'has-many-count link ' + (loadingItems || !!loadingNewFor ? ' loading-items' : '')"
          @click="openHasManyItemsInNewWindow"
        >
          <v-progress-circular
            v-if="loadingItems || !!loadingNewFor"
            :indeterminate="indeterminateProgress"
            :width="1"
            size="20"
            color="rgba(0, 0, 0, 0.22)"
            class="pa-0 ma-0"
          />
          <div
            v-else-if="parentItem.token || parentItem.id"
            class="count-number"
          >
            {{ count }}
          </div>
        </div>
      </div>

      <!--Has-many list-->

      <div
        ref="hmListContainer"
        class="has-many-items-inner-container"
      >
        <table>
          <HasManyItem
            v-for="(hasManyItem, index) in itemsComputed"
            :key="hasManyItem.id ? 'id_' + hasManyItem.id : index"
            ref="hasManyItem"
            :index="index"
            :parent-field="field"
            :parent-item="parentItem"
            :parent-resource="parentResource"
            :fields="fieldsComputed"
            :edit="editComputed"
            :item="hasManyItem"
            :resource="resource"
            :amc="amc"
            :layout-edit-mode="layoutEditMode"
            :show-item-picker-for="showItemPickerFor"
            :list-queries="listQueries"
            :set-has-many-item-in-list="setHasManyItemInList"
            :state-change-callback="initializeHasManyListComponent"
            :read-only="!hasManyItem['@editable'] || isPreventWriteMember"
            :autofocus="autofocusLastItemFirstTextField && index === itemsComputed.length - 1"
            :update-has-many-parent-item-on-defaults-for-change="updateHasManyParentItemOnDefaultsForChange"
            :parent-item-without-dynamic-fields="parentItemWithoutDynamicFields"
            :fixed-withs-by-item-designer="fixedWithsByItemDesigner"
            :show-item-modal="showItemModal"
            :modal="modal"
            @delete="() => {
              deleteHasManyItemWithWarning(index, items, parentItem, field)
            }"
            @closeModalAndReloadList="closeModalAndReloadList"
            @addNewNestedRow="buttonClick({ button: 'add_nested' })"
            @setFocusedRowIndex="setFocusedRowIndex"
            @moveFocusTo="moveFocusTo"
          />
        </table>
      </div>
      <div
        v-if="buttonsComputed.length > 0 && items.length > 0"
        class="pt-3"
      >
        <v-btn
          v-for="(button, buttonIndex) in buttonsComputed"
          :key="buttonIndex"
          :dark="false"
          :rounded="theme.button.round"
          :depressed="theme.button.depressed"
          :color="colors.buttonVeryLight"
          :loading="loadingNewFor === button.button"
          :disabled="loadingItems || !!loadingNewFor || isPreventWriteMember"
          x-small
          tabindex="-1"
          :class="'mx-3 has-many-add-button ' + button.button"
          @click="buttonClick(button, $event)"
        >
          <v-icon
            x-small
            class="pr-2"
          >
            fa fa-{{ buttonIcons[button.button] }}
          </v-icon>
          {{ $i18n.t('aava.buttons.' + button.button) }}
        </v-btn>
      </div>
      <div
        v-if="!loadingItems && totalCount > items.length"
        class="load-all-btn-container py-3"
      >
        <v-progress-circular
          v-if="loadingAllItems"
          indeterminate
          size="20"
          width="3"
          color="grey lighten-2"
        />
        <div
          v-else-if="!layoutEditMode"
          class="load-all-items"
          @click="loadAllHasManyItems"
        >
          + {{ totalCount - items.length }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import util from '../../utilities/sharedUtilities'
import methods from './../methods'
import listQueries from '@/store/_listQueries'
import api from './../../store/api'

import HasManyItem from './HasManyItem.vue'

import state from './../../store/state'
import { createHelpers } from 'vuex-map-fields'
import itemSaveQueueMethods from '@/methods/item/itemSaveQueueMethods'
import { HasManyExtendedField, HasManyListColumn, LP } from '@/types/LP.types'
import { HasManyBlockButton, Types } from '@/types/AppTypes'
import { AxiosResponse } from 'axios'
import { ItemsResponse } from '@/types/API.responses'
import { deleteHasManyItemById, deleteHasManyItemWithWarning } from '@/components/Item/HasMany.delete.methods'
import { addNewHasManyItemToStore, addNewNestedRow, addNewSelectItemToStore } from '@/components/Item/HasMany.add.methods'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

// Export functions that are also used in the simplified has-many-reference component

export function hasManyListLoadFilters (): Record<string, string> {
  // Set order, if not, set default order
  // Preferred would be 'created_at desc', but api does not like that,
  // for edit view have to send 'existing=temp', which prevents any sorting.
  // Therefore, has to be asc for now, until api is changed.
  const order = this.field.order || 'created_at asc'
  if (this.edit) {
    return {
      existing: 'temp',
      order,
    }
  }
  return {
    existing: 'true',
    order,
  }
}

export function getHasManyItems (objectClass: string, forClass: string, forId: any, forField: string, filters: any, queries: string[], parentHasManyParentField: any = null, parentHasManyParentItem: any = null): Promise<AxiosResponse<ItemsResponse>> {
  return api.fetchListItems(objectClass, filters, queries, { forClass, forId, forField, parentHasManyParentField, parentHasManyParentItem })
}

export function initializeHasManyListComponent ({ loadAll } = { loadAll: false }) {
  // For simple multiselect has-many inside has-many, to generate ~path to the main form item
  const parentHasManyParentField = this.edit ? this.$parent?.$parent?.parentField : null
  const parentHasManyParentItem = this.edit ? this.$parent?.$parent?.parentItem : null
  return new Promise(resolve => {
    if (!this.parentId) { // this.layoutEditMode || - Now also need example data for layout editor
      this.items = []
      this.loadingItems = false
      return resolve(null)
    }
    if (!loadAll) {
      this.loadingItems = true
    }
    // Get parent item attribute metadata (needed for item picker as targetObject, to filter out dynamic attributes)
    // Parent item has not retrieved it's am itself, as it gets all needed info from LP-items
    // Actually LP-items should also have this info, but easier to use attribute-metadata in various places
    // rather than passing LP-items to children and it's children
    this.$store.dispatch('getAttributeMetadata', this.parentItem['@class']).then(() => { // Will be used later
      // Get model attribute metadata first
      this.prepareColumnsInfoForHasManyList().then(() => {
        // Then complete queries for every field by its type
        listQueries.getQueriesForHasManyItems(this.hmListColumns, {
          amc: this.amc,
          edit: this.edit,
          locale: this.locale,
          view: 'hasManyItemEdit',
          parentField: this.field,
        }).then((listQueries: string[]) => {
          // listQueries.push('@editable')
          const filters = { ...this.hasManyListLoadFilters }
          filters.limit = this.layoutEditMode ? 1 : loadAll ? 10000 : 20
          // filters.edit = true
          this.listQueries = listQueries

          // Fetch has-many items
          this.getHasManyItems(this.resource, this.parentResource,
            this.parentId, this.field.name,
            filters,
            listQueries,
            parentHasManyParentField,
            parentHasManyParentItem,
          ).then((response: AxiosResponse<ItemsResponse>) => {
            this.reloadWhenItemSaveQueueIsEmpty = false
            this.$store.dispatch('globalErrorDisplay', { response, context: 'Has-many list loading for ' + this.resource })
            if (response.data) {
              this.items = response.data.items?.map(item => {
                // Set parent item as target object to be used for has-many row item picker query
                if (this.edit) { // For actual row edit only, otherwise in has-many show mode list edit reference dropdowns do not work
                  item.targetObject = this.parentItemWithoutDynamicFields
                }
                return item
              }) || []
              this.loadingItems = false
              this.loadingAllItems = false
              this.focusedRowIndex = this.items.length - 1
            }
            resolve(null)
          })
        })
      })
    })
  })
}

export function prepareColumnsInfoForHasManyList () {
  return new Promise(resolve => {
    // A) Use widget gorilla service to get columns info
    if (this.field.columns === 'query_columns') {
      api.getHasManyListQueryColumns(this.parentItem['@class'], this.parentItem.id, this.field.name)
        .then((response: AxiosResponse) => {
          this.$store.dispatch('globalErrorDisplay', { response, context: 'Get widget columns' }).then()
          const widgetColumns = response.data.columns || []
          // A.1) When items were picked by layout designer, use these columns
          // But still need to get information from gorilla service which columns to hide in which fields
          if (this.fieldsPickedByItemDesigner?.length > 0) {
            const hideOnByName = {}
            widgetColumns.filter(col => !!col.hide_on).forEach(col => {
              hideOnByName[col.name] = col.hide_on
            })
            this.hmListColumns = this.fieldsPickedByItemDesigner.map(col => {
              col.hide_on = hideOnByName[col.name]
              return col
            })
            resolve(true)
          } else {
            // A.2) Use widget columns as they are
            this.hmListColumns = widgetColumns
            resolve(true)
          }
        })
    } else if (this.fieldsPickedByItemDesigner?.length > 0) {
      // B) Use columns from layout designer
      this.hmListColumns = this.fieldsPickedByItemDesigner
      resolve(true)
    } else if (Array.isArray(this.field.columns)) {
      // C) API has returned array of columns to show, from attribute metadata, no widths are set
      this.hmListColumns = this.field.columns
      if (this.field.columns.length === 0) {
        this.hmListColumns = [{
          name: 'summary',
          type: 'string',
        }]
      }
      resolve(true)
    } else {
      this.hmListColumns = []
      resolve(true)
    }
  })
}

export function updateHasManyParentItemOnDefaultsForChange () {
  // Force update always, as currently @observed_members does not include has-many list field
  // Would be good if it had, then don't have to always update parent item when something changes in has-many row
  // that triggers DFC request
  this.updateItemOnDefaultsForChange(this.field, true)
}

// Add new has-many item picked form item picker
// Use can pick multiple items quickly - therefore queue is used
// NOTE - theoretically may have issues with ongoing DFCs
export function processNewItemFromQueue (item) {
  return new Promise(resolve => {
    if (this.referenceFieldNameToAutofillFromItemPicker) {
      // A) Popup of one configured reference field values was shown
      // User can select multiple form the popup, new nested items must be created,
      // then value set for the reference field
      const autoFillData = {}
      autoFillData[this.referenceFieldNameToAutofillFromItemPicker] = item
      this.addNewNestedRow({
        setFocusAfter: false, // We want to keep the popup open, user can select next one
        autoFillData,
      }).then(() => {
        resolve(null)
      })
    } else {
      // B) Popup of existing items was shown and user picked existing items from there, just need to create a link
      this.addNewHasManyItemToStore(item).then(() => {
        resolve(null)
      })
    }
  })
}

export default {
  name: 'HasManyItems',

  components: {
    HasManyItem,
  },

  props: {
    total: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: null,
    },
    field: {
      type: Object as () => LP.Item,
      required: true,
    },
    parentItem: {
      type: Object as () => Types.Item,
      required: true,
    },
    parentResource: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
      default: false,
    },
    layoutEditMode: {
      type: Boolean,
      default: false,
    },
    availableSpaceInFieldSet: {
      type: Number,
      default: 23,
    },
    showItemModal: {
      type: Function,
      default: () => {},
    },
    modal: {
      type: Boolean,
      default: false,
    },
    updateItemAfterSelectingNewHasManyChild: {
      type: Function,
      default: () => {},
    },
    updateItemOnDefaultsForChange: {
      type: Function,
      default: () => {},
    },
    unfinishedDfcCount: {
      type: Number,
      default: 0,
    },
  },

  data () {
    return {
      items: [] as Types.Item[],
      amc: {} as LP.AMC,
      hmListColumns: [] as HasManyListColumn[],
      loadingItems: true,
      loadingAllItems: false,
      loadingNewFor: null,
      listQueries: [],
      autofocusLastItemFirstTextField: false,
      focusedRowIndex: 0,
      addQueue: [],
      reloadWhenItemSaveQueueIsEmpty: false,
      fieldsPickedByItemDesigner: [],
      fixedWithsByItemDesigner: {},
      indeterminateProgress: false,
      layoutChangeTimer: null,
      // Temporary hold the field name to use when auto-filling reference field value
      // for new has-many item which was picked from the item picker (feature add_multi_by_reference)
      referenceFieldNameToAutofillFromItemPicker: '',
      intervalId: null, // To execute button click when DFCs are done
      debug: false,
      buttonIcons: {
        add: 'link',
        add_new: 'external-link-alt',
        add_nested: 'plus',
        add_multi_by_reference: 'square-check',
        add_modal: 'plus-square',
        copy_nested: 'copy',
      },

      // Node element props to decide if horizontal scroll helper should be shown
      // And which scroll direction buttons to enable
      hmClientWidth: 0,
      hmClientHeight: 0,
      hmScrollWidth: 0,
      hmScrollLeft: 0,
    }
  },

  watch: {
    parentId () {
      this.initializeHasManyListComponent().then(() => {
        this.$nextTick(() => {
          this.setRefElProps()
        })
      })
    },

    resizeTrigger () {
      this.setRefElProps()
    },

    layoutEditMode () {
      this.setRefElProps()
    },

    reloadWhenItemSaveQueueIsEmpty (val) {
      if (!val) {
        console.log('-s-e-t-:::::::', false)
        return
      }
      this.whenQueueIsEmpty().then(() => {
        // console.log('_______reloadWhenItemSaveQueueIsEmpty', val, this.field.name)
        this.initializeHasManyListComponent()
      })
    },

    layoutChangeTrigger () {
      if (!this.layoutEditMode) { return }
      clearTimeout(this.layoutChangeTimer)
      this.layoutChangeTimer = setTimeout(() => {
        this.saveHasManyLayout()
      }, 2000)
    },

    loadingItems (val) {
      if (val) {
        // When small form and loading very fast
        // No need to flash the loader in this case
        setTimeout(() => {
          if (this.loadingItems) {
            this.indeterminateProgress = true
          }
        }, 1000)
        return
      }
      this.indeterminateProgress = false
    },
  },

  created () {
    this.$store.dispatch('getAttributeMetadata', this.resource).then(amc => {
      this.amc = amc
      this.initHasManyLayoutProps()
      this.initializeHasManyListComponent().then(() => {
        this.$nextTick(() => {
          this.setRefElProps()
          this.$refs.hmListContainer?.addEventListener('scroll', this.handleHmListScroll)
        })
      })
    })
  },

  destroyed () {
    const el = this.$refs.hmListContainer
    if (!el) { return }
    el.removeEventListener('scroll', this.handleHmListScroll)
  },

  computed: {
    // TODO refactoring. When e2e tests are ready, start filtering which state arrays to use here. does not need all
    // Use commonStateVarsUsed
    ...mapFields(Object.keys(state)), // + resizeTrigger
    hasManyListLoadFilters,

    layoutChangeTrigger () {
      return JSON.stringify(this.fieldsPickedByItemDesigner) + '_' + JSON.stringify(this.fixedWithsByItemDesigner)
    },

    count () {
      const arrayCountFromParent = (Array.isArray(this.parentItem[this.field.name]) ? this.parentItem[this.field.name].length : 0)
      return this.edit
        ? this.items.length
        : this.parentItem[this.field.name]?.[0]?.count || // When items are not returned within the parent item request
          arrayCountFromParent // Items already present, ex. for 'files'
    },

    isPreventWriteMember () {
      if (!this.parentItem['@prevent_write_members']) {
        return
      }
      return this.parentItem['@prevent_write_members'].includes(this.field.name)
    },

    totalCount () {
      return this.parentItem[this.field.name] && this.parentItem[this.field.name][0] && this.parentItem[this.field.name][0].count || 0
    },

    editComputed () {
      return this.edit
      // return this.edit && !this.field.dynamic
    },

    buttonsComputed (): HasManyBlockButton[] {
      if (!this.field.buttons) { return [] }
      return this.field.buttons
        .filter((button: HasManyBlockButton) => {
          // In modal disabled nested option as can't open modal on top of another modal
          if (this.modal && button.button === 'add_modal') {
            return false
          }
          // Disable adding new child in new window when parent item is not saved yet (has no id)
          // Just does not work. Didn't work in old ui as well - logical error
          if (!this.parentItem.id && button.button === 'add_new') {
            return false
          }
          if (!this.edit && ['none', 'edit'].includes(button.hide_on)) {
            return true
          } else if (this.edit && ['none', 'show', null].includes(button.hide_on)) {
            return true
          }
          return false
        })
    },

    hmListColumnsByName () {
      // To add column data to field easily, like static list options
      const byName: Record<string, HasManyListColumn> = {}
      for (const column of this.hmListColumns) {
        byName[column.name] = column
      }
      return byName
    },

    itemsComputed () {
      if (this.layoutEditMode) {
        if (this.items?.[0]) { // First item for layout editor
          return [this.items[0]]
        }
        return [{}] // empty object for layout editor
      }
      return this.items
    },

    fieldsComputed (): HasManyExtendedField[] {
      const columns = this.fieldsPickedByItemDesigner?.length
      // Columns have been selected by using the item designer - the new way
        ? this.fieldsPickedByItemDesigner
      // Fields set by attribute metadata by object modelling in the old/default way
        : this.hmListColumns
      return this.completeFieldsWithAttributeMetadataInfo(this.filteredFieldsByHideOn(columns))
    },

    // Parent item without dynamic fields
    // used for has-many row reference item-picker query
    // back-end requires full parent item as payload
    parentItemWithoutDynamicFields () {
      const item = {}
      const amc = this.amByModel[util.objectClassUnderscoredName(this.parentItem['@class'])]
      Object.keys(this.parentItem).filter(fieldName => {
        return !amc?.[fieldName]?.dynamic
      }).forEach(fieldName => {
        item[fieldName] = this.parentItem[fieldName]
      })
      return item
    },

    resource () {
      return util.objectClassUnderscoredName(this.field.reference_class)
    },

    parentId () {
      return this.parentItem && (this.parentItem.token || this.parentItem.id) || null
    },
  },

  methods: {
    ...methods,
    ...itemSaveQueueMethods,
    deleteHasManyItemWithWarning,
    initializeHasManyListComponent,
    prepareColumnsInfoForHasManyList,
    addNewNestedRow,
    addNewHasManyItemToStore,
    getHasManyItems,
    addNewSelectItemToStore,
    deleteHasManyItemById,
    updateHasManyParentItemOnDefaultsForChange,
    processNewItemFromQueue,

    handleHmListScroll (e: Event) {
      this.setRefElProps(e.target)
    },

    setRefElProps (el: HTMLDivElement | null = null) {
      this.$nextTick(() => {
        el = el || this.$refs.hmListContainer
        if (!el) { return }

        // Set clientHeight only once, after initial items load
        if (this.hmClientHeight < 50) {
          this.hmClientHeight = el.clientHeight
        }
        this.hmClientWidth = el.clientWidth
        this.hmScrollWidth = el.scrollWidth
        this.hmScrollLeft = el.scrollLeft
      })
    },

    scrollToRight () {
      const el = this.$refs.hmListContainer
      if (!el) { return }
      el.scrollTo({
        left: this.hmScrollLeft + this.hmClientWidth,
        behavior: 'smooth',
      })
      setTimeout(() => {
        this.setRefElProps()
      }, 1000)
    },

    scrollToLeft () {
      const el = this.$refs.hmListContainer
      if (!el) { return }
      el.scrollTo({
        left: this.hmScrollLeft - this.hmClientWidth,
        behavior: 'smooth',
      })
      setTimeout(() => {
        this.setRefElProps()
      }, 1000)
    },

    requestReloadWhenItemSaveQueueIsEmpty () {
      this.reloadWhenItemSaveQueueIsEmpty = true
    },

    // Filter fields by hide_on for specific views (show/edit)
    // Gorilla service widget_columns gives information when some fields are hidden
    filteredFieldsByHideOn (fields) {
      return fields.slice().filter(field => {
        return !field.hide_on || this.layoutEditMode || !(field.hide_on === (this.edit ? 'edit' : 'show'))
      })
    },

    // Use fields from has_many_layout and improve with attribute metadata
    // So we have columns which are usable for columns selector
    completeFieldsWithAttributeMetadataInfo (fields: LP.Item[]): HasManyExtendedField[] {
      return [...fields].map((field: LP.Item) => {
        const fieldInfo = this.amc[field.name] || field as LP.Item
        fieldInfo.name = fieldInfo.name || fieldInfo.attribute_name
        fieldInfo.type = fieldInfo.type || fieldInfo.attribute_type
        fieldInfo.select_columns = field.select_columns
        fieldInfo.inputOptions = field.input_options || {}
        if (this.hmListColumnsByName[field.name] && this.hmListColumnsByName[field.name].items) {
          fieldInfo.items = this.hmListColumnsByName[field.name].items
        }
        // Use editable_in_list from field data got from JSON (has_many_layout)
        fieldInfo.editable_in_list = field.editable_in_list
        return fieldInfo
      })
    },

    showColumnsMenu () {
      if (this.showListColumnsSelector || !this.field.reference_class) {
        this.showListColumnsSelector = false
        return
      }
      const objectClass = util.objectClassUnderscoredName(this.field.reference_class)
      this.$store.dispatch('getAttributeMetadata', objectClass)
        .then(amc => {
          const fields = Object.keys(amc).map(key => ({
            ...amc[key],
            name: amc[key].attribute_name,
            type: amc[key].attribute_type,
          }))
          const itemsPreparedForColumnsSelector = [] as HasManyExtendedField[]
          fields.forEach((field: HasManyExtendedField, i) => {
            const indexInDefault = this.fieldsComputed.map(f => f.name).indexOf(field.name)
            // Get index in the default list, to sort by it
            field.visible = indexInDefault > -1
            // Set field props from has_many_layout as in has-many list we do not use layout profile items
            // and have this field to store layout settings in JSON
            if (indexInDefault > -1) {
              field.editable_in_list = !!this.fieldsComputed[indexInDefault].editable_in_list
              field.sort_order = indexInDefault
            } else {
              // Set default values when not in selected columns
              field.editable_in_list = false
              // Larger sort_order, so would go at the end when user adds
              field.sort_order = 1000 + i
            }
            itemsPreparedForColumnsSelector.push(field)
          })
          itemsPreparedForColumnsSelector.sort((a, b) => a.index < b.index ? -1 : 1)
          this.columnsSelectorProps = {
            layoutProfileItems: JSON.parse(JSON.stringify(itemsPreparedForColumnsSelector)),
            objectClass,
            previewItemId: this.items?.[0]?.id,
            forHasMany: true,
            callback: this.saveColumns,
          }
          this.showListColumnsSelector = true
        })
    },

    saveColumns ({ columns }) {
      this.fieldsPickedByItemDesigner = columns.filter(column => column.visible)
      this.initializeHasManyListComponent()
    },

    moveFocusTo ({ rowIndex, colIndex }) {
      const targetEl = this.$refs.hasManyItem?.[rowIndex]?.$refs?.hasManyItemField?.[colIndex]?.$el
      if (!targetEl) { return }
      const inputEl = targetEl.querySelector('input')
      if (inputEl) {
        inputEl.focus()
        this.setFocusedRowIndex(rowIndex)
      }
    },

    setFocusedRowIndex (index: number) {
      this.focusedRowIndex = index
    },

    openHasManyItemsInNewWindow () {
      window.open(window.location.origin + window.location.pathname +
          '#/' + this.resource + '/for/' + this.parentResource + '/' +
          this.parentItem.id + '/' + this.field.name, '_blank')
    },

    openAttributeMetadata (metadataId) {
      window.open(window.location.origin + window.location.pathname + '#/attribute_metadatas/' + metadataId + '/edit', '_blank')
    },

    setHasManyItemInList (index: number, item: Types.Item) {
      Vue.set(this.items, index, item)
    },

    loadAllHasManyItems () {
      this.loadingAllItems = true
      this.initializeHasManyListComponent({ loadAll: true })
    },

    // Handle user button click
    // Call handleNewHasManyItemButtonClick when DFCs are completed
    buttonClick (button: HasManyBlockButton, e: MouseEvent) {
      clearInterval(this.intervalId)

      // Show loading btn immediately, even if executing code later
      this.loadingNewFor = button.button

      // Add important delay here, as there is a delay on user input change to trigger DFC
      // Do not want to execute code below if there may be change event triggered
      setTimeout(() => {
        this.intervalId = setInterval(() => {
          this.handleNewHasManyItemButtonClick(button, e)
        }, 100)
      }, 200) // Text field has 1000 ms, but not likely to be issue here with text fields
    },

    // How to add new has-many item?
    handleNewHasManyItemButtonClick (button: HasManyBlockButton, e: MouseEvent | null = null) {
      // console.log('try...', this.unfinishedDfcCount)
      if (this.loadingItems) { // To be sure
        clearInterval(this.intervalId)
        return
      }

      // Cancel this run when DFCs in progress
      if (this.unfinishedDfcCount > 0) {
        return
      }

      clearInterval(this.intervalId)
      this.referenceFieldNameToAutofillFromItemPicker = ''
      switch (button.button) {
        case 'add_new':
          this.addNewHasManyItemFormAsMainItemForm()
          break
        case 'add_nested':
          this.addNewNestedRow({})
          break
        case 'copy_nested':
          this.copyNestedRowAsNew()
          break
        case 'add_modal':
          this.openModalViewFormForNewHasManyItem()
          break
        case 'add_multi_by_reference':
          // Set which field will be autofilled when user picks new item from the item picker
          this.referenceFieldNameToAutofillFromItemPicker = button.reference
          this.openPickerForMultiSelectByReferenceField(e)
          break
        default:
          this.openPickerForSingleSelectNewHasManyItem(e)
      }
    },

    // Add new in new window
    addNewHasManyItemFormAsMainItemForm () {
      if (!this.parentItem.id) { return }
      const path = '/' + this.resource + '/new/for/' +
          this.parentResource + '/' +
          this.parentItem.id + '/' + this.field.name
      this.$router.push({ path })
      // Also cancel the loader
      // Because when adding item in new window, user will lose existing form changes and has to confirm that
      // If user cancels route push, then is important to stop loader
      this.loadingNewFor = null
    },

    // Get last has-many row
    // Get focusable fields
    // Use focus_column_name if set, if not, then first (not read only)
    setFocusToNewlyAddedRow () {
      const focusColumnName = this.field.focus_column_name
      // For not yet discovered reason, when adding new has-many row into an empty list,
      // it takes 4 ticks (this.$nextTick) before <FormField> is created
      // Even though all the data is present to create that component at the same tick
      // When deleting first row and adding a new row, it's created instantly
      // Using setTimeout here for just visual preference
      setTimeout(() => {
        const lastRow = this.$refs.hasManyItem?.pop()
        const fieldComponents = lastRow?.$refs?.hasManyItemField || []
        const focusableFields = fieldComponents
          .filter(fieldComponent => {
            if (fieldComponent.readOnlyComputed) { // Can't focus when disabled
              return false
            }
            return focusColumnName === fieldComponent.field.name || // Set focus to specified column (focus_column_name)
                  (!focusColumnName && this.focusableFieldTypes.includes(fieldComponent.field?.type))
          })
        const firstFocusableInput = focusableFields?.[0]?.$children?.[0]?.$refs?.inputRef
        if (!firstFocusableInput) { return }
        firstFocusableInput.focus()
      }, 5)
    },

    // Copy existing, where the focus is
    copyNestedRowAsNew () {
      if (this.unfinishedDfcCount > 0) {
        this.loadingNewFor = null
        return this.showUnfinishedDfcRequestsError()
      }
      // Get recently focused has-many item ref
      const childRef = this.$children.filter(hasMany => {
        return hasMany.index === this.focusedRowIndex && hasMany.getHasManyItemDfcChanges
      })?.[0]
      if (!childRef) {
        this.loadingNewFor = null
        return
      }
      // Do a dummy update with defaults-for-change for focused has-many item, for field 'updated_at'
      // This will update that row data server side, which is then used for copying
      // and creating new row with same data
      childRef.getHasManyItemDfcChanges({ name: 'updated_at' }).then(() => {
        this.addNewNestedRow({ copyFromIdOrToken: childRef.item.token || childRef.item.id })
      })
    },

    // Add in modal
    openModalViewFormForNewHasManyItem () {
      if (!this.parentItem.id) { return }
      this.showItemModal({
        show: true,
        id: 'new',
        edit: true,
        targetResource: this.parentResource,
        targetId: this.parentItem.id,
        targetField: this.field.name,
        resource: this.resource,
        showLoader: false,
        saveCallback: this.closeModalAndReloadList,
        closeCallback: this.closeModalRow,
      })
      this.loadingNewFor = null
    },

    closeModalAndReloadList () {
      this.closeModalRow()
      this.initializeHasManyListComponent()
    },

    closeModalRow () {
      this.showItemModal({
        show: false,
      })
    },

    // Add one from picker
    openPickerForSingleSelectNewHasManyItem (e) {
      this.hasManyComponentForItemPicker = this
      this.$store.state.itemPickerSearchTerm = ''
      this.showItemPickerFor(e, {
        objectClass: util.objectClassUnderscoredName(this.field.reference_class),
        forObjectClass: util.objectClassUnderscoredName(this.parentItem['@class']),
        forItem: this.parentItem,
        selectColumns: ['summary'],
        selectCallback: this.addNewSelectItemToStore,
        unselectCallback: this.deleteHasManyItemById,
        forField: this.field,
        forFieldName: this.field.name,
        forHasMany: true,
        multiSelect: this.field.multi_select === true || this.field.multi_select === 'true',
      })
      this.loadingNewFor = null
    },

    // Add multiple from picker
    // Create a dropdown of one reference field possible values
    openPickerForMultiSelectByReferenceField (e) {
      this.hasManyComponentForItemPicker = this
      this.$store.state.itemPickerSearchTerm = ''
      // Instead of picking has-many item from the same list which model has-many items are
      // use a list from specified reference field model
      const objectClass = this.amc[this.referenceFieldNameToAutofillFromItemPicker].reference_class || this.amc[this.referenceFieldNameToAutofillFromItemPicker].association_types[0]
      this.showItemPickerFor(e, {
        objectClass,
        selectColumns: ['summary'],
        selectCallback: this.addNewSelectItemToStore,
        unselectCallback: () => {},
        forField: this.field,
        forFieldName: this.field.name,
        forHasMany: true,
        multiSelect: true,
        hideToggleAll: true,
        disableDeselect: true,
      })
      this.loadingNewFor = null
    },
  },
}
</script>

<style lang="scss">
.item-has-many-items-show {
  .has-many-items-container {
    fieldset {
      border: 1.5px solid black;
      background: white;
    }
  }
}
.item-has-many-items {
  border-radius: 8px;
  padding: 10px 0 10px 0;
  // Fix last row bottom margin (-12)
  // Potentially being fixed in future Vuetify version
  .row:last-child {
    margin-bottom: 0;
  }
  .has-many-items-container {
    display: grid; /* Very important. To force children not causing width over 100% when content does not fit horizontally */
    position: relative;
    border-color: rgba(0, 0, 0, 0.22);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding: 14px 4px 8px 4px;
    min-height: 40px;
    .has-many-items-inner-container {
      background: inherit;
      overflow-x: auto; /* Important ! */
      overflow-y: hidden; /* Important ! */
      table {
        /* Important ! For some reason without it 100% causes horizontal scrollbar to appear,
         which can be hidden with display: block, but then table does not take 100%. */
        width: calc(100% - 2px);
        background: inherit;
        border-collapse: collapse;
        tr:first-child {
          td {
            padding-top: 4px !important;
          }
        }
        tr {
          td {
            margin: 0;
            padding: 0;
            background: inherit;
            >div {
              background: inherit;
            }
            .has-tooltip {
              background: inherit;
              >div {
                background: inherit;
              }
            }
          }
        }
        tr:not(:first-child) {
          .item-show-label {
            display: none !important;
          }
        }
      }
      .item-show-field {
        padding: 8px 10px 3px 10px;
        min-height: 34px;
        /* display: flex;
        Breaks address show view (Nurminen /bookings/4858) in has-many.
        Was added with Aava-Vue-667, but can't replicate this issue when removed. */
        /* min-width: 100px; */
        .item-show-value {
          display: block; /* has to be block so reference field is single line */
          /* display: contents; */
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .has-many-top-buttons {
      position: absolute;
      right: 15px;
      top: -12px;
      padding: 0 5px;
      .hm-open-am, .hm-col-selector {
        background-color: white !important;
      }
      .v-btn--icon.v-size--small {
        /* height: 24px;
        width: 24px; */
      }
      .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
        background-color: #eee !important;
      }
    }
    .has-many-header {
      position: absolute;
      top: -10px;
      left: 8px;
      display: flex;
      padding: 0 10px 0 4px;
      .has-many-label {
        color: var(--item-form-label-color);
        font-size: 14px;
        font-weight: 500;
      }
    }
    .has-many-count {
      position: relative;
      top: 1px;
      float: right;
      font-size: 10px;
      vertical-align: center;
      background: var(--show-view-input-grey-bg);
      border-radius: 50px;
      padding: 0 5px;
      min-width: 20px;
      min-height: 20px;
      margin-left: 0;
      margin-right: 0;
      text-align: center;
      font-weight: 600;
      letter-spacing: normal !important;
      color: var(--item-form-label-color);
      border: 1px solid rgba(0, 0, 0, 0.22);
      .count-number {
        height: 18px;
        line-height: 18px;
      }
      .v-progress-circular {
        position: absolute !important;
        top: -1px;
        left: -1px;
      }
    }
    .has-many-count.loading-items {
      border: 1px solid #f6f6f6;
    }
  }
  .v-btn--fab.v-size--small {
    height: 30px !important;
    width: 30px !important;
  }
  .v-input__slot {
    min-height: 34px !important;
  }
  .v-text-field--outlined.v-input--dense .v-label {
    top: 7px !important;
  }
  .v-input__append-inner {
    margin-top: 5px !important;
  }
  textarea {
    overflow: hidden;
  }
  .has-many-add-button {
    margin-top: 1px;
    color: #616161 !important;
    .v-progress-circular {
      height: 14px !important; /* Vuetify fix, loader should not be bigger than the button */
    }
  }
  .load-all-btn-container {
    text-align: -webkit-center;
    .load-all-items {
      cursor: pointer;
      width: 80px;
      background: #ebebeb;
      border-radius: 50px;
      padding: 5px 10px;
      margin-left: 15px;
      line-height: 20px;
    }
  }
}
</style>

<style lang="scss">
.has-many-items-container {
  .has-many-items-inner-container {
    overflow: auto;
    scrollbar-color: #eee #eee; // Firefox
  }
  .has-many-items-inner-container::-webkit-scrollbar {
    height: 10px;
  }

  .has-many-items-inner-container::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #eee;
  }
}
.has-many-items-container:hover {
  .has-many-items-inner-container {
    scrollbar-color: #ddd #eee; // Firefox
  }
  .has-many-items-inner-container::-webkit-scrollbar-thumb {
    background: #ddd;
  }
}
</style>
