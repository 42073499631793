<template>
  <div
    :class="'reference-field-container ' + (showMenuButton ? 'with-menu-button' : '')"
  >
    <v-text-field
      ref="inputRef"
      v-model="inputSearchString"
      :label="label"
      :placeholder="isHasManyField ? label : ''"
      :data-field-name="field.name"
      :class="(field.mandatory ? (value && value.id ? ' required-filled ' : ' required-missing ') : '') +
        (itemPickerIsOpen ? ' menu-open' : '')"
      :single-line="isHasManyFieldAndNotFirstRow"
      :persistent-placeholder="true"
      :disabled="readOnly"
      :autofocus="autofocus"
      color="orange"
      autocomplete="off"
      outlined
      hide-details
      dense
      @focus="focus"
      @blur="blur"
      @keydown="keydownHandler"
    >
      <template v-slot:append>
        <v-icon
          tabindex="-1"
          :disabled="readOnly"
          class="toggle-dropdown"
          @click="toggleItemPicker"
        >
          fa-caret-down
        </v-icon>
        <div
          v-if="!modal"
          class="reference-field-menu"
        >
          <v-menu
            v-if="!readOnly"
            :z-index="160"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                tabindex="-1"
                v-on="on"
                @click.stop=""
              >
                fa fa-ellipsis-v
              </v-icon>
            </template>
            <div class="reference-field-menu-popup">
              <div
                :class="(!inputValue ? 'menu-el-disabled aa' : 'ss') + ' popup-menu-el'"
                @click="clearValue"
              >
                <a href="javascript:">
                  <span class="fa fa-times" />
                  {{ $i18n.t('aava.actions.clear') }}
                </a>
              </div>
              <template v-if="!isHasManyField && view !== 'list' && !editInList">
                <div
                  v-for="(addNewOption, index) in addNewOptions"
                  :key="index"
                  class="popup-menu-el"
                  @click="openNewReferenceItemModal(addNewOption.referenceClass)"
                >
                  <a href="javascript:;">
                    <span class="fa fa-plus" />
                    {{ $i18n.t('aava.buttons.add_new') }} {{ addNewOption.title }}
                  </a>
                </div>
              </template>
              <div
                v-if="view !== 'list' && !editInList"
                :class="(!value || !value.id ? 'menu-el-disabled aa' : 'ss') + ' popup-menu-el'"
                @click="showReferenceItem"
              >
                <a href="javascript:;">
                  <span class="fa fa-eye" />
                  {{ $i18n.t('aava.actions.show') }}
                </a>
              </div>
              <div
                v-if="!isHasManyField && view !== 'list' && !editInList"
                :class="(!value || !value.id ? 'menu-el-disabled aa' : 'ss') + ' popup-menu-el'"
                @click="openReferenceItemModal(true)"
              >
                <a href="javascript:">
                  <span class="fa fa-edit" />
                  {{ $i18n.t('aava.actions.edit') }}
                </a>
              </div>
            </div>
          </v-menu>
        </div>
      </template>
    </v-text-field>
  </div>
</template>

<script lang="ts">
import util from '../../utilities/sharedUtilities'
import itemMethods from '@/methods/item/itemMethods'
import itemFieldMethods from '@/methods/item/itemFieldMethods'
import listItemCellMethods from '@/methods/listItem/listItemCellMethods'
import { AxiosResponse } from 'axios'

export default {
  name: 'ReferenceField',

  props: {
    // TODO - props builder?
    value: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: null,
    },
    view: {
      type: String,
      default: 'item',
    },
    field: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
    resource: {
      type: String,
      default: null,
    },
    showItemPickerFor: {
      type: Function,
      default: () => {},
    },
    editInList: {
      type: Boolean,
      default: false,
    },
    layoutEditMode: {
      type: Boolean,
      default: false,
    },
    openPickerOnFocus: {
      type: Boolean,
      default: false,
    },
    showItemFieldMenu: {
      type: Function,
      default: () => {},
    },
    hasManyParentField: {
      type: Object,
      default: () => {},
    },
    isHasManyFieldAndNotFirstRow: {
      type: Boolean,
      default: false,
    },
    showItemModal: {
      type: Function,
      default: () => {},
    },
    modal: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      inputSearchString: '',
      itemPickerIsOpen: false,
      clearedFromMenu: false,
      autoSelectFirstWhenOptionsLoaded: false,
    }
  },

  computed: {
    showMenuButton () {
      return !this.modal && !this.readOnly // && !this.isHasManyField
    },

    pickerAutoOpenOff () {
      return !!this.field.amc_options?.picker_auto_open_off || !!this.field.picker_auto_open_off
    },

    isHasManyField () {
      return !!(this.hasManyParentField && this.hasManyParentField.name)
    },

    addNewOptions () {
      const options: { referenceClass: string, title?: string }[] = []
      if (this.field.reference_class === 'Any') {
        (this.field.association_classes || []).forEach(referenceClass => {
          options.push({
            referenceClass,
            title: this.$i18n.t(util.objectClassUnderscoredName(referenceClass) + '.show.title'),
          })
        })
      } else {
        options.push({ referenceClass: this.field.reference_class })
      }
      return options
    },

    inputValue: {
      set (value) {
        if (typeof value === 'object') {
          this.$emit('input', value)
        } else {
          // Clear value if search term cleared
          if (!value) {
            this.$emit('input', null)
          }
          this.$store.state.itemPickerSearchTerm = value
          if (!this.clearedFromMenu && !this.pickerAutoOpenOff) {
            this.showItemPicker()
          }
        }
      },
      get () {
        return (this.value?.[this.summaryField] || this.value?.id) || ''
      },
    },

    summaryField () {
      return this.field?.reference_attribute || 'summary'
    },
  },

  watch: {
    // On user input as search string, update inputValue
    // which triggers showing the item picker
    inputSearchString (value) {
      if (this.inputValue === value) { return }
      this.inputValue = value
    },

    // Show selected option text value for the user,
    // however the selection was actually made (by resetting, user selection, dfc-request etc)
    inputValue (value) {
      if (this.inputSearchString === value) { return }
      this.inputSearchString = value
    },
  },

  created () {
    this.inputSearchString = this.inputValue
  },

  methods: {
    ...itemMethods,
    ...itemFieldMethods,
    ...listItemCellMethods,

    clearValue () {
      this.clearedFromMenu = true // To avoid item picker popup while changing input value
      this.inputValue = ''
      this.$emit('changeListener', 0)
      this.$emit('selectedInList')
      setTimeout(() => {
        this.clearedFromMenu = false
      }, 30)
    },

    openNewReferenceItemModal (referenceClass) {
      if (this.readOnly) { return }
      this.showItemModal({
        show: true,
        id: 'new',
        edit: true,
        resource: util.objectClassUnderscoredName(referenceClass),
        showLoader: false,
        saveCallback: this.changeModalReferenceItemCompleted,
        closeCallback: this.closeReferenceModal,
      })
    },

    openReferenceItemModal (edit) {
      if (!this.value?.id) { return }
      this.showItemModal({
        show: true,
        id: this.value.id,
        edit,
        resource: util.objectClassUnderscoredName(this.value['@class']),
        showLoader: false,
        saveCallback: this.changeModalReferenceItemCompleted,
        closeCallback: this.closeReferenceModal,
      })
    },

    // Control how reference item is opened in case of
    // a - regular edit form field
    // b - has-many item on edit mode, in split mode
    // c - has many item on edit mode, without split mode
    showReferenceItem (event) {
      if (!this.value?.id) { return }
      if (!this.isHasManyField) {
        // Open reference item on modal
        this.openReferenceItemModal(false)
      } else {
        if (this.$store.state.splitMode) {
          // In case of has-many item, open in split mode or new tab
          const payload = {
            className: this.value['@class'],
            // parentId: this.hasManyParentField?.id,
            event,
            view: 'item',
          }
          this.openReferenceLink(this.value.id, payload)
        } else {
          // Open in new tab, easiest to support
          // Can't use same modal as for the main item, not just available easily enough
          window.open('#' + util.objectClassUnderscoredName(this.value['@class']) + '/' + this.value.id)
        }
      }
    },

    changeModalReferenceItemCompleted (item) {
      if (!item?.['@class']) { return }
      this.closeReferenceModal()
      // TODO - test this case if should use summaryField
      this.getItemInfo(util.objectClassUnderscoredName(item['@class']), item.id, [this.summaryField])
        .then((response: AxiosResponse) => {
          this.selectOption(response.data.item)
        })
    },

    closeReferenceModal () {
      this.showItemModal({
        show: false,
      })
    },

    keydownHandler (e) {
      // For date field show only with down key
      if (e.key === 'ArrowDown') {
        this.showItemPicker()
      }
      // On special cases pass keypress to the <FormField> component
      if ([
        'Escape', // Restore the value in list view
      ].includes(e.key)) {
        this.$emit('keyPress', e)
      }
      // Special behaviour for picker_auto_open_off
      // Tab opens item picker, if only one result, auto-select and move to next input
      if (e.key === 'Tab' && this.pickerAutoOpenOff && !this.$store.state.showItemPicker) {
        this.showItemPicker()
        this.$store.state.itemPickerAutoSelectFirstWhenOptionsLoaded = true
        e.stopPropagation()
        e.preventDefault()
      } else if (e.key === 'Tab' && this.pickerAutoOpenOff) {
        this.$store.state.itemPickerAutoSelectFirstWhenOptionsLoaded = true
      } else if (e.key === 'Tab') {
        // Pass Tab for the parent - used in list view to move to the next editable cell
        this.$emit('keyPress', e)
      }
    },

    focus (e) {
      if (this.readOnly) { return }
      this.autoSelectTextOnFocus(e)
      if (this.openPickerOnFocus) {
        this.showItemPicker()
      }
    },

    blur (e) {
      // After blur let's set input search string to reflect if and which value has been set
      // Case - user selects one option, then types new search string and leaves the input,
      // this will still keep the old selection, which should be shown for the user
      setTimeout(() => {
        this.inputSearchString = this.value?.id ? this.inputValue : ''
      }, 300)
    },

    toggleItemPicker (e) {
      if (this.itemPickerIsOpen) {
        this.$store.dispatch('closeItemPicker')
        this.itemPickerIsOpen = false
      } else {
        this.showItemPicker(e)
      }
    },

    showItemPicker (e) {
      this.itemPickerIsOpen = true
      let selectColumns = this.field.select_columns || []

      // Use AMC select_columns?
      if (this.isHasManyField && this.field?.amc_options?.select_columns) {
        // When already have select columns in field amc_options, use these
        // Ex. Janla > sales_orders > edit > Myyntitilausrivit > product item picker
        // In case of has-many > reference field > item picker, amc_options?.select_columns can be used
        // Otherwise (on item main form, not in has-many) use itemPickerProps.selectColumns
        selectColumns = this.field?.amc_options?.select_columns
      }

      this.showItemPickerFor(e, {
        objectClass: util.objectClassUnderscoredName(this.field.reference_class),
        // TODO comment
        forObjectClass: util.objectClassUnderscoredName(this.hasManyParentField?.reference_class) || this.resource,
        forField: this.field,
        forFieldName: this.field.name,
        forId: (this.item && (this.item.token || this.item.id)) || null,
        selectColumns,
        // For country select in address form for ~path part in url
        // Normally forObjectClass is fine but country api call expects different value
        pathField: this.field.pathField || (this.hasManyParentField?.name),
        selectCallback: this.selectOption,
        closeCallback: () => { this.itemPickerIsOpen = false },
        attachEl: this.$refs.inputRef.$el,
        useAttachElMinWidth: true,
        forItem: this.item,
      })
    },

    selectOption (option) {
      this.itemPickerIsOpen = false
      this.inputValue = {
        '@class': option['@class'],
        id: option.id,
        [this.summaryField]: option[this.summaryField],
      }
      this.$emit('changeListener', 0)
      this.$emit('selectedInList')
      // Set focus back to the input, when selected with the mouse
      this.$refs.inputRef.focus()
    },
  }

}
</script>

<style lang="scss">
.reference-field-container, .form-static-list-field {
  position: relative;
  .reference-field-menu {
    position: absolute;
    right: 0;
    top: 0;
    .v-icon {
      font-size: 18px;
      color: #aaa;
      padding: 12px 16px 8px 5px;
    }
  }
  .fa-caret-down {
    font-size: 16px !important;
    color: #aaa !important;
    padding: -3px 10px 5px 10px;
    margin-right: 7px !important;
    margin-top: 4px;
  }
}
.has-many-item {
  .reference-field-container {
    .reference-field-menu {
      top: -4px;
    }
  }
}
/* In case of menu shown, align arrow to the right */
.with-menu-button {
  .fa-caret-down {
    margin-right: 21px !important;
  }
}
.reference-field-menu-popup {
  padding: 10px 0;
  background: white;
}
.menu-open {
  .fa-caret-down {
    transform: rotate(180deg);
  }
}
.v-input {
  font-size: 14px !important;
}
</style>
