import { render, staticRenderFns } from "./ReleaseInfo.vue?vue&type=template&id=767acfbb"
import script from "./ReleaseInfo.vue?vue&type=script&lang=ts"
export * from "./ReleaseInfo.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports