import util from '../../utilities/sharedUtilities'
import dateTimeHelper from '@/methods/date_time_helper'
import { ItemPicker } from '@/types/ItemPicker'

export function showItemPickerFor (e: KeyboardEvent | MouseEvent, props: ItemPicker.Props) {
  const newProps: ItemPicker.Props = {} as ItemPicker.Props
  Object.keys(props).forEach(key => {
    newProps[key] = props[key]
  })
  // Skip if already shown with same props
  const propsChanged = ['objectClass', 'forObjectClass', 'selectColumns', 'pathField', 'forId', 'forFieldName'].filter(name => {
    return JSON.stringify(this.$store.state.itemPickerProps?.[name]) !== JSON.stringify(newProps?.[name])
  }).length
  if (!propsChanged) {
    return
  }
  this.$store.dispatch('resetItemPickerData', this.$store.state.itemPickerProps?.forFieldName).then(() => {
    this.$store.state.itemPickerSelectCallback = props.selectCallback
    this.$store.state.itemPickerUnselectCallback = props.unselectCallback
    this.$store.state.itemPickerProps = newProps
    // Use nextTick to trigger created() for component
    this.$nextTick(() => {
      this.$store.state.showItemPicker = true
      this.$store.state.showItemPickerTimestamp = dateTimeHelper.getTimestamp()
      this.$nextTick(() => {
        util.positionPopupMenu(e, 'vue-item-picker', 0, 0, {
          attachEl: props.attachEl,
          useAttachElMinWidth: props.useAttachElMinWidth,
        })
      })
    })
  })
}

export default {
  showItemPickerFor,
}
