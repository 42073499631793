<template>
  <v-menu
    :z-index="210"
    content-class="white"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        :dark="!compact && !isMobileBottomToolbar"
        :icon="compact"
        :block="isMobileBottomToolbar"
        :class="isMobileBottomToolbar ? 'mobile-toolbar-btn grey--text text--darken-1' : 'mx-1'"
        :color="isMobileBottomToolbar ? 'grey lighten-3' : 'grey'"
        depressed
        small
        v-on="on"
        @click="getItemHistory"
      >
        <v-icon
          :class="isMobileBottomToolbar ? 'pr-2' : compact ? '' : 'mr-2'"
          small
        >
          fa-clock
        </v-icon>
        {{ compact ? '' : $i18n.t('aava.general.object_history') }}
      </v-btn>
    </template>
    <v-container
      :class="$vuetify.breakpoint.xsOnly ? 'mobile' : 'desktop'"
    >
      <v-row>
        <v-col cols="4">
          <v-btn
            v-if="auditTrailEnabled"
            :small="theme.button.small"
            :dark="theme.button.dark"
            :rounded="theme.button.round"
            :depressed="theme.button.depressed"
            :color="colors.buttonNeutral"
            class="ma-0"
            @click="openAuditTrail"
          >
            {{ $i18n.t('aava.general.audit_trail') }}
          </v-btn>
        </v-col>
        <v-col
          cols="8"
          class="text-no-wrap text-right"
        >
          {{ $i18n.t('aava.attributes.created_by') }} {{ item.created_by && item.created_by.name }} {{ dateTimeHelper.formatDateAndTimeWithSeconds(item.created_at) }}<br>
          {{ $i18n.t('aava.attributes.updated_by') }} {{ item.updated_by && item.updated_by.name }} {{ dateTimeHelper.formatDateAndTimeWithSeconds(item.updated_at) }}<br>
          {{ $i18n.t('aava.attributes.owner') }} {{ item.owner && item.owner.name }}
        </v-col>
      </v-row>
      <v-row v-if="historyItems.length > 0">
        <v-col>
          <v-container class="pa-0">
            <v-row dense>
              <v-col cols="12">
                <b>
                  {{ $i18n.t('aava.general.object_history') }}
                </b>
              </v-col>
              <v-col
                v-for="(historyItem, index) in historyItems"
                :key="'h' + index"
                cols="12"
              >
                {{ historyItem.event_person }}
                {{ historyItem.event_translated }}
                {{ dateTimeHelper.formatDateAndTimeWithSeconds(historyItem.event_time) }}
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col v-if="loading">
          <v-progress-linear
            indeterminate
            height="3"
            color="grey lighten-2"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-menu>
</template>

<script lang="ts">
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import api from '@/store/api'
import sharedUtilities from '@/utilities/sharedUtilities'
import dateTimeHelper from '@/methods/date_time_helper'
import { AxiosResponse } from 'axios'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ItemHistoryBtn',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
    resource: {
      type: String,
      default: '',
    },
    isMobileBottomToolbar: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      dateTimeHelper,
      historyItems: [],
      auditTrailEnabled: false,
      loading: false,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    compact () {
      return !this.isMobileBottomToolbar
    },
  },

  methods: {
    getItemHistory () {
      this.loading = true
      this.historyItems = []
      api.fetchItemHistory(sharedUtilities.objectClassSingularName(this.resource), this.item.id).then((response: AxiosResponse) => {
        this.loading = false
        this.historyItems = response.data.items
        this.auditTrailEnabled = !!response.data.audit_trail
      })
    },

    openAuditTrail () {
      window.open('/gorilla/audit_trail/' + this.resource + '/' + this.item.id + '?locale=' + this.locale, '_blank')
    },
  },
}
</script>

<style scoped>
.mobile {
  width: 100%;
  min-width: 300px;
  min-height: 400px;
  max-height: 400px;
  overflow: auto;
}
.desktop {
  width: 400px;
}
</style>
