var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.parentItem)?_c('div',{class:'form-has-many-multiselect ' +
    // (edit && !isPreventWriteMember ? 'item-has-many-items-edit' : 'item-has-many-items-show') +
    (_vm.itemLayoutEditMode ? ' no-text-select' : '')},[_c('div',{staticClass:"item-show-field"},[_c('div',{staticClass:"item-show-label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{ref:"contentRef",staticClass:"item-show-value px-2 pt-1",style:({
        position: 'relative',
        paddingRight: '20px !important',
      })},[(_vm.contentIsOverflowing && !_vm.measuringOverflow)?_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-n2 mb-n1 mr-1",style:({
              borderRadius: '20px',
              background: '#ddd',
              width: '18px',
              height: '18px',
            }),attrs:{"text":"","fab":"","color":"grey","tabindex":"-1","x-small":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.items.length)+" ")])]}}],null,false,2032808007)},[_c('v-container',{staticClass:"item-container-menu popup-menu elevation-5 form-has-many-multiselect",style:({ position: 'relative'})},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.items),function(item,index){return _c('v-chip',{key:item.id,staticClass:"mr-1 pl-2 pr-2 my-1",attrs:{"close":_vm.edit,"color":_vm.edit ? 'grey lighten-3' : 'grey lighten-2',"to":_vm.edit ? '' : _vm.generateReferenceLinkInSplitMode({
                  className: _vm.resource,
                  id: item.id,
                }),"small":"","label":"","text-color":"black"},on:{"click:close":() => {
                  _vm.removeFromMultiselectInput(item)
                }}},[_vm._v(" "+_vm._s(_vm.itemLabel(item))+" ")])}),1)],1)],1)],1):_vm._e(),_vm._l((_vm.items),function(item,index){return _c('v-chip',{key:item.id,staticClass:"mr-1 pl-2 pr-2 mb-1",attrs:{"close":_vm.edit,"color":_vm.edit ? 'grey lighten-3' : 'grey lighten-2',"to":_vm.edit ? '' : _vm.generateReferenceLinkInSplitMode({
          className: _vm.resource,
          id: item.id,
        }),"small":"","label":"","text-color":"black"},on:{"click:close":() => {
          _vm.removeFromMultiselectInput(item)
        }}},[_vm._v(" "+_vm._s(_vm.itemLabel(item))+" ")])}),_c('div',{staticClass:"fa-plus-container"},[(_vm.edit)?_c('v-btn',{staticClass:"mt-n1 mb-n1",attrs:{"text":"","fab":"","color":"grey","tabindex":"-1","x-small":""},on:{"click":_vm.openPicker}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa-plus ")])],1):_vm._e()],1)],2)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }