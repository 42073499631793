<template>
  <div>
    <v-container
      ref="filesContainer"
      :class="[
        'form-files-list',
        'pa-0',
        !files.length && 'empty-list',
        showCompactOneFile && 'compact-one-file',
        isDragOver && 'drag-over',
      ]"
      fluid
      @drop.prevent="readFiles($event)"
      @dragenter.prevent="handleDragEnter"
      @dragleave.prevent="handleDragLeave"
      @dragover.prevent=""
    >
      <div class="item-show-field show-field-files">
        <div
          v-if="fieldLabel"
          :class="'item-show-label ' + (edit ? 'item-edit-label' : '')"
        >
          <span>{{ fieldLabel }}</span>
        </div>
        <div class="item-show-value files-type">
          <v-btn
            v-if="!readOnly"
            :disabled="uploading"
            :loading="uploading"
            :block="!showCompactOneFile"
            :text="!showCompactOneFile"
            :fab="showCompactOneFile"
            :color="showCompactOneFile ? 'white' : 'grey'"
            :class="'add-btn ' + (showCompactOneFile ? 'elevation-0' : '')"
            tabindex="-1"
            x-small
            @click="selectFile"
          >
            <v-icon
              :small="!showCompactOneFile"
              :x-small="showCompactOneFile"
            >
              fa-plus
            </v-icon>
          </v-btn>
          <v-menu
            v-if="showCompactOneFile && files.length > 1"
            :z-index="210"
            content-class="white"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="has-many-count-round mt-1"
                style="background: #ddd;"
                v-on="on"
              >
                {{ files.length }}
              </div>
            </template>
            <v-container>
              <v-row
                v-for="(file, index) in files"
                :key="file.id || index"
                no-gutters
              >
                <v-col>
                  <FilesFile
                    :edit="edit"
                    :edit-in-list="editInList"
                    :file="file"
                    class="my-1"
                    :read-only="readOnly"
                    :show-compact-one-file="showCompactOneFile"
                    @delete="() => { deleteFile(index) }"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-menu>
          <div
            v-else
            class="pa-0 mr-6"
          >
            <FilesFile
              v-for="(file, index) in files"
              :key="file.id || index"
              :edit="edit"
              :edit-in-list="editInList"
              :file="file"
              :read-only="readOnly"
              :show-compact-one-file="showCompactOneFile"
              @delete="() => { deleteFile(index) }"
            />
          </div>
        </div>
      </div>
    </v-container>
    <input
      :id="fileInputId"
      type="file"
      style="position: fixed; top: -2000px"
      tabindex="-1"
      multiple
      @change="readFiles($event)"
    >
  </div>
</template>

<script lang="ts">
import FilesFile from '@/components/Form/FilesFile.vue'
import state from './../../store/state'
import { createHelpers } from 'vuex-map-fields'
import { Types } from '@/types/AppTypes'
import { LP } from '@/types/LP.types'
import { Field } from '@/types/FieldTypes'
import Confirm from '@/methods/confirm'
import { AxiosResponse } from 'axios'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'Files',

  components: {
    FilesFile,
  },

  props: {
    files: {
      type: Array as () => Field.File[],
      default: () => [],
    },
    fieldLabel: {
      type: String,
      default: '',
    },
    field: {
      type: Object as () => LP.Item,
      required: true,
    },
    forItem: {
      type: Object as () => Types.Item,
      required: true,
    },
    hasManyParentField: {
      type: Object as () => LP.Item,
      default: () => {},
    },
    hasManyParent: {
      type: Object as () => Types.Item,
      default: () => {},
    },
    edit: {
      type: Boolean,
      default: false,
    },
    editInList: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      uploadFiles: [],
      uploading: false,
      isDragOver: false,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    showCompactOneFile () {
      return (!!this.hasManyParent?.['@class'] || this.editInList) && this.files.length > 0
    },

    fileInputId () {
      return 'upload_file' + Math.random()
    },
  },

  methods: {
    deleteFile (index: number) {
      Confirm.request(this.$i18n.t('aava.edit.confirm_destroy_file'), () => {
        this.$store.dispatch('deleteFile', { index, files: this.files })
      })
    },

    handleDragEnter (e: DragEvent) {
      e.preventDefault()
      this.isDragOver = true
    },

    handleDragLeave (e: MouseEvent) {
      if (!this.$refs.filesContainer.contains(e.relatedTarget)) {
        e.preventDefault()
        this.isDragOver = false
      }
    },

    selectFile () {
      const el = document.getElementById(this.fileInputId)
      if (!el) { return }
      el.click()
    },

    getDataURLs (e: DragEvent | MouseEvent) {
      const files: FileList | null = (e.target as HTMLInputElement)?.files || ((e as DragEvent).dataTransfer?.files || null) // Select or drag n drop
      return files
        ? Promise.all(Array.from(files)
          .map(fileToDataURL => fileToDataURL))
        : null
    },

    // Upload all files and get tokens/ids
    // this must be done synchronous in order for api to return valid tokens
    // with async way tokens are returned, but can't be used on item save or when selecting country
    readFiles (e: DragEvent | MouseEvent) {
      this.ready = false
      this.isDragOver = false
      this.getDataURLs(e).then(selectedFiles => {
        // this.setTempUploadingImages(selectedFiles)
        if (!selectedFiles || !selectedFiles[0]) { return }
        // this.setTempUploadingImages(selectedFiles)
        selectedFiles
          .reduce((previousPromise, file) => {
            return previousPromise.then(() => {
              return this.readFile(file, e)
            })
          }, Promise.resolve()).then(() => {
            this.ready = true
          })
      })
    },

    readFile (file, e) {
      return new Promise(resolve => {
        if (!file) {
          resolve(false)
          return
        }
        // this.uploadFile = URL.createObjectURL(file)
        const reader = new FileReader()
        reader.onload = e => {
          this.uploading = true
          this.$store.dispatch('addNewFile', {
            file,
            files: this.files,
            forObjectClass: this.forItem['@class'],
            forFieldName: this.field.name,
            forObjectId: this.forItem.token || this.forItem.id,
            attachmentType: this.field.type === 'pdf' ? 'pdf' : 'file',
            parentClass: this.hasManyParent?.['@class'],
            parentTokenOrId: this.hasManyParent?.token || this.hasManyParent?.id,
            parentFieldName: this.hasManyParentField?.name,
            skipPathQuery: this.hasManyParent && !this.edit, // When in has-many and show view, do not send path query
          }).then((response: AxiosResponse) => {
            this.uploading = false
            resolve(response)
          })
        }
        reader.readAsText(file)
      })
    }
  }
}
</script>

<style lang="scss">
.form-files-list.empty-list {
  .item-show-field {
    padding: 4px 0 !important;
    height: 40px !important;
    .item-show-value {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
.form-files-list.drag-over {
  .show-field-files {
    border: 2px solid #388E3C;
  }
}
.form-files-list.compact-one-file {
  .item-show-field {
    padding: 0 0 1px 8px !important;
    max-height: 34px;
    .item-show-value {
      padding-right: 8px;
      position: relative;
      .add-btn.v-btn--fab.v-size--x-small {
        height: 25px;
        width: 25px;
      }
      .add-btn {
        position: absolute;
        top: 4px;
        right: 2px;
      }
      .v-chip {
        height: 21px;
        margin-top: 2px;
      }
      button.fa-times-circle {
        font-size: 14px !important;
      }
    }
  }
}
.form-files-list {
  .item-show-field {
    padding: 5px 8px !important;
    .item-show-value {
      width: 100%;
      .add-btn {
        margin: 0 0 7px 0 !important;
      }
      button.fa-times-circle:before {
        color: #999;
      }
    }
  }
  .v-chip {
    /* width: calc(100% - 10px) !important; */
    display: block; /* One per row in has many list */
    .v-chip__close {
      position: absolute !important;
      right: 10px;
      background: #e0e0e0;
    }
  }
}
.item-has-many-items {
  .form-files-list.empty-list {
    .item-show-field {
      padding: 2px 0 !important;
      height: 34px !important;
    }
  }
}
</style>
