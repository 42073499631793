var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.tooltip),expression:"tooltip",modifiers:{"top":true}}],class:'item-show-field ' + (_vm.maxOneRowHeight ? 'single-row' : ''),style:(_vm.fieldStyle)},[_c('div',{class:'item-show-label ' + (!!_vm.displayValue ? ' item-show-label-filled-todo-to-be-decided' : '')},[_c('span',[_vm._v(_vm._s(_vm.label))])]),_c(_vm.hasManyItemLink ? 'router-link' : 'div',{ref:"viewText",tag:"component",class:_vm.field.type + '-type item-show-value',attrs:{"to":_vm.hasManyItemLink,"tabindex":"-1"},domProps:{"innerHTML":_vm._s(typeof _vm.displayValue === 'string'
      ? (_vm.displayValue.trim() || '&nbsp;')
      : _vm.displayValue)}}),(_vm.contentIsOverflowing)?_c('div',{style:({
      position: 'absolute',
      right: '8px',
      top: '8px',
    })},[_c('v-icon',{attrs:{"x-small":""},on:{"click":function($event){_vm.maxOneRowHeight = !_vm.maxOneRowHeight}}},[_vm._v(" fa-chevron-"+_vm._s(_vm.maxOneRowHeight ? 'down' : 'up')+" ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }