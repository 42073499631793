<template>
  <div
    v-if="parentItem"
    :class="'form-has-many-multiselect ' +
      // (edit && !isPreventWriteMember ? 'item-has-many-items-edit' : 'item-has-many-items-show') +
      (itemLayoutEditMode ? ' no-text-select' : '')
    "
  >
    <div class="item-show-field">
      <div class="item-show-label">
        {{ label }}
      </div>
      <div
        ref="contentRef"
        class="item-show-value px-2 pt-1"
        :style="{
          position: 'relative',
          paddingRight: '20px !important',
        }"
      >
        <v-menu
          v-if="contentIsOverflowing && !measuringOverflow"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              fab
              color="grey"
              class="mt-n2 mb-n1 mr-1"
              tabindex="-1"
              x-small
              :style="{
                borderRadius: '20px',
                background: '#ddd',
                width: '18px',
                height: '18px',
              }"
              v-bind="attrs"
              v-on="on"
            >
              {{ items.length }}
            </v-btn>
          </template>
          <v-container
            class="item-container-menu popup-menu elevation-5 form-has-many-multiselect"
            :style="{ position: 'relative'}"
          >
            <v-row>
              <v-col

                cols="12"
              >
                <v-chip
                  v-for="(item, index) in items"
                  :key="item.id"
                  :close="edit"
                  :color="edit ? 'grey lighten-3' : 'grey lighten-2'"
                  :to="edit ? '' : generateReferenceLinkInSplitMode({
                    className: resource,
                    id: item.id,
                  })"
                  class="mr-1 pl-2 pr-2 my-1"
                  small
                  label
                  text-color="black"
                  @click:close="() => {
                    removeFromMultiselectInput(item)
                  }"
                >
                  {{ itemLabel(item) }}
                </v-chip>
              </v-col>
            </v-row>
          </v-container>
        </v-menu>
        <v-chip
          v-for="(item, index) in items"
          :key="item.id"
          :close="edit"
          :color="edit ? 'grey lighten-3' : 'grey lighten-2'"
          :to="edit ? '' : generateReferenceLinkInSplitMode({
            className: resource,
            id: item.id,
          })"
          class="mr-1 pl-2 pr-2 mb-1"
          small
          label
          text-color="black"
          @click:close="() => {
            removeFromMultiselectInput(item)
          }"
        >
          {{ itemLabel(item) }}
        </v-chip>
        <div
          class="fa-plus-container"
        >
          <v-btn
            v-if="edit"
            text
            fab
            color="grey"
            class="mt-n1 mb-n1"
            tabindex="-1"
            x-small
            @click="openPicker"
          >
            <v-icon
              small
            >
              fa-plus
            </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import util from '../../utilities/sharedUtilities'
import { createHelpers } from 'vuex-map-fields'
import itemSaveQueueMethods from '@/methods/item/itemSaveQueueMethods'
import { HasManyListColumn, LP } from '@/types/LP.types'
import { BaseItem, Types } from '@/types/AppTypes'
import {
  getHasManyItems,
  hasManyListLoadFilters,
  initializeHasManyListComponent,
  prepareColumnsInfoForHasManyList,
  processNewItemFromQueue,
  updateHasManyParentItemOnDefaultsForChange,
} from '@/components/Item/HasManyItems.vue'
import { showItemPickerFor } from '@/components/ItemPicker/itemPicker.methods'
import { commonStateVarsUsed } from '@/store/state'
import {
  deleteHasManyItemById, deleteHasManyItemByToken,
  deleteHasManyItemWithWarning,
} from '@/components/Item/HasMany.delete.methods'
import { addNewHasManyItemToStore, addNewSelectItemToStore } from '@/components/Item/HasMany.add.methods'
import listItemMethods from '@/methods/listItem/listItemMethods'
import { getItemSavePayload, getFormItemOnDefaultsForChange } from '@/methods/item/itemMethods'
import api from '@/store/api'
import { AxiosResponse } from 'axios'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'HasManyItemsSimpleMultiselect',

  props: {
    value: {
      type: Array,
      default: () => { return [] },
    },
    total: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: null,
    },
    field: {
      type: Object as () => LP.Item,
      required: true,
    },
    parentItem: {
      type: Object as () => Types.Item,
      required: true,
    },
    parentResource: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
      default: false,
    },
    editInList: {
      type: Boolean,
      default: false,
    },
    layoutEditMode: {
      type: Boolean,
      default: false,
    },
    availableSpaceInFieldSet: {
      type: Number,
      default: 23,
    },
    showItemModal: {
      type: Function,
      default: () => {},
    },
    modal: {
      type: Boolean,
      default: false,
    },
    updateItemAfterSelectingNewHasManyChild: {
      type: Function,
      default: () => {},
    },
    updateItemOnDefaultsForChange: {
      type: Function,
      default: () => {},
    },
    unfinishedDfcCount: {
      type: Number,
      default: 0,
    },
    isHasManyField: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      isSimpleMultiselect: true,
      items: [] as Types.Item[],
      amc: {} as LP.AMC,
      hmListColumns: [] as HasManyListColumn[],
      loadingItems: true,
      loadingAllItems: false,
      loadingNewFor: null,
      listQueries: [],
      autofocusLastItemFirstTextField: false,
      focusedRowIndex: 0,
      addQueue: [],
      reloadWhenItemSaveQueueIsEmpty: false,
      fieldsPickedByItemDesigner: [],
      fixedWithsByItemDesigner: {},
      indeterminateProgress: false,
      layoutChangeTimer: null,
      // Temporary hold the field name to use when auto-filling reference field value
      // for new has-many item which was picked from the item picker (feature add_multi_by_reference)
      debug: false,
      contentIsOverflowing: false,
      measuringOverflow: false,
      showMenu: false,
    }
  },

  watch: {
    parentId () {
      if (!this.isHasManyField) {
        this.initializeHasManyListComponent()
      }
    },

    selectedItemsHash () {
      this.checkContentOverflowing()
    },

    reloadWhenItemSaveQueueIsEmpty (val) {
      if (!val) {
        console.log('-s-e-t-:::::::', false)
        return
      }
      this.whenQueueIsEmpty().then(() => {
        if (!this.isHasManyField) {
          this.initializeHasManyListComponent()
        }
      })
    },

    loadingItems (val) {
      if (val) {
        // When small form and loading very fast
        // No need to flash the loader in this case
        setTimeout(() => {
          if (this.loadingItems) {
            this.indeterminateProgress = true
          }
        }, 1000)
        return
      }
      this.checkContentOverflowing()
      this.indeterminateProgress = false
    },
  },

  created () {
    this.$store.dispatch('getAttributeMetadata', this.resource).then(amc => {
      this.amc = amc
      if (this.editInList) {
        this.items = [...this.value]
        this.checkContentOverflowing()
      } else {
        if (this.isHasManyField) {
          this.items = [...this.value]
          this.loadingItems = false
        } else {
          this.initializeHasManyListComponent()
        }
      }
    })
    window.addEventListener('resize', this.resizeHandler)
  },

  destroyed () {
    window.removeEventListener('resize', this.resizeHandler)
  },

  computed: {
    ...mapFields([...commonStateVarsUsed, 'amByModel']),
    hasManyListLoadFilters,

    selectedItemsHash () {
      return this.items.map(item => item.id).join('-')
    },

    resource () {
      return util.objectClassUnderscoredName(this.field.reference_class)
    },

    multiselectParentReferenceAttribute () {
      return this.field.widgetConfig?.multiselect_parent_attribute
    },

    selectedPickerItemIds () {
      return this.items.map(item => {
        if (this.multiselectParentReferenceAttribute) {
          return item[this.field.widgetConfig.multiselect_parent_attribute]?.id
        }
        return item.id
      })
    },

    parentId () {
      return (!this.editInList && this.parentItem?.token) || // Edit in edit form, probably on main item
          this.parentItem?.id || // Edit in show view (case 1: ManufacturingApp)
          null
    },
  },

  methods: {
    ...itemSaveQueueMethods,
    ...listItemMethods,
    deleteHasManyItemWithWarning,
    initializeHasManyListComponent,
    prepareColumnsInfoForHasManyList,
    getHasManyItems,
    showItemPickerFor,
    addNewSelectItemToStore,
    deleteHasManyItemById,
    deleteHasManyItemByToken,
    updateHasManyParentItemOnDefaultsForChange,
    processNewItemFromQueue,
    addNewHasManyItemToStore,
    getItemSavePayload,
    getFormItemOnDefaultsForChange,

    resizeHandler () {
      clearTimeout(this.resizeTimer)
      this.resizeTimer = setTimeout(() => {
        this.checkContentOverflowing()
      }, 1000)
    },

    checkContentOverflowing () {
      this.measuringOverflow = true
      this.$nextTick(() => {
        const el = this.$refs.contentRef
        if (!el) { return }
        this.contentIsOverflowing = el.offsetHeight < el.scrollHeight - 10
        this.measuringOverflow = false
      })
    },

    itemLabel (item: BaseItem) {
      const summaryField = this.field.widgetConfig?.multiselect_input_display_attribute || 'summary'
      // Selected and shown by parent attribute
      if (this.multiselectParentReferenceAttribute) {
        return item[this.field.widgetConfig.multiselect_parent_attribute]?.[summaryField]
      }
      return item[summaryField]
    },

    removeFromMultiselectInput (item: BaseItem) {
      // Delete reference item from store
      if (item.id) {
        this.deleteHasManyItemById(item.id)
      } else if (item.token) {
        this.deleteHasManyItemByToken(item.token)
      } else { return }
      // Also delete from the server?
      const idOrToken = this.editInList ? item.id : (item.token || item.id)
      if (this.editInList) { // && this.field.remove_type === 'destroy'
        api.deleteItem(util.objectClassUnderscoredName(item['@class']), idOrToken!).then(response => {
          this.$store.dispatch('globalErrorDisplay', { response, context: 'Remove simple-multiselect-item: ' + item.id })
        })
      } else if (!this.editInList) {
        this.whenQueueIsEmpty().then(() => {
          this.updateItemAfterSelectingNewHasManyChild(this.field.name).then()
        })
      }
    },

    addMultiselectOption (selectedOption: BaseItem) {
      this.startJob().then(key => {
        // For simple multiselect has-many inside has-many, to generate ~path to the main form item
        let path = ''
        const parentHasManyParentField = this.edit ? this.$parent?.$parent?.parentField : null
        const parentHasManyParentItem = this.edit ? this.$parent?.$parent?.parentItem : null
        if (parentHasManyParentField && parentHasManyParentItem) {
          path = '~path=' + parentHasManyParentItem['@class'] +
              ':' + (parentHasManyParentItem.token || parentHasManyParentItem.id) + '%20' + parentHasManyParentField.name + '&'
        }
        // 1. Get token for new item
        api.fetchTokenFor({
          objectClass: this.parentResource,
          referenceClass: this.resource,
          objectToken: this.parentId,
          referenceField: this.field.name,
          queries: this.listQueries, // TODO - too many?
          path,
        }).then((tokenResponse: AxiosResponse) => {
          this.$store.dispatch('globalErrorDisplay', { response: tokenResponse, context: 'Token for new has-many in list-edit' })
          if (tokenResponse.data.item) {
            const item = tokenResponse.data.item
            this.$store.dispatch('getAttributeMetadata', item['@class']).then(amc => {
              item[util.objectClassUnderscoredNameSingular(this.multiselectParentReferenceAttribute)] = selectedOption
              const saveItem = this.getItemSavePayload(item, amc)
              // OK to add to the store now, visible for the user
              this.addNewHasManyItemToStore(item)
              // Save as permanent item when in list edit mode
              if (this.editInList) {
                api.saveItem(util.objectClassUnderscoredName(item['@class']), saveItem, [], {}).then(response => {
                  // Update item id so it can be deleted
                  item.id = response?.data?.item?.id
                  this.$store.dispatch('globalErrorDisplay', { response, context: 'Add simple-multiselect-item' })
                  this.endJob(key)
                })
              } else {
                // 2. Now important to update temp object with references value. Calling "dummy" DFC for updated_at
                this.getFormItemOnDefaultsForChange(util.objectClassUnderscoredName(item['@class']), item.token, true, {
                  field: 'updated_at',
                  item: saveItem,
                }).then(() => {
                  // 3. As a final step update parent
                  // same way as in addNewSelectItemToStore for normal has-many add
                  this.updateItemAfterSelectingNewHasManyChild(this.field.name).then(() => {
                    this.endJob(key)
                  })
                })
              }
            })
          }
        })
      })
    },

    requestReloadWhenItemSaveQueueIsEmpty () {
      this.reloadWhenItemSaveQueueIsEmpty = true
    },

    openPicker (e: MouseEvent) {
      this.$store.state.hasManyComponentForItemPicker = this
      this.$store.state.itemPickerSearchTerm = ''
      const objectClass = this.field.widgetConfig?.multiselect_parent_attribute ||
          util.objectClassUnderscoredName(this.field.reference_class)
      this.showItemPickerFor(e, {
        ...{
          objectClass,
          selectColumns: this.field.widgetConfig?.multiselect_picker_display_attributes || ['summary'],
          selectCallback: this.editInList || this.multiselectParentReferenceAttribute
            ? this.addMultiselectOption
            : this.addNewSelectItemToStore,
          unselectCallback: () => {}, // Disabled for multiselect
          forField: this.field,
          forFieldName: this.field.name,
          forHasMany: true,
          multiSelect: true,
          disableDeselect: true,
        },
        ...(!this.editInList ? {
          forObjectClass: util.objectClassUnderscoredName(this.parentItem['@class']),
          forItem: this.parentItem,
        } : {})
      })

      this.loadingNewFor = null
    },
  },
}
</script>

<style lang="scss">
.has-many-items-inner-container {
  .form-has-many-multiselect {
    .item-show-field {
      max-height: 34px;
      .item-show-value {
        padding-top: 4px !important;
        /* Do not force to one row, need to 'act normal' for measuring height - is count btn needed? */
        white-space: normal;
        text-overflow: inherit;
      }
    }
  }
}
.form-has-many-multiselect {
  .item-show-field {
    padding: 4px 0 !important;
    height: 40px !important;
    .item-show-value {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      overflow: hidden;
      min-height: 26px;
      max-height: 29px;
      padding-top: 8px !important;
    }
  }
  .v-chip.v-size--small {
    height: 21px;
    .v-chip__close.v-icon {
      font-size: 14px !important;
    }
  }
  .fa-times-circle {
    color: #999 !important;
  }
  .fa-plus-container {
    position: absolute;
    right: 6px;
    top: 4px;
    button {
      height: 22px;
      width: 22px;
    }
  }
}
</style>
