import Vue from 'vue'
import clearCache from '@/utilities/clearCache'

export default {
  setHasManyColumnWithChangeProps (e) {
    this.hasManyFixedWidthsSetByItemDesigner = this.fixedWithsByItemDesigner
  },

  // On component create
  initHasManyLayoutProps () {
    const hasManyLayout =
      this.field.has_many_layout && JSON.parse(this.field.has_many_layout)
    this.fieldsPickedByItemDesigner = JSON.parse(
      JSON.stringify(
        hasManyLayout?.fieldsPickedByItemDesigner?.filter((field) => {
          // Check that imported field is present in amc
          return !!this.amc[field.name]
        }) || [],
      ),
    )
    this.fixedWithsByItemDesigner =
      hasManyLayout?.fixedWithsByItemDesigner || {}
  },

  hasManyColumnResizeStart (e) {
    this.resizingHasManyField = this.field

    const initialWidth = e.target?.parentElement?.parentElement?.parentElement?.clientWidth || 100
    // Set initial width
    // BUT because of 100% wide table, when sum of column widths is less than full width
    // can't use exact current width, use what has been set before
    this.resizingFieldWidthBefore =
      this.hasManyFixedWidthsSetByItemDesigner?.[this.field.name] ||
      initialWidth
  },

  hasManyColumnResizeEnd () {
    this.resizingHasManyField = null
  },

  adjustHasManyFieldWidth (e) {
    if (!this.resizingHasManyField) {
      return
    }
    let newWidth = e.clientX - this.mouseDownCoords.x + this.resizingFieldWidthBefore
    const minWidth = 58
    if (newWidth < minWidth) {
      newWidth = minWidth
    }
    if (!this.hasManyFixedWidthsSetByItemDesigner) {
      return
    }
    this.$set(
      this.hasManyFixedWidthsSetByItemDesigner,
      this.resizingHasManyField.name,
      newWidth,
    )
  },

  saveHasManyLayout () {
    this.field.has_many_layout = JSON.stringify({
      fieldsPickedByItemDesigner: (this.fieldsPickedByItemDesigner || []).map(
        (item) => {
          return {
            name: item.name,
            sort_order: item.sort_order,
            editable_in_list: !!item.editable_in_list,
          }
        },
      ),
      fixedWithsByItemDesigner: this.fixedWithsByItemDesigner,
    })
    this.$store.dispatch('updateItemLayoutProfileItem', this.field)
    const selectedItemLayoutProfileId = this.selectedLayoutProfileIdByModel[this.objectClass]
    clearCache('lp_data_' + selectedItemLayoutProfileId)
    // Also update in the store, othewise when re-visiting same model later, changes are not without a refresh
    const fieldInStore = this.layoutProfileItemsById[selectedItemLayoutProfileId]
      .find(field => field.name === this.field.name)
    fieldInStore.has_many_layout = this.field.has_many_layout
  },
}
