<template>
  <v-dialog
    v-model="showUserLevelSettingsForm"
    class="white"
  >
    <v-container
      class="white"
      fluid
    >
      <v-row>
        <v-col cols="10">
          <h1>{{ $i18n.t('aava.menu_items.my_settings') }}</h1>
        </v-col>
        <v-col
          cols="2"
          class="text-right"
        >
          <v-btn
            icon
            @click="showUserLevelSettingsForm = false"
          >
            <v-icon>fa-times</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <TextArea
            v-model="settingsByKey.models_to_skip_reload_list_on_return"
            :item="settingsByKey"
            :field="{
              name: 'models_to_skip_reload_list_on_return',
            }"
            :label="$i18n.t('aava.userSettings.models_to_skip_reload_list_on_return')"
            resource="user_settings"
            spellcheck="false"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            :dark="!disabled"
            :disabled="disabled"
            :loading="saving"
            color="green darken-3"
            class="elevation-0"
            @click="saveUserLevelSettings"
          >
            {{ $i18n.t('aava.actions.save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script lang="ts">
import api from './../../store/api'
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import TextArea from '../Form/TextArea.vue'
import { AxiosResponse } from 'axios'
import { Types } from '@/types/AppTypes'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'UserLevelSettings',
  components: { TextArea },
  data () {
    return {
      saving: false,
      disabled: false,
      settingsByKey: {
        models_to_skip_reload_list_on_return: '',
      },
    }
  },

  computed: {
    // Use commonStateVarsUsed
    ...mapFields(Object.keys(state)),

    userProfile () {
      // Assume here that user has a UserProfile already
      return this.userInfo.user_profiles?.[0]
    },
  },

  created () {
    this.setData()
  },

  methods: {
    saveUserLevelSettings () {
      const promises: any = []
      this.saving = true
      Object.keys(this.settingsByKey).forEach(key => {
        // May, or may not be existing setting
        const setting = this.$store.getters.userLevelSettings[key]
        const payload: Types.Item = {
          profile: {
            '@class': 'UserProfile',
            id: this.userProfile.id,
          },
          setting_type: null,
          key,
          value: this.settingsByKey[key],
        }
        if (setting) {
          payload.id = setting.id
        }
        promises.push(this.saveOne(payload))
      })
      let errors = false
      Promise.all(promises).then(responses => {
        responses.forEach(saveSuccessful => {
          if (!saveSuccessful) {
            errors = true
          }
        })
        if (!errors) {
          this.$store.dispatch('showMessage', {
            message: 'OK',
            type: 'success',
            expires: 1000,
          })
          this.showUserLevelSettingsForm = false
          location.reload()
        }
        setTimeout(() => {
          this.saving = false
        }, 500)
      })
    },

    saveOne (payload) {
      return new Promise(resolve => {
        api.saveItem('user_settings', payload).then((response: AxiosResponse | null) => {
          this.$store.dispatch('globalErrorDisplay', { response, context: 'Save user-setting' }).then(errorsFound => {
            resolve(!errorsFound)
          })
        })
      })
    },

    setData () {
      Object.keys(this.settingsByKey).forEach(key => {
        this.settingsByKey[key] = this.$store.getters.userLevelSettings?.[key].value || ''
      })
    },
  },
}
</script>
