import Vue from 'vue'
import Confirm from '@/methods/confirm'
import { BaseItem } from '@/types/AppTypes'
import { LPI } from '@/types/LP.types'

export type DeleteHasManyByIndexFunction = (
  index: number,
  items: BaseItem[],
  parentItem: BaseItem,
  field: LPI
) => void;

export type DeleteHasManyByIdFunction = (
  itemId: number,
) => void;

export type DeleteHasManyByTokenFunction = (
  itemToken: string,
) => void;

const deleteHasManyItem = (index: number, items: BaseItem[], parentItem: BaseItem, field: LPI) => {
  if (!items[index]) { return false }
  Vue.delete(items, index)
  const parentHasCount = parentItem[field.name]?.[0] &&
      Object.keys(parentItem[field.name][0]).length === 1 &&
      'count' in parentItem[field.name][0]
  if (parentHasCount) {
    const currentCount = (parentItem[field.name][0]?.count) || 0
    Vue.set(parentItem, field.name, [{ count: currentCount - 1 }])
  } else {
    // For multiselect has-many
    Vue.set(parentItem, field.name, [...items])
  }
  return true
}

export const deleteHasManyItemWithWarning: DeleteHasManyByIndexFunction = function (index, items, parentItem, field) {
  Confirm.request(this.$i18n.t('aava.confirmations.destroy_objects'), () => {
    deleteHasManyItem(index, items, parentItem, field)
    this.whenQueueIsEmpty().then(() => {
      this.updateHasManyParentItemOnDefaultsForChange()
    })
  })
}

export const deleteHasManyItemById:DeleteHasManyByIdFunction = function (itemId) {
  const index = this.items.map((item: BaseItem) => item && item.id).indexOf(itemId)
  deleteHasManyItem(index, this.items, this.parentItem, this.field)
}

export const deleteHasManyItemByToken:DeleteHasManyByTokenFunction = function (itemToken) {
  const index = this.items.map((item: BaseItem) => item && item.token).indexOf(itemToken)
  deleteHasManyItem(index, this.items, this.parentItem, this.field)
}
