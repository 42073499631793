<template>
  <div
    v-if="listColumnMenuField"
    id="list-header-menu"
    class="list-header-menu popup-menu elevation-5"
    elevation-1
  >
    <div
      :class="(!listFilters[listColumnMenuField] ? 'menu-el-disabled' : '') + ' popup-menu-el'"
      @click="addFilter"
    >
      <span class="fa fa-thumbtack header-btn-save" />
      {{ $i18n.t('aava.actions.save') }}
    </div>
    <div
      :class="(selectedLayoutProfile.locked ? 'menu-el-disabled ' : ' ') + ' popup-menu-el'"
      @click="hideField(listColumnMenuField)"
    >
      <span
        class="fa fa-eye-slash header-btn-hide"
      /> {{ $i18n.t('aava.actions.hide') }}
    </div>
    <div
      :class="'popup-menu-el ' + (listFilters[listColumnMenuField]
        ? '' : 'menu-el-disabled')"
      @click="emptySearch()"
    >
      <span
        class="fa fa-eraser header-btn-clear"
      />
      {{ $i18n.t('aava.actions.clear') }}
    </div>
    <div
      v-if="showMultiEditButton"
      :class="(selectedItems.length === 0 ? 'menu-el-disabled' : '') + ' popup-menu-el'"
      @click="openMultiEditMenu($event)"
    >
      <span class="fa fa-edit header-btn-edit" />
      {{ $i18n.t('aava.index.edit_many_selected') }}
    </div>
    <div
      class="popup-menu-el"
      @click="sortByHeaderField(listColumnMenuField, $event, 'asc')"
    >
      <span
        class="fa fa-sort-up list-sort-up header-btn-sort-asc"
      />
      {{ $i18n.t('aava.actions.sort_asc') }}
    </div>
    <div
      :class="'popup-menu-el '"
      @click="sortByHeaderField(listColumnMenuField, $event, 'desc')"
    >
      <span
        class="fa fa-sort-down list-sort-down header-btn-sort-desc"
      />
      {{ $i18n.t('aava.actions.sort_desc') }}
    </div>
  </div>
</template>

<script lang="ts">
import util from '../../utilities/sharedUtilities'
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import { LP } from '@/types/LP.types'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'HeaderColumnMenu',

  computed: {
    ...mapFields(Object.keys(state)),

    fieldInfo (): LP.Item | null {
      if (!this.listColumnMenuField) { return null }
      return this.layoutProfileItems
        .find((column: LP.Item) => column.name === this.listColumnMenuField)
    },

    showMultiEditButton () {
      const field = this.fieldInfo
      if (!field) { return }
      return field.name === 'main_object_process_events' || field.name === 'owner'
        ? true
        : !(
            !field.allow_multi_edit ||
            !field.writable ||
            field.type === 'richtext' ||
            field.dynamic ||
            field.widget ||
            // TODO widgetClass deprecated?
            (field.widgetClass && field.widgetClass.disallowMultiedit)
          )
    }
  },

  methods: {
    ...methods,

    hideField (field) {
      if (this.selectedLayoutProfile.locked) { return }
      this.$store.dispatch('hideListField', field)
      this.$nextTick(() => {
        this.adjustCellWidthsAndLineHeight()
      })
    },

    addFilter () {
      if (!this.listFilters[this.listColumnMenuField]) { return }
      this.listFiltersAddPermanent(this.listColumnMenuField)
    },

    openMultiEditMenu (e) {
      if (this.selectedItems.length === 0) { return }
      const leftOffset = -170
      const topOffset = -70
      this.multiEditColumn = this.listColumnMenuField
      this.listColumnMenuField = ''
      this.$nextTick(() => {
        util.positionPopupMenu(e, 'multi-edit-menu', leftOffset, topOffset, { })
      })
    },

    emptySearch () {
      if (this.listFieldsByName[this.listColumnMenuField].custom_value) {
        this.listFiltersRemovePermanent(this.listColumnMenuField)
      } else if (this.listFilters[this.listColumnMenuField]) {
        this.listFiltersRemove(this.listColumnMenuField)
      }
    }
  }
}
</script>

<style lang="less">
.list-header-menu {
	min-width: 150px;
}
</style>

<style>
.list-sort-up {
  position: relative;
  top: 3px;
}
.list-sort-down {
  position: relative;
  top: -3px;
}
</style>
