<template>
  <div
    :class="(fullWidth ? 'shortcuts-full-width' : '') + ' top_shortcuts_container'"
  >
    <div
      v-if="render"
      id="shortcut-items"
      :class="expanded && 'expanded-items'"
    >
      <v-btn
        v-if="showExpandBtn"
        icon
        small
        @click="expanded = !expanded"
      >
        <v-icon small>{{ expanded ? 'fa-caret-up' : 'fa-caret-down' }}</v-icon>
      </v-btn>
      <v-chip
        v-for="(shortcut, index ) in shortcuts.filter(s => !!s.link_title)"
        :key="shortcut.id"
        :to="shortcut.link_url.replace('#', '/')"
        color=""
        class="mx-1 my-1"
        outlined
        small
        label
        @click="openExternalLink(shortcut)"
      >
        <div class="shortcut_button_container">
          <v-icon
            :color="shortcutColor(shortcut)"
            class="pr-1"
            x-small
          >
            fa-{{ shortcutIcon(shortcut) }}
          </v-icon>
          {{ shortcut.link_title }}
        </div>
      </v-chip>
    </div>
  </div>
</template>

<script lang="ts">
import Color from 'color'
import Sortable from 'sortablejs'
import shortcutData from '@/store/dashboardItems/helpers/shortcutData'
import { DashboardTypes } from '@/types/DashboardTypes'

export default {
  name: 'DashboardShortcuts',

  props: {
    fullWidth: {
      type: Boolean,
      default: true,
    },
  },

  data () {
    return {
      sortable: null,
      render: true,
      expanded: false,
      showExpandBtn: false,
    }
  },

  computed: {
    resizeTrigger () {
      return this.$store.state.resizeTrigger
    },

    shortcuts: {
      get (): DashboardTypes.Shortcut[] {
        return this.$store.state.shortcuts
      },
      set (value) {
        // this.$store.commit('updateShortcutsOrder', value)
      },
    },
  },

  created () {
    this.$nextTick(() => {
      this.createShortcutsSortables()
      this.showExpandBtnIfNeeded()
    })
  },

  watch: {
    resizeTrigger (val) {
      this.showExpandBtnIfNeeded()
    },
  },

  methods: {
    createShortcutsSortables () {
      this.$nextTick(() => {
        this.sortable = null
        const el = document.getElementById('shortcut-items')
        if (!el) { return }
        this.sortable = Sortable.create(el, {
          onEnd: this.shortcutDragEnd,
          delay: 150,
        })
      })
    },

    showExpandBtnIfNeeded () {
      const el = document.getElementById('shortcut-items')
      if (!el) { return }
      this.showExpandBtn = el.scrollHeight > el.clientHeight + 10
    },

    openExternalLink (shortcut: DashboardTypes.Shortcut) {
      if (shortcut.details?.link_type !== 'external') { return }
      window.open(shortcut.details?.external_link.replace('#', '/'))
    },

    shortcutDragEnd (move) {
      const temp = this.shortcuts.splice(move.oldIndex, 1)[0]
      this.shortcuts.splice(move.newIndex, 0, temp)
      // Re-render for new order to be shown
      // Without it sortable new order and vuex new order are conflicted
      this.reRender()
      this.$store.commit('updateShortcutsOrder', this.shortcuts)
    },

    reRender () {
      this.render = false
      this.$nextTick(() => {
        this.render = true
        this.$nextTick(() => {
          this.createShortcutsSortables()
          this.showExpandBtnIfNeeded()
        })
      })
    },

    shortcutColor (shortcut: DashboardTypes.Shortcut) {
      return Color(shortcutData.shortcutColor(shortcut)).hex()
    },

    shortcutIcon (shortcut: DashboardTypes.Shortcut) {
      return shortcutData.shortcutIcon(shortcut)
    },
  }
}
</script>

<style scoped lang="scss">
#shortcut-items {
  overflow: hidden;
  max-height: 30px;
}
#shortcut-items.expanded-items {
  overflow: auto;
  max-height: none;
}
.shortcut_button_container {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 140px;
}
.dragged_shortcut {
  outline-offset: -3px;
  outline: 3px solid #fff159;
  cursor: move;
  cursor: -webkit-grabbing;
}
</style>
