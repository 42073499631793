<template>
  <v-progress-linear
    v-if="noSeLoading"
    :indeterminate="true"
    height="3"
    color="teal"
  />
  <div
    v-else
    class="no-se-notifications"
  >
    <v-data-table
      :headers="headers"
      :items="noSeMyNotifications"
      :loading="noSeLoading"
      :hide-default-footer="true"
      :no-data-text="$i18n.t('aava.no_se.no_own_notifications')"
      class="elevation-1"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="blue"
          indeterminate
        />
      </template>
      <template
        v-slot:item="props"
      >
        <tr
          class="rowClass"
        >
          <td>
            <v-chip
              v-if="props.item.active"
              class="ma-2"
              color="green darken-1"
              dark
              @click="activate(props.item, false)"
            >
              {{ $i18n.t('aava.no_se.active') }}
            </v-chip>
            <v-chip
              v-if="!props.item.active"
              class="ma-2"
              color="grey lighten-1"
              dark
              @click="activate(props.item, true)"
            >
              {{ $i18n.t('aava.no_se.not_active') }}
            </v-chip>
            <v-chip
              v-if="props.item.shared"
              class="ma-2"
              color="green darken-1"
              dark
              @click="share(props.item, false)"
            >
              {{ $i18n.t('aava.no_se.shared') }}
            </v-chip>
            <v-chip
              v-if="!props.item.shared"
              class="ma-2"
              color="grey lighten-1"
              dark
              @click="share(props.item, true)"
            >
              {{ $i18n.t('aava.no_se.not_shared') }}
            </v-chip>
          </td>
          <td>
            <b>{{ props.item.identifier }}</b>
          </td>
          <td>
            <span
              v-if="props.item.event_trigger"
            >
              {{ $i18n.t('aava.no_se.type_event') }}
            </span>
            <span
              v-else
            >
              {{ $i18n.t('aava.no_se.type_group') }}
            </span>
          </td>
          <td>
            {{ props.item.description }}
          </td>
          <td>
            <v-btn
              fab
              small
              text
              class="ma-2 green--text text--darken-1"
              @click="openForEditing(props.item)"
            >
              <v-icon>fa fa-edit</v-icon>
            </v-btn>
            <v-btn
              fab
              small
              text
              class="ma-2 red--text text--darken-1"
              @click="remove(props.item)"
            >
              <v-icon>fa fa-trash</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'NoSeMyNotifications',

  data () {
    return {
      tab: null,
      headers: [
        {
          text: this.$i18n.t('aava.no_se.status'),
          align: 'left',
          sortable: false
        },
        {
          text: this.$i18n.t('aava.no_se.name'),
          align: 'left',
          sortable: false
        },
        {
          text: this.$i18n.t('aava.no_se.type'),
          align: 'left',
          sortable: false
        },
        {
          text: this.$i18n.t('aava.no_se.description'),
          align: 'left',
          sortable: false
        },
        {
          text: this.$i18n.t('aava.no_se.actions_btn'),
          align: 'left',
          sortable: false
        },
      ]
    }
  },

  computed: {
    ...mapFields(Object.keys(state))
  },

  methods: {
    activate (item, active) {
      this.$store.dispatch('noSeActivate', { item, active }).then((success: boolean) => {
        if (success) {
          item.active = active
        }
      })
    },

    openForEditing (item) {
      this.noSeEditItemId = item.id
    },

    remove (item) {
      if (confirm(this.$i18n.t('confirmations.destroy_object'))) {
        this.$store.dispatch('noSeRemove', item).then(() => {
        })
      }
    },

    share (item, shared) {
      this.$store.dispatch('noSeShare', { item, shared }).then((success: boolean) => {
        if (success) {
          item.shared = shared
        }
      })
    },
  },
}
</script>
