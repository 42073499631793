<template>
  <v-col
    cols="12"
    class="text-center pb-0"
  >
    <v-btn
      :loading="savingItemLayout && !resettingLayout"
      color="grey"
      class="float-right le-btn-close"
      icon
      @click="$emit('closeLayoutEditMode')"
    >
      <v-icon small>
        fa-times
      </v-icon>
    </v-btn>
    <v-btn
      v-tooltip.top="$t('aava.layout.export_import')"
      color="grey"
      class="float-right le-btn-import"
      icon
      :disabled="savingItemLayout"
      @click="$emit('toggleImportForm')"
    >
      <v-icon small>
        fa-file-import
      </v-icon>
    </v-btn>
    <v-btn
      v-tooltip.top="$t('aava.layout.reset')"
      :loading="resettingLayout"
      :disabled="savingItemLayout"
      color="grey"
      class="float-right le-btn-trash"
      icon
      @click="$emit('resetLayout')"
    >
      <v-icon small>
        fa-trash-alt
      </v-icon>
    </v-btn>
    <v-btn
      v-tooltip.top="$t('aava.layout.hide_fields')"
      color="grey"
      class="float-right le-btn-toggle-selector"
      icon
      :disabled="savingItemLayout"
      @click="$emit('toggleFieldSelector')"
    >
      <v-icon small>
        fa-th-list
      </v-icon>
    </v-btn>
  </v-col>
</template>
<script lang="ts">
export default {
  name: 'LayoutEditorToolbar',
  props: {
    resettingLayout: {
      type: Boolean,
      required: true,
    },
    savingItemLayout: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
