<template>
  <v-container
    ref="itemFormInner"
    class="pa-0"
    fluid
    fill-height
    @scroll="handleItemScroll"
  >
    <ItemFieldSetMenu
      v-if="$store.state.dialogItemFieldSetMenu"
      :layout-profile="layoutProfile"
    />
    <div v-if="!modal">
      <v-dialog
        v-if="itemModalProps.show"
        v-model="itemModalProps.show"
        persistent
      >
        <Item
          :id="itemModalProps.id"
          ref="modal_item"
          :item-action="itemModalProps.edit ? 'edit' : 'show'"
          :resource="itemModalProps.resource"
          :target-resource="itemModalProps.targetResource"
          :target-id="itemModalProps.targetId"
          :target-field="itemModalProps.targetField"
          :modal-props="itemModalProps"
          item-form-position="modal"
          style="background: white;"
        />
      </v-dialog>
    </div>
    <v-row no-gutters>
      <v-col
        class="flex-grow-1 flex-shrink-0"
      >
        <v-container
          id="item-form-container"
          :style="style"
          class="item-form-container align-content-start"
          fluid
          fill-height
        >
          <v-container
            class="pa-0"
            fluid
          >
            <!-- Warning that desktop resolution must be used for item layout editor -->
            <LayoutEditorNotAvailable
              v-if="showLayoutEditorNotAvailableMessage"
              @closeLayoutEditMode="closeLayoutEditMode"
            />
            <v-row
              v-else-if="render && layoutProfilesItemsLoaded"
              id="level-1-sort"
              :class="'containers-outer'"
              @mouseup="dragEnd"
            >
              <LayoutEditorToolbar
                v-if="itemLayoutEditMode"
                :resetting-layout="resettingLayout"
                :saving-item-layout="savingItemLayout"
                @toggleImportForm="showLayoutImportExportForm = !showLayoutImportExportForm"
                @toggleFieldSelector="toggleFieldSelector"
                @closeLayoutEditMode="closeLayoutEditMode"
                @resetLayout="resetLayout"
              />
              <LayoutEditorImportExport
                v-model="showLayoutImportExportForm"
                :containers="containers"
                :field-sets="fieldSets"
                :fields="fields"
                :layout-profile="layoutProfile"
                @import="importLayoutFromJSON"
              />
              <CustomAppComponent
                v-if="layoutProfile.name === 'metal-demo'"
                app-file="metal-apps/manufacturing/QuickModuleEntry.vue"
                :item="item"
                :edit="edit"
              />
              <v-col
                v-for="(container, containerIndex) in containersComputed"
                :key="container.id || containerIndex"
                :class="getContainerClasses(container)"
                :cols="getColsForContainer(container)"
                :data-index="containerIndex"
                :data-container-id="container.id"
              >
                <template v-if="debug">
                  {{ getColsForContainer(container) }} / /
                  {{ getAvailableSpaceForContainer(container) }}px
                </template>
                <v-container
                  :fill-height="!itemLayoutEditMode"
                  class="container-form-inner align-content-start"
                  fluid
                >
                  <ContainerEditorToolbar
                    v-if="itemLayoutEditMode"
                    :index="containerIndex"
                    :container="container"
                    @showItemContainerMenu="showItemContainerMenu"
                    @updateLayoutContainer="updateLayoutContainer"
                    @toggleContainerCollapse="toggleContainerCollapse"
                  />
                  <ContainerName
                    v-else-if="container['name_' + locale]"
                    :container="container"
                    :disabled="disabledInModal"
                    :name="container['name_' + locale] || getContainerTranslatedName(container)"
                    :icon="container.collapsed || collapsedVirtualContainers[container.id] ? 'fa-chevron-down' : 'fa-chevron-up'"
                    @toggle="toggleContainerCollapse(container)"
                  />
                  <ContainerInfoText
                    v-if="container.showInfoText && !container.collapsed"
                    :container="container"
                    :edit="itemLayoutEditMode"
                    @updateLayoutContainer="updateLayoutContainer"
                  />
                  <v-row
                    v-if="container && !container.collapsed && !collapsedVirtualContainers[container.id]"
                    :id="'level-2-sort-' + container.id"
                    :class="fieldSetClasses(container)"
                  >
                    <v-col
                      v-for="(fieldSet, fieldSetIndex) in fieldSetsComputed(container)"
                      :key="fieldSet.id || (containerIndex + '_' + fieldSetIndex)"
                      :class="getFieldSetClasses()"
                      :data-sort-index_delete_this="(containerIndex * 100) + fieldSetIndex"
                      :data-field-set-index="fieldSetIndex"
                      :data-field-set-id="fieldSet.id"
                      :cols="getFieldSetColsInContainer(container, fieldSet)"
                    >
                      <template v-if="debug">
                        {{ getFieldSetColsInContainer(container, fieldSet) }} //
                        {{ getAvailableSpaceForContainerFieldSets(container, fieldSet) }}px
                      </template>
                      <v-container
                        class="field-set-form-inner-container fill-height align-content-start"
                        :style="fieldSetBgStyle(fieldSet)"
                        fluid
                      >
                        <FieldSetEditorToolbar
                          v-if="itemLayoutEditMode"
                          :index="fieldSetIndex"
                          :container="container"
                          :field-set="fieldSet"
                          :field-sets="fieldSets"
                          :available-space-in-field-set="getAvailableSpaceForContainerFieldSets(container, fieldSet)"
                          @showItemFieldsetMenu="showItemFieldsetMenu"
                          @updateLayoutContainerFieldSet="updateLayoutContainerFieldSet"
                        />
                        <v-row v-else-if="fieldSet['name_' + locale]">
                          <v-col
                            cols="12"
                            class="field-set-title"
                          >
                            {{ fieldSet['name_' + locale] }}
                          </v-col>
                        </v-row>
                        <v-row
                          :id="'level-3-sort-' + fieldSet.id"
                          :class="(draggingItemType === 'form-field' ? 'level-3-sort-area' : '') + ' field-set-fields'"
                        >
                          <template
                            v-for="(field, index) in getFieldsInContainerFieldSet(containerIndex, fieldSetIndex, fieldSet)"
                          >
                            <FormField
                              v-if="!(item['@hidden_members'] && item['@hidden_members'].includes(field.name) && !itemLayoutEditMode)"
                              :id="id"
                              :ref="field.name"
                              :key="field.type + '_' + field.id + index"
                              :edit="edit"
                              :field="field"
                              :field-index="field.fieldIndex"
                              :resource="resource"
                              :item="item"
                              :modal="modal"
                              :autofocus="isFirstFormInputField(field)"
                              :available-space-in-field-set="getAvailableSpaceForContainerFieldSets(container, fieldSet)"
                              :content-cols="fieldSet.content_columns"
                              :layout-edit-mode="itemLayoutEditMode"
                              :show-item-picker-for="showItemPickerFor"
                              :show-item-field-menu="showItemFieldMenu"
                              :show-item-modal="showItemModal"
                              :field-set-width-columns="getColsForContainerFieldSets(fieldSet)"
                              :update-item-on-defaults-for-change="updateItemOnDefaultsForChange"
                              :update-item-after-selecting-new-has-many-child="updateItemAfterSelectingNewHasManyChild"
                              :unfinished-dfc-count="unfinishedDfcCount"
                              @saveLayoutEditorOnExtraItemChange="() => {
                                // layoutProfile is present here, but not in the <FormField>
                                $store.dispatch('saveLayoutProfileData', layoutProfile)
                              }"
                            />
                          </template>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </v-col>
      <v-col
        v-if="showItemFieldSelector && !modal"
        class="flex-grow-0 flex-shrink-1"
      >
        <v-container
          :style="style"
          class="pa-0"
        >
          <v-row no-gutters>
            <v-col>
              <ItemFieldSelector
                :fields="fields"
                :resource="resource"
                :show-loader="showLoaderComputed"
                :render="render"
                :show-item-field-menu="showItemFieldMenu"
                :toggle-layout-editor-selected-field="toggleLayoutEditorSelectedField"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import util from '../../utilities/sharedUtilities'
import methods from './../methods'
import FormField from './FormField.vue'
import ItemFieldSelector from './ItemFieldSelector.vue'
import { EventBus } from '@/eventBus'

import state from './../../store/state'
import { createHelpers } from 'vuex-map-fields'
import ContainerEditorToolbar from '@/components/Item/LayoutEditor/ContainerEditorToolbar.vue'
import itemFormMethods from '@/components/Item/itemFormMethods'
import layoutEditMethods from '@/components/Item/LayoutEditor/layoutEditMethods'
import LayoutEditorImportExport from '@/components/Item/LayoutEditor/LayoutEditorImportExport.vue'
import FieldSetEditorToolbar from '@/components/Item/LayoutEditor/FieldSetEditorToolbar.vue'
import Vue from 'vue'
import { FieldType, FormItem, LP, LPI } from '@/types/LP.types'
import CustomAppComponent from '@/customer-apps/CustomAppComponent.vue'
import LayoutEditorNotAvailable from '@/components/Item/LayoutEditorNotAvailable.vue'
import LayoutEditorToolbar from '@/components/Item/LayoutEditorToolbar.vue'
import ContainerName from '@/components/Item/ContainerName.vue'
import ItemFieldSetMenu from '@/models/LayoutContainerFieldSet/LCFS.menu.vue'
import Sortable from 'sortablejs'
import { getSelectedItemLP } from '@/methods/item/itemMethods'
import ContainerInfoText from '@/components/Item/ContainerInfoText.vue'

// @ts-ignore temp, until switching to Vue 3 and Vuex 4
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

const menuBarHeightWithFormTopMargin = 40 + 4

export default {
  name: 'ItemForm',

  components: {
    ContainerInfoText,
    ItemFieldSetMenu,
    ContainerName,
    LayoutEditorToolbar,
    LayoutEditorNotAvailable,
    CustomAppComponent,
    FieldSetEditorToolbar,
    LayoutEditorImportExport,
    Item: () => import('@/views/Item.vue'), // For Item > ItemForm > Item to be possible
    ContainerEditorToolbar,
    FormField,
    ItemFieldSelector,
  },

  props: {
    value: {
      type: Object,
      default: () => {}
    },
    id: {
      type: [String, Number],
      default: null,
    },
    itemMeta: {
      type: Object,
      default: () => {},
    },
    targetResource: {
      type: String,
      default: '',
    },
    targetId: {
      type: [Number, String],
      default: '',
    },
    targetField: {
      type: String,
      default: '',
    },
    resource: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
    modalProps: {
      type: Object,
      default: () => {},
    },
    itemFormPosition: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      debug: false,
      render: true,
      savingItemLayout: false,
      resettingLayout: false,
      layoutProfilesItemsLoaded: false,
      containerSortable: null as Sortable | null,
      fieldSetSortables: [] as Sortable[],
      fieldSortables: [] as Sortable[],
      fieldSelectorSortables: [] as Sortable[],
      itemCopy: {}, // To keep track if item data has been changed
      showLayoutImportExportForm: false,
      itemModalProps: {
        show: false,
        id: null,
        edit: true,
        targetResource: '',
        targetId: '',
        targetField: '',
        resource: '',
        showLoader: false,
        layoutProfileContext: '',
        saveCallback: () => {},
        closeCallback: () => {},
      },
    }
  },

  mounted () {
    EventBus.$on?.('callUpdateMainItemOnHasManyChildItemEventAction', this.updateMainItemOnHasManyChildItemEventAction)
  },

  beforeDestroy () {
    EventBus.$off?.('callUpdateMainItemOnHasManyChildItemEventAction', this.updateMainItemOnHasManyChildItemEventAction)
  },

  computed: {
    ...mapFields(Object.keys(state)),

    // Aava-Vue-527
    // Hack fix to allow item-picker columns search when opened from modal view
    disabledInModal () {
      return !!(this.itemPickerProps?.objectClass && this.modal && this.itemPickerProps?.selectColumns?.length)
    },

    unfinishedDfcCount () {
      return this.itemTotalUnfinishedDfcRequestsCount()
    },

    showLayoutEditorNotAvailableMessage () {
      return this.itemLayoutEditMode && (!this.$vuetify.breakpoint.mdAndUp || this.splitMode)
    },

    modal () {
      return this.itemFormPosition === 'modal'
    },

    splitProps () {
      return this.$route.params
    },

    containersComputed (): LP.Container[] {
      return this.containers.length > 0
        ? this.containers.filter((container: LP.Container) => {
          return this.itemLayoutEditMode || this.fieldSetsComputed(container).length > 0
        })
        : this.virtualContainers
    },

    itemIsPendingSave () {
      return this.itemCopy && this.edit && this.item['@editable'] && JSON.stringify(this.item) !== JSON.stringify(this.itemCopy)
    },

    isInFirstSplitWindow () {
      return this.itemFormPosition === 'first' && this.splitProps.secondResource
    },

    formWidth () {
      if (this.splitMode !== 'vertical') {
        return this.innerWidth
      }
      const formWidth = this.innerWidth * (100 - this.splitModeListSize) / 100
      return this.isInFirstSplitWindow
        ? this.innerWidth - formWidth
        : formWidth
    },

    initializeTrigger () {
      return this.id + '-' + this.edit // + '-' + this.targetId
    },

    item: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      },
    },

    showLoaderComputed: {
      get () {
        return this.showLoader
      },
      set (value) {
        this.$emit('updateShowLoader', value)
      },
    },

    triggerForSavingVirtualContainers () {
      if (!this.itemLayoutEditMode) { return false }
      return this.itemLayoutEditMode + this.selectedItemLayoutProfileId
    },

    showItemFieldSelector () {
      return this.itemLayoutEditMode && !this.hideFieldSelector
    },

    containers (): LP.Container[] {
      return this.layoutContainers[this.selectedItemLayoutProfileId] || []
    },

    fieldSets (): LP.FieldSet[] {
      return this.layoutContainerFieldSets[this.selectedItemLayoutProfileId]?.filter(fieldSet => {
        const fields = this.getFieldsInContainerFieldSet(-1, -1, fieldSet)
        return this.itemLayoutEditMode || fields.length > 0
      }) || []
    },

    layoutProfile (): LP.LayoutProfile | null {
      return getSelectedItemLP(this, this.resource, this.selectedItemLayoutProfileId)
    },

    // Form items sorted
    // Mixes LP items and form extra items
    formFields () {
      const idToIndex = {}
      this.layoutProfile.timelineConfiguration.itemsSortOrder.forEach((id, index) => {
        idToIndex[id] = index
      })
      return this.fields.filter((field: FormItem) => {
        //  (field.name in this.item || field.name + '_' + this.locale in this.item) &&
        return !field.isLPI || field.visible
      })
        .sort((a, b) => {
          // Use prefix '_' for form extra items
          const indexA = idToIndex[a.isLPI ? a.id : ('_' + a.id)] ?? 10000
          const indexB = idToIndex[b.isLPI ? b.id : ('_' + b.id)] ?? 10000
          // console.log(a.isLPI ? a.id : ('_' + a.id), b.isLPI ? b.id : ('_' + b.id))
          // console.log(a, b, indexA, indexB)
          return indexA - indexB
        })
    },

    style () {
      let height = (this.innerHeight - menuBarHeightWithFormTopMargin - this.itemToolbarHeight)
      if (this.splitMode === 'horizontal') {
        height = this.horizontalSplitModeHeight
      }
      if (this.suppressToolbar) {
        height += 66
      }
      if (this.modal) { height = height - 100 }
      return {
        height: height + 'px',
        position: 'relative',
        overflowY: 'scroll',
        paddingBottom: this.$vuetify.breakpoint.xsOnly ? '76px !important' : '',
      }
    },

    horizontalSplitModeHeight () {
      const padding = 6
      let height = this.innerHeight / 100 * (100 - this.splitModeListSize) - this.itemToolbarHeight - padding
      // Item is opened in horizontal split screen top part
      if (this.isInFirstSplitWindow) {
        height = this.innerHeight / 100 * this.splitModeListSize - this.itemToolbarHeight - menuBarHeightWithFormTopMargin
      }
      return height
    },

    itemToolbarHeight () {
      return this.$vuetify.breakpoint.xsOnly || this.splitMode === 'horizontal'
        ? 64 // On mobile or horizontal split mode
        : 64 + 36
      // Now showing item tabs behind a menu btn
      // and state label on the first row
      // so height is always the same
      // return !this.suppressToolbar && // Toolbar is not hidden
      //   this.showItemTabsOnSeparateToolbar(this.itemLayoutProfilesByModel[this.resource]) &&
      //   this.splitMode !== 'horizontal'
      //   ? 64
      //   : 64
    },

    // Combine LP items and extra form items
    fields (): FormItem[] {
      if (!this.layoutProfileItemsById[this.selectedItemLayoutProfileId]) {
        return []
      }
      return [
        ...this.layoutProfileItemsById[this.selectedItemLayoutProfileId].map(i => ({
          ...i,
          isLPI: true,
        })),
        ...this.layoutProfile.timelineConfiguration.extraFormItems
      ]
        .map((field: LPI, index: number) => {
          const data = {
            fieldIndex: index,
            ...field,
          }
          // IMPORTANT!
          // Add amc to LPI, but only those values which do not exist in LPI before, or are empty
          // Example select_columns, widget, etc
          Object.entries((this.amByModel?.[this.resource]?.[field.name] || {})).forEach(([key, am]) => {
            if (typeof am !== 'boolean' && (!(key in data) || !data[key])) {
              data[key] = am
            }
          })
          return data
        })
    },

    fieldsByName () {
      const unitAndCurrencyFieldsByName = {} // When creating save payload need to check if these are writable
      const items = this.fields
        .filter((field: FormItem) => field.isLPI)
        .reduce((byName: Record<string, LPI>, field: LPI) => {
          if (field.multi_language) {
            this.availableContentLocales.forEach(availableLocale => {
              byName[field.name + '_' + availableLocale] = field
            })
          } else {
            byName[field.name] = field
          }
          if (['price', 'quantity'].includes(field.type)) {
            const prefix = field.type === 'price' ? '_currency' : '_unit'
            unitAndCurrencyFieldsByName[field.name + prefix] = field
          }
          return byName
        }, {})
      return { ...items, ...unitAndCurrencyFieldsByName }
    },

    fieldsByNameNotLocalized (): Record<string, LPI> {
      const byName = {}
      this.fields.filter((field: FormItem) => field.isLPI).forEach((field: LPI) => {
        byName[field.name] = field
      })
      return byName
    },

    selectedItemLayoutProfileId (): number {
      // When specific layout profile context is defined, find and use it
      // Currently only "dialog" option is used when opening has-many item
      // modal edit form in pre-defined layout
      let contextLayoutProfileId = null
      if (this.modalProps?.layoutProfileContext) {
        const contextLayoutProfiles = (this.$store.state.itemLayoutProfilesByModel[this.modalProps.resource] || [])
          .filter(item => {
            return item.context === this.modalProps.layoutProfileContext
          })
        contextLayoutProfileId = contextLayoutProfiles[0].id
      }
      // For modal view layoutProfileId can be passed as an argument
      return contextLayoutProfileId || this.selectedLayoutProfileIdByModel[this.resource]
    },

    // First a request to change LP is set
    // When all has-many items are saved to the server (through dummy DFC)
    // Then do the actual LP change
    waitingItemLayoutProfileChangeId () {
      return this.waitingItemLayoutProfileChangeByModel[this.resource]
    },

    virtualContainers (): LP.Container[] {
      const containers: LP.Container[] = []
      let lastGroupName: any = null
      // In case of fields are assigned to field set but field sets are deleted
      // have to check that something is shown for the user.
      // If fieldsCountWithoutFieldSet = 0 then show all visible fields in virtual containers
      const fieldsCountWithoutFieldSet = this.fields.filter((field: LP.Item) => {
        return field.visible && !field.layout_container_field_set
      }).length
      this.fields.filter((field: LP.Item) => {
        return field.visible && (!field.layout_container_field_set || fieldsCountWithoutFieldSet === 0)
      })
        .forEach((field: LP.Item) => {
          let isNewContainer = false
          const lastContainerIndex = containers.length === 0 ? 0 : containers.length - 1
          if (field.attribute_group_name) {
            const match = containers.find(c => c.name === field.attribute_group_name)
            if (!match) {
              isNewContainer = true
              lastGroupName = field.attribute_group_name
              field.containerIndex = containers.length
            } else {
              field.containerIndex = match.index
            }
          } else {
            if (field.attribute_group_name !== lastGroupName) {
              isNewContainer = true
              lastGroupName = field.attribute_group_name
            }
            field.containerIndex = lastContainerIndex
          }
          if (isNewContainer) {
            const container: LP.Container = {
              virtual: true,
              id: containers.length + 1,
              index: containers.length,
              name: field.attribute_group_name,
              columns: 12,
              sort_order: containers.length,
              fieldSets: [],
              showInfoText: false,
              infoTextValues: {},
            }
            // Set container names from translation
            this.availableContentLocales.forEach(locale => {
              container['name_' + locale] = this.translationsByLocale[locale]?.[this.resource]?.attribute_groups?.[field.attribute_group_name]
            })
            containers.push(container)
          }
          field.fieldSetIndex = 0
        })
      return containers
    },

    firstFreeTextInputField () {
      const matchFieldTypes: FieldType[] = [
        'text',
        'richtext',
        'string',
        'search_string',
        'quantity',
        'price',
        'decimal',
        'numeric',
        'raw',
        'date',
        'datetime',
        'reference',
      ]
      const textFields = this.formFields
        .filter(field => matchFieldTypes.includes(field.type) && !field.dynamic)
      return textFields?.[0]
    },

    useCacheForLPData () {
      return this.resource !== 'people'
    },
  },

  watch: {
    waitingItemLayoutProfileChangeId () {
      this.handleWaitingItemLayoutProfileChange()
    },

    selectedItemLayoutProfileId (id, before) {
      if (!before) { return } // Triggered with created ()
      this.showLoaderComputed = true
      this.layoutProfilesItemsLoaded = false
      const itemHasChanged = !((this.item.id === parseInt(this.id)) || (!this.item.id && this.id === 'new'))
      const modelHasChanged = this.resource !== util.objectClassUnderscoredName(this.item['@class'])
      if (itemHasChanged || modelHasChanged) {
        this.clearItemCurrentData()
      }
      this.$nextTick(() => {
        this.getItemLayoutProfileItems({
          id,
          useCache: this.useCacheForLPData,
        }).then(() => {
          this.layoutProfilesItemsLoaded = true
          this.$nextTick(() => {
            if (itemHasChanged || modelHasChanged) {
              this.initializeFormItem()
            } else {
              this.initializeSameItemAfterLayoutProfileChange()
            }
          })
        })
      })
    },

    initializeTrigger () {
      // When LP also changed, showLoaderComputed is set to true before by lp ID watcher
      if (this.showLoaderComputed) { return }
      this.showLoaderComputed = true
      this.clearItemCurrentData()
      this.$nextTick(() => {
        this.initializeFormItem()
      })
    },

    triggerForSavingVirtualContainers (value) {
      if (!value) { return }
      this.saveVirtualContainers().then(() => {
        this.createSortables()
      })
    },

    showLayoutEditorNotAvailableMessage (value) {
      if (value) { return }
      // Designer is now available, create sortables so user can drag fields from the column selector
      this.createSortables()
    }
  },

  created () {
    this.$store.dispatch('getAttributeMetadata', this.resource) // Get into cache, used in DFC check to get field types

    // Important to set loader = true here
    // ItemForm is created again when switching between models and model LPs are not loaded yet
    // and set into selectedLayoutProfileIdByModel
    this.showLoaderComputed = true
    this.clearItemCurrentData()

    // To trigger LP change when item opened from list view
    // when default show/edit item LPs are set for list LP,
    // waitingItemLayoutProfileChangeByModel holds its value
    this.handleWaitingItemLayoutProfileChange()

    this.getItemLayoutProfileItems({
      id: this.selectedItemLayoutProfileId,
      useCache: this.useCacheForLPData,
    }).then(() => {
      this.layoutProfilesItemsLoaded = true
      this.initializeFormItem()
    })
    window.addEventListener('beforeunload', this.confirmWindowCloseForItemPendingSave)
  },

  destroyed () {
    window.removeEventListener('beforeunload', this.confirmWindowCloseForItemPendingSave)
  },

  methods: {
    ...methods,
    ...itemFormMethods,
    ...layoutEditMethods,

    // Change item layout profile when the time is right
    // - save changed has-many items
    // - send action when should, but when DFCs are finished
    handleWaitingItemLayoutProfileChange () {
      const id = this.waitingItemLayoutProfileChangeId
      if (!id) { return }
      this.saveChangedHasManyItemTemporaryObjects()
      this.whenQueueIsEmpty().then(() => {
        Vue.set(this.selectedLayoutProfileIdByModel, this.resource, this.waitingItemLayoutProfileChangeId)
        Vue.set(this.waitingItemLayoutProfileChangeByModel, this.resource, null)
      })
    },

    // Set item modal props, has to be here as there can be multiple forms.
    // Keep this method here as it sets value of itemModalProps which is in the component data block
    showItemModal (props) {
      this.itemModalProps = props
    },
  },
}
</script>

<style lang="scss">
@import "itemForm.style";
@import "itemFormField.style";
</style>
