import axios, { AxiosResponse, AxiosError } from 'axios'
import moment from 'moment'
import util from '@/utilities/sharedUtilities'
import { BaseItem, Types } from '@/types/AppTypes'
import { networkErrorAxiosResponse } from '@/store/api/apiRequests'
import { isObject } from '@/store/actions/appActions'

export default {
  fetchMenuItems (): Promise<AxiosResponse> {
    return this.sendRequest('/gorilla/menu_items', {}, [])
  },

  fetchModelDocumentation (modelName: string, locale: string): Promise<AxiosResponse | null> {
    // My profile special handling - skip
    if (window.location.hash.includes('myProfile')) { return Promise.resolve(null) }
    return this.sendRequest('/gorilla/documentation/' + modelName, {
      locale,
    }, [])
  },

  fetchItemMeta (modelName: string, itemId: string | number): Promise<AxiosResponse> {
    let view = 'show'
    if (window.location.hash.includes('myProfile')) {
      view = 'my_profile'
    }
    return this.sendRequest('/gorilla/' + util.objectClassUnderscoredName(modelName) + '/meta/' + view, {
      _id: itemId,
    }, [])
  },

  fetchItemHistory (model: string, id: string | number): Promise<AxiosResponse> {
    return this.sendRequest('/gorilla/history/' + model + '/' + id, {}, [])
  },

  fetchTranslations (): Promise<AxiosResponse> {
    return this.sendRequest('/gorilla/initializers/translations', {}, [])
  },

  fetchReleaseInfo (page: string): Promise<AxiosResponse> {
    return this.sendRequest('/gorilla/release_info/info', {
      page,
    }, [])
  },

  fetchProcessStyles (): Promise<AxiosResponse> {
    return this.sendRequest('/gorilla/initializers/process_styles', {}, [])
  },

  fetchSettings (): Promise<AxiosResponse> {
    return this.sendRequest('/gorilla/system/settings', [], [])
  },

  fetchGlobalFilters (): Promise<AxiosResponse> {
    return this.sendRequest('/gorilla/global_filters', [], [])
  },

  fetchSystemInfo (): Promise<AxiosResponse> {
    return this.sendRequest('/gorilla/initializers/system_info', [], [])
  },

  fetchUserLevelSettings (): Promise<AxiosResponse> {
    return new Promise(resolve => {
      return this.sendRequest('/gorilla/initializers/user_level_settings', [], []).then((response: AxiosResponse) => {
        if (response.data.data?.linked_associations) {
          return resolve(response)
        }
        this.sendRequest('/gorilla/initializers/linked_associations', [], []).then((response: AxiosResponse) => {
          resolve(response)
        })
      })
    })
  },

  fetchSystemConfigs (): Promise<AxiosResponse> {
    return this.fetchListItems('system_configs', {
      parameter: 'currency+google_maps_api',
      limit: 1000,
    }, [], {})
  },

  fetchAttributeMetadata (resource: string): Promise<AxiosResponse> {
    const queries = [
      '!object_type,attribute_name,attribute_type,dynamic,macro',
      'nestable,multi_language,editable_in_list,writable,association_types,reference_class?',
      'reference_attribute?,amc_options,config_as_json,editable_in_list,ui_widget',
      'visible_on_show',
    ]
    const filters = {
      _object_type: '"=' + resource + '"',
      q: queries.join(',')
    } as any
    filters.limit = 10000
    return this.fetchListItems('attribute_metadatas', filters, [], {})
  },

  fetchTokenFor (
    { objectClass, referenceClass, objectToken, referenceField, queries, copyFromIdOrToken = false, path = '' }: {
      objectClass: string,
      referenceClass: string,
      objectToken: string | number | undefined,
      referenceField: string,
      queries: string[],
      copyFromIdOrToken?: boolean,
      path?: string
    }): Promise<AxiosResponse> {
    return new Promise((resolve) => {
      let queriesString = queries.map(query => 'q[]=' + query).join('&')
      if (copyFromIdOrToken) {
        queriesString += '&~copy_from=' + copyFromIdOrToken
      }
      axios.get(
        '/api/' + referenceClass + '/new/for/' + objectClass + '/' +
        objectToken + '/' + referenceField + '?' + path + queriesString,
        this.requestOptions()).then((response) => {
        resolve(response)
      }).catch((error: AxiosError) => {
        resolve(error.response || networkErrorAxiosResponse(error))
      })
    })
  },

  fetchToken (objectClass: string, item: Types.Item, queries: string[]): Promise<string | AxiosResponse | undefined> {
    return new Promise((resolve) => {
      if (item.token) {
        // Token has already been requested for the item
        resolve(item.token)
      } else {
        queries.push('editable')
        axios.get('/api/' + objectClass + '/new?' +
          queries.map(query => 'q[]=' + query).join('&'), this.requestOptions()
        ).then((response: AxiosResponse) => {
          if (response.data) {
            resolve(response.data.item && response.data.item.token)
          }
          resolve('')
        }).catch((err: AxiosError) => {
          resolve(err.response)
        })
      }
    })
  },

  fetchListItems (
    objectClass: string,
    filters: any,
    queries: string[],
    {
      count = false,
      forClass = '',
      forId = '',
      forField = '',
      parentHasManyParentField = null,
      parentHasManyParentItem = null,
    }: {
      count?: boolean,
      forClass?: string,
      forId?: string | number,
      forField?: string,
      // To generate ~path when requesting has-many items inside parent has-many
      // Need to set path for the main form item
      // Currently only usage is simple multiselect has-many component
      parentHasManyParentField?: BaseItem | null
      parentHasManyParentItem?: BaseItem | null,
    }): Promise<AxiosResponse> {
    let path = ''
    if (parentHasManyParentField && parentHasManyParentItem) {
      path = '~path=' + parentHasManyParentItem['@class'] +
          ':' + (parentHasManyParentItem.token || parentHasManyParentItem.id) + '%20' + parentHasManyParentField.name + '&'
    }
    let url = '/api/' + objectClass + '/'
    if (forId && forClass && forField) {
      // TODO 2024 - This existing now fixed? Or can be always 'temp'
      // https://chat.aavaohjelmistot.com/aava0/pl/aud5hsas8ffn9yeuierwexf8zo

      // Existing note: option :existing, nil, 'Return strategy for items (true = existing in db, :temp = existing in temp, nil = all available)'
      const existing = parentHasManyParentField
        ? 'true' // Has-many items on a form
        : 'temp' // Has-many items (in a new window feature) in a list view
      if (isObject(filters)) {
        filters.existing = existing
      } else {
        filters.push({
          key: 'existing',
          value: existing,
        })
      }
      url = '/api/' + objectClass + '/for/' + forClass +
        '/' + forId + '/' + forField
    }
    if (count) {
      url += '/count'
    }
    return this.sendRequest(url, filters, queries, path)
  },

  fetchItemInfo (objectClass: string, itemId: string | number, queries: string[] | string[][]): Promise<AxiosResponse> {
    return this.sendRequest('/api/' + objectClass + '/' + itemId, [], queries)
  },

  fetchItemInfoForEdit (objectClass: string, itemId: string | number, queries: string[], {
    targetResource, targetId, targetField, fromId,
  }: {
    targetResource?: string | undefined,
    targetId?: string | number | undefined,
    targetField?: string | undefined,
    fromId?: string | number | null,
  }): Promise<AxiosResponse> {
    const filters = {} as any
    if (itemId !== 'new') {
      filters.edit = true
    }
    let url = '/api/' + objectClass + '/' + itemId
    // TODO - delete and refactor calls if tested that below is not needed
    // 17.02.21 If below code is active it causes this issue
    // Open list view from has-many-list from parent item, these 3 props below are present
    // and even if @edit=true in api call, no token is returned, that causes defaults-for-change not to work
    // on edit form.
    // Looks like code block below is not used in any cases, just in case monitor for a while...
    // UPDATE: it was likely added to auto fill parent reference field when creating new item from the list
    // But this is not an important feature (didn't work like this before also)
    // UPDATE 21-MAR-22 Must activate code below for adding a new item from parent has-many list "add new" button
    // Can't replicate issue reported above a year ago
    if (targetResource && targetId && targetField) {
      url = url + '/for/' + util.objectClassUnderscoredName(targetResource) + '/' + targetId + '/' + targetField
    } else if (fromId) {
      // Copy from feature
      url = '/gorilla/' + objectClass + '/new/from/' + fromId
    }
    return this.sendRequest(url, filters, queries)
  },

  // searchOptionsByName (
  //   searchString: string,
  //   objectClass: string,
  //   moduleClass: string,
  //   tokenOrId: string | number,
  //   fieldName: string
  // ) {
  //   const queries = [
  //     ['summary']
  //   ]
  //   const filters = {} as any
  //   if (searchString) {
  //     filters._summary = searchString
  //   }
  //   return this.sendRequest(
  //     '/api/' + objectClass + '/for/' +
  //     moduleClass + '/' + tokenOrId + '/' +
  //     fieldName, filters, queries
  //   )
  // },

  fetchMsgJson (): Promise<AxiosResponse> {
    return this.sendRequest('/msg.json', [], [`_=${moment().valueOf()}`])
  },
}
