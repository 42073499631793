import { AxiosResponse } from 'axios'

export default {
  getSessionAndAppSupportingData () {
    return new Promise(resolve => {
      if (!this.$vuetify.breakpoint.xsOnly) {
        this.showMenu = true
      }
      this.$store.dispatch('trySessionRestore').then((response: AxiosResponse) => {
        if (!response) {
          // TODO - don't send to login portal if request was error
          // For testing add some query parameters which are not supported
          if (this.isLocalhostDomain) {
            this.showLoginDialog = true
          } else {
            this.$store.dispatch('login')
          }
          resolve(null)
        } else {
          const userLang = response.data.item?.language || response.data.item?.person?.language
          this.setUserLocale(userLang)
          this.$store.dispatch('loadSupportingData').then(() => {
            // Need to wait for translations before creating shortcut link values
            this.$store.dispatch('loadAndPrepareDashboardContent')
            // Set user locale again, when we have updated available locales list from translations service
            this.setUserLocale(userLang)
            resolve(null)
          })
        }
      })
    })
  },

  setUserLocale (userLang) {
    const locale = this.$store.state.availableAppLocales.includes(userLang?.identifier) ? userLang?.identifier : 'en'
    this.$i18n.locale = this.locale = locale
  },

  showAppNewVersionMessage () {
    this.$store.dispatch('addFlashMessage', {
      message: 'New version is available. Reloading now ...', // TODO - translate
      type: 'success',
      expires: 5000,
      hideCloseButton: true,
    })
  },

  refreshAppForNewVersion () {
    location.reload()
  },

  resizeHandler () {
    clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(() => {
      this.innerHeight = window.innerHeight
      this.innerWidth = window.innerWidth
      this.resizeTrigger++
    }, 300)
    if (!this.$vuetify.breakpoint.xsOnly) {
      this.showMenu = true
    }
  },

  toggleHorizontalSplitMode () {
    this.$store.state.splitMode = this.$store.state.splitMode !== 'horizontal'
      ? 'horizontal'
      : ''
    this.$store.state.splitModeAltered = true // To trigger events related with split mode change
    this.setNoSplitView()
  },

  toggleVerticalSplitMode () {
    this.$store.state.splitMode = this.$store.state.splitMode !== 'vertical'
      ? 'vertical'
      : ''
    this.$store.state.splitModeAltered = true // To trigger events related with split mode change
    this.setNoSplitView()
  },

  setNoSplitView () {
    if (this.$store.state.splitMode) { return }
    if (this.$route.path !== '/' + this.$store.state.objectClass) {
      this.$router.push({ path: '/' + this.$store.state.objectClass })
    }
  },

  clearCacheAndReload () {
    this.clearCache()
    location.reload()
  },

  clearCache () {
    localStorage.clear()
  },
}
