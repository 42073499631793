import appActions from './actions/appActions'
import sessionActions from './actions/sessionActions'

import listItemActions from '@/store/actions/listItem/listItemActions'

import listActions from './actions/list/listActions'
import listSelectItemsActions from './actions/list/listSelectItemsActions'
import listVisibleItemsActions from './actions/list/listVisibleItemsActions'
import listRenderPoolActions from './actions/list/listRenderPoolActions'
import listLoadItemsActions from './actions/list/listLoadItemsActions'

import timelineActions from './actions/alternativeViews/timelineActions'
import breakdownActions from './actions/alternativeViews/breakdownActions'
import pivotTableActions from './actions/alternativeViews/pivotTableActions'
import cardActions from './actions/alternativeViews/cardActions'
import noSeActions from './actions/noSe/noSeActions'
import dashboardActions from '@/store/dashboardItems/actions'

import listViewAPI from './api'

import LCActions from '@/models/LayoutContainer/LC.actions'
import LCFSActions from '@/models/LayoutContainerFieldSet/LCFS.actions'
import LPIActions from '@/models/LayoutProfileItem/LPI.actions'
import LPIListActions from '@/models/LayoutProfileItem/LPI.list.actions'

import util from '../utilities/sharedUtilities'
import Vue from 'vue'
import fileActions from '@/store/actions/fileActions'
import localStorageTtl from '@/utilities/localStorageTtl'
import LPActions from '@/models/LayoutProfile/LP.actions'
import itemPickerActions from '@/components/ItemPicker/itemPicker.actions'
import { LP } from '@/types/LP.types'
import { AxiosResponse } from 'axios'
import { ItemsResponse } from '@/types/API.responses'

export default {
  ...appActions,
  ...sessionActions,
  ...fileActions,
  ...listItemActions,

  ...listActions,
  ...listSelectItemsActions,
  ...listVisibleItemsActions,
  ...listRenderPoolActions,
  ...listLoadItemsActions,

  ...timelineActions,
  ...breakdownActions,
  ...pivotTableActions,
  ...cardActions,
  ...noSeActions,

  ...dashboardActions,
  ...LPIActions,
  ...LCActions,
  ...LCFSActions,
  ...LPIListActions,
  ...LPActions,
  ...itemPickerActions,

  waitAndReturnAttributeMetadataWhenLoaded: ({ state, dispatch }, { resource, tryCount = 0 }) => {
    tryCount++
    return new Promise(resolve => {
      if (tryCount > 100) { // Must have some limit, for how long to wait
        resolve(false)
      } else if (state.amByModel[resource]) {
        // Return from local vuex store
        resolve(state.amByModel[resource])
      } else {
        setTimeout(() => {
          resolve(dispatch('waitAndReturnAttributeMetadataWhenLoaded', { resource, tryCount }))
        }, 100)
      }
    })
  },

  getAttributeMetadata: ({ state, dispatch }, resource: string): Promise<LP.AMC> => {
    resource = util.objectClassUnderscoredName(resource)
    return new Promise(resolve => {
      const fromCache = localStorageTtl.get('amc_' + resource)
      if (state.amByModel[resource]) {
        // Return from local vuex store
        return resolve(state.amByModel[resource])
      } else if (fromCache && Object.keys(fromCache).length) {
        // Return from local storage
        Vue.set(state.amByModel, resource, fromCache)
        return resolve(fromCache)
      } else {
        // Attribute metadata for same resource is currently being retrieved, do not send uplicate request
        if (state.modelsRetrievingAttributeMetadataNow.includes(resource)) {
          return resolve(dispatch('waitAndReturnAttributeMetadataWhenLoaded', { resource }))
        } else {
          // For the first one
          state.modelsRetrievingAttributeMetadataNow.push(resource)
        }
      }
      if (resource === 'Any') {
        resolve({})
        return
      }
      const modelName = util.objectClassSingularName(resource)
      listViewAPI.fetchAttributeMetadata(modelName).then((response: AxiosResponse<ItemsResponse>) => {
        dispatch('globalErrorDisplay', { response, context: 'Get attribute meta data ' + modelName })
        if (response.data.items) {
          const attributesByKey = {}
          response.data.items.forEach(attribute => {
            if (attribute.object_type !== modelName) { return }
            // Set static_list options as "items"
            // In case of has-many > static_list attribute AND when Layout Editor is used
            // UI does not have options to show for the user. JUN2023 "items" as added under amc_options
            if (attribute?.amc_options?.items) {
              attribute.items = attribute.amc_options.items
            }
            attribute.name = attribute.attribute_name
            attribute.type = attribute.attribute_type

            attribute.widget = attribute.ui_widget || // Set in modelling UI and get from AM
                attribute.config_as_json?.widget || // Set in config
                attribute.widget || // ? Is it ever used for AM? May only be for LPI
                attribute.amc_options.widget // Set in config as widget_type
            attribute.widgetConfig = attribute.config_as_json?.widget_config || {}

            attributesByKey[attribute.attribute_name] = attribute
          })
          resolve(attributesByKey)
          Vue.set(state.amByModel, resource, attributesByKey)
          // Set to local storage for 4 hours
          localStorageTtl.set('amc_' + resource, attributesByKey, 1000 * 60 * 60 * 4)
        }
        resolve({})
      })
    })
  },

  resetItemPickerData: ({ state, dispatch }, forField) => {
    dispatch('closeItemPicker')
    return new Promise(resolve => {
      state.itemPickerResetTimer = setTimeout(() => {
        if (state.itemPickerProps?.forFieldName !== forField) {
          return resolve(false)
        }
        state.itemPickerSelectedOption = {}
        // Can not set search term, it was just set from reference input
        // state.itemPickerSearchTerm = ''
        state.showItemPicker = false
        state.itemPickerSelectCallback = () => {}
        state.itemPickerUnselectCallback = () => {}
        // state.hasManyComponentForItemPicker = null
        state.itemPickerProps = null
        resolve(true)
      }, 10) // Get selected option before item picker is destroyed
    })
  },

  closeItemPicker: ({ state }) => {
    if (state.itemPickerProps?.closeCallback) {
      state.itemPickerProps.closeCallback()
    }
    state.showItemPicker = false
  }
}
