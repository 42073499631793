<template>
  <v-container
    class="elevation-0"
    max-width="800"
  >
    <!-- dummy input to prevent browser focusing global search field when auto populating user+pass -->
    <div style="position: absolute; top: -10000px;">
      <input
        type="text"
        name="dummy"
      >
    </div>
    <v-row class="py-4">
      <v-col cols="2">
        <v-icon
          small
          class="mx-5"
        >
          fa-key
        </v-icon>
      </v-col>
      <v-col>
        <h3>{{ $i18n.t('aava.change_password.title') }}</h3>
      </v-col>
    </v-row>
    <v-row
      v-if="!message || message.type !== 'success'"
      dense
    >
      <v-col cols="12">
        <v-text-field
          v-model="current_password"
          :label="$i18n.t('aava.change_password.old_password')"
          type="password"
          autofocus
          hide-details
          dense
          outlined
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="password"
          :label="$i18n.t('aava.change_password.new_password')"
          type="password"
          dense
          outlined
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="password_confirmation"
          :label="$i18n.t('aava.change_password.new_password_again')"
          :clearable="false"
          type="password"
          dense
          outlined
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="!message || message.type !== 'success'"
        cols="12"
      >
        <v-btn
          :dark="!disabled"
          :disabled="disabled"
          :loading="saving"
          color="green darken-3"
          class="elevation-0"
          block
          @click="changePassword"
        >
          {{ $i18n.t('aava.change_password.submit') }}
        </v-btn>
      </v-col>
      <v-col v-if="message">
        <v-alert
          :color="message.type"
          border="top"
          dark
        >
          {{ message.message }}
        </v-alert>
      </v-col>
      <v-col
        cols="12"
        class="text-center"
      >
        <v-btn
          color="#bbb"
          text
          @click="showUserPasswordForm = false"
        >
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import api from '@/store/api'
import { AxiosResponse } from 'axios'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'ChangePassword',

  data () {
    return {
      saving: false,
      current_password: '',
      password: '',
      password_confirmation: '',
      message: null,
      textFieldAttributes: {
        outlined: true,
        color: '#666',
        rounded: true,
        hideDetails: true,
        dense: false,
        clearable: false,
      },
    }
  },

  computed: {
    // Use commonStateVarsUsed
    ...mapFields(Object.keys(state)),

    disabled () {
      return !(this.current_password && this.password && this.password_confirmation)
    },
  },

  methods: {
    changePassword () {
      this.saving = true
      api.changePassword(this.current_password, this.password, this.password_confirmation).then((response: AxiosResponse) => {
        if (response.data.status === 'ok' && response.data.message) {
          this.message = {
            message: this.$i18n.t(response.data.message.replace('aava:', 'aava.')),
            type: 'success',
          }
        } else {
          this.message = {
            message: this.$i18n.t(response.data?.messages?.[0].key.replace('aava:', 'aava.')) || 'Error',
            type: 'error',
          }
        }
        this.saving = false
      })
    },
  },
}
</script>
