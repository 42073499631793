import util from '@/utilities/sharedUtilities'
import listViewAPI from '@/store/api'
import { LPI } from '@/types/LP.types'
import { BaseItem } from '@/types/AppTypes'

export interface LoadPickerOptionsParams {
  className: string
  fieldName: string
  forField: LPI
  forClass: string
  pathField: string
  referenceFieldName: string
  referenceFieldSearchTerm: string
  selectColumns: string[]
  filterValues: Record<string, any>
  offset: number
  forItem: BaseItem
}

export default {
  loadItemPickerOptions: ({ state, dispatch }, params: LoadPickerOptionsParams) => {
    console.log('params::', params)
    const {
      className,
      fieldName,
      forField,
      forClass,
      pathField,
      referenceFieldName,
      referenceFieldSearchTerm,
      selectColumns,
      filterValues,
      offset,
      forItem,
    } = params
    return new Promise(resolve => {
      const filters: Record<string, any> = {}
      const forId = (forItem && (forItem.token || forItem.id)) || null
      const queries = [referenceFieldName]
      // Apply user search terms
      if (referenceFieldName && referenceFieldSearchTerm && !filterValues[referenceFieldName]) {
        filters['_' + referenceFieldName] = referenceFieldSearchTerm
      }
      if (offset) { // User scrolls down to load additional  options
        filters.offset = offset
      }
      // Apply MULTISELECT picker sorting
      // TODO 2024 should be multiselect_picker_sort_attribute instead
      // TODO Remove when smoke models are in Oma
      if (forField.widgetConfig?.multiselect_picker_sort_condition) {
        filters.order = (forField.widgetConfig.multiselect_parent_attribute
          ? forField.widgetConfig.multiselect_parent_attribute +
                ' ' : '') +
            forField.widgetConfig.multiselect_picker_sort_condition
      }

      if (forField.widgetConfig?.multiselect_picker_sort_attribute) {
        filters.order = (forField.widgetConfig.multiselect_parent_attribute
          ? forField.widgetConfig.multiselect_parent_attribute +
                ' ' : '') +
            forField.widgetConfig.multiselect_picker_sort_attribute
      }
      dispatch('buildItemPickerQueries', { className, forField, fieldName, selectColumns })
        .then((response: any) => {
          const { itemPickerQueries, selectColumnsAm } = response
          queries.push(...itemPickerQueries)
          // Apply user search terms for individual fields
          selectColumns.forEach(selectColumn => {
            const fieldInfo = state.amByModel[util.objectClassUnderscoredName(className)]?.[selectColumn]
            if (filterValues[selectColumn]) {
              const key = '_' + selectColumn + (fieldInfo?.multi_language ? '_' + state.locale : '')
              filters[key] = filterValues[selectColumn]
            }
          })
          let path = ''
          let formData = {}
          if (forItem?.targetObject) {
            // Path is needed for the api when requesting options for item reference field
            // opened on parent item form
            // Example: Country for address which is on parent item form
            // Example: Reference field in has-many editable list on parent item edit form
            path = '~path=' + forItem.targetObject['@class'] +
              ':' + (forItem.targetObject.token || forItem.targetObject.id) + '%20' + (pathField || forClass) + '&'
            formData = { targetObject: forItem.targetObject }
          }
          let url
          if (forId && className && forClass) {
            url = url = '/api/' + util.objectClassUnderscoredName(className) + '/for/' +
              forClass + '/' + (forId || '+') + '/' + fieldName
            // Add queries and filters
            url += '?' + path + queries.map(query => 'q[]=' + query).join('&') + '&' +
              Object.keys(filters).map(key => key + '=' + filters[key]).join('&')
            listViewAPI.sendPostRequestFormData(url, formData).then(apiResponse => {
              dispatch('globalErrorDisplay', { response: apiResponse, context: 'Get item picker options with POST ' + url })
              resolve({ apiResponse, selectColumnsAm })
            })
          } else if (className) {
            // Without parent - simple GET request, instead of POST
            url = '/api/' + util.objectClassUnderscoredName(className)
            listViewAPI.sendRequest(url, filters, queries).then(apiResponse => {
              dispatch('globalErrorDisplay', { response: apiResponse, context: 'Get item picker options with GET ' + url })
              resolve({ apiResponse, selectColumnsAm })
            })
          }
        })
    })
  },

  // Get queries (fields) to show in multi edit form for a reference dropdown
  buildItemPickerQueries: ({ dispatch }, { className, forField, fieldName, selectColumns }) => {
    const queryColumns = [...selectColumns]
    // Add simple multiselect display column
    const simpleMultiselectDisplayColumn = forField.widgetConfig?.multiselect_input_display_attribute
    if (simpleMultiselectDisplayColumn && !queryColumns.includes(simpleMultiselectDisplayColumn)) {
      queryColumns.push(simpleMultiselectDisplayColumn)
    }

    return new Promise(resolve => {
      const itemPickerQueries: string[] = []
      if (!className || !fieldName || !queryColumns) { resolve({ itemPickerQueries }) }
      let classSingular = util.objectClassSingularName(className)
      // In case of Any reference, use first association class
      if (classSingular === 'Any') {
        // This is Any type, so for picker columns query build, first association class is used. Problem is, that in case of layout-profile-items, association_classes attribute holds this information.
        // But in case of attribute_metadata, association_types has it.
        classSingular = forField.association_classes?.[0] || forField.association_types?.[0] || 'Any'
      }
      let columnQueries
      dispatch('getAttributeMetadata', classSingular).then(cache => {
        queryColumns.forEach(selectColumn => {
          const fieldType = cache?.[selectColumn]?.attribute_type
          switch (fieldType) {
            case 'reference':
            case 'has_many_reference':
            case 'polymorphic_autocomplete':
              columnQueries = cache?.[selectColumn]?.amcOptions?.select_columns ||
                cache?.[selectColumn]?.reference_attribute || 'summary'
              itemPickerQueries.push(selectColumn + ' ' + columnQueries)
              itemPickerQueries.push(selectColumn + ' main_object_state identifier,name,background_color')
              break
            case 'state':
              itemPickerQueries.push(selectColumn)
              itemPickerQueries.push(selectColumn + ' identifier,summary,background_color')
              break
            case 'price':
              itemPickerQueries.push(selectColumn)
              itemPickerQueries.push(selectColumn + '_currency identifier')
              break
            case 'quantity':
              itemPickerQueries.push(selectColumn)
              itemPickerQueries.push(selectColumn + '_unit name')
              break
            default:
              itemPickerQueries.push(selectColumn)
          }
        })
        resolve({ itemPickerQueries, selectColumnsAm: cache })
      })
    })
  },
}
