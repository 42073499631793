<template>
  <div
    v-tooltip.top="tooltip"
    :style="fieldStyle"
    :class="'item-show-field ' + (maxOneRowHeight ? 'single-row' : '')"
  >
    <div
      :class="'item-show-label ' + (!!displayValue ? ' item-show-label-filled-todo-to-be-decided' : '')"
    >
      <span>{{ label }}</span>
    </div>
    <component
      :is="hasManyItemLink ? 'router-link' : 'div'"
      ref="viewText"
      :to="hasManyItemLink"
      tabindex="-1"
      :class="field.type + '-type item-show-value'"
      v-html="typeof displayValue === 'string'
        ? (displayValue.trim() || '&nbsp;')
        : displayValue"
    />
    <div
      v-if="contentIsOverflowing"
      :style="{
        position: 'absolute',
        right: '8px',
        top: '8px',
      }"
    >
      <v-icon
        x-small
        @click="maxOneRowHeight = !maxOneRowHeight"
      >
        fa-chevron-{{ maxOneRowHeight ? 'down' : 'up' }}
      </v-icon>
    </div>
  </div>
</template>

<script>
import listItemCellMethods from '@/methods/listItem/listItemCellMethods'

export default {
  name: 'ShowViewText',

  data () {
    return {
      contentIsOverflowing: false,
      maxOneRowHeight: false,
    }
  },

  props: {
    displayValue: {
      type: [String, Number, Object, Array, Boolean],
      default: '',
    },
    isHasManyField: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    fieldStyle: {
      type: String,
      default: '',
    },
    rawValue: {
      type: [String, Number, Object, Array, Boolean],
      default: '',
    },
    field: {
      type: Object,
      default: () => {},
    },
    hasManyItemLink: {
      type: String,
      default: '',
    },
  },

  computed: {
    tooltip () {
      if (this.field.type !== 'decimal') { return }
      return this.formatFloatValueForLocale(this.rawValue, null, true)
    },
  },

  created () {
    this.maxOneRowHeight = !!this.field.config_as_json?.one_row_in_has_many_show_list && this.isHasManyField
    setTimeout(() => {
      this.checkContentOverflowing()
    }, 300)
  },

  methods: {
    ...listItemCellMethods,

    checkContentOverflowing () {
      // Skip when showing full height
      if (!this.maxOneRowHeight) { return }

      // Measure if content takes more than one row
      this.$nextTick(() => {
        const el = this.$refs.viewText?.$el
        if (!el) { return }
        this.contentIsOverflowing = el.offsetHeight < el.scrollHeight - 10
      })
    },
  },
}
</script>

<style>
.single-row {
  max-height: 34px;
}
</style>
