<template>
  <div ref="container">
    <template v-if="!edit && value">
      <div class="item-show-field">
        <div class="item-show-label">
          <span>{{ label }}</span>
        </div>
        <div
          v-if="value"
          class="item-show-value address-widget-value"
        >
          <v-icon
            v-if="value && value.summary"
            small
            @click="showAddressOnMap"
          >
            fa-map-marker-alt
          </v-icon>
          <div v-html="referenceLabel(value, field)" />
        </div>
      </div>
    </template>
    <v-container
      v-else-if="value"
      fluid
      class="pa-0"
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          class="mb-2"
        >
          <v-text-field
            v-model="street1Computed"
            :label="label"
            :persistent-placeholder="true"
            :disabled="readOnly"
            spellcheck="false"
            color="orange"
            outlined
            dense
            hide-details
            @focus="focus"
            @keydown="focused = true"
          />
        </v-col>
        <v-col
          v-if="!onSingleField && ((focused && value.street_1) || value.street_2)"
          cols="12"
          class="mb-2"
        >
          <v-text-field
            v-model="value.street_2"
            :disabled="readOnly"
            spellcheck="false"
            outlined
            dense
            hide-details
            color="orange"
            @focus="focused = true"
          />
        </v-col>
        <v-col
          v-if="!onSingleField && ((focused && value.street_2) || value.street_3)"
          cols="12"
          class="mb-2"
        >
          <v-text-field
            v-model="value.street_3"
            :disabled="readOnly"
            spellcheck="false"
            outlined
            dense
            hide-details
            color="orange"
            @focus="focused = true"
          />
        </v-col>
        <v-col
          v-if="!onSingleField && ((focused && value.street_3) || value.street_4)"
          cols="12"
          class="mb-2"
        >
          <v-text-field
            v-model="value.street_4"
            :disabled="readOnly"
            spellcheck="false"
            outlined
            dense
            hide-details
            color="orange"
          />
        </v-col>
        <v-col
          v-if="!onSingleField"
          :class="!$vuetify.breakpoint.mdAndUp ? 'mb-2' : 'pr-1'"
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="value.postal_code_string"
            :label="$i18n.t('addresses.attributes.postal_code_string')"
            :persistent-placeholder="true"
            :disabled="readOnly"
            spellcheck="false"
            outlined
            dense
            hide-details
            color="orange"
          />
        </v-col>
        <v-col
          v-if="!onSingleField"
          :class="!$vuetify.breakpoint.mdAndUp ? 'mb-2' : 'pr-1'"
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="value.postal_office_string"
            :label="$i18n.t('addresses.attributes.postal_office_string')"
            :persistent-placeholder="true"
            :disabled="readOnly"
            spellcheck="false"
            outlined
            dense
            hide-details
            color="orange"
          />
        </v-col>
        <v-col
          v-if="!onSingleField"
          :class="!$vuetify.breakpoint.mdAndUp ? 'mb-2' : ''"
          cols="12"
          md="4"
        >
          <ReferenceField
            v-model="value.country"
            :label="$i18n.t('addresses.attributes.country')"
            :show-item-picker-for="showItemPickerFor"
            :item="valueComputedForAddressCountry"
            :field="{
              name: 'country',
              reference_class: 'countries',
              pathField: isHasManyField ? null : field.name,
            }"
            :has-many-parent-field__="hasManyParentField"
            :layout-edit-mode="layoutEditMode"
            :show-item-modal="showItemModal"
            :modal="true"
            :read-only="readOnly"
            :resource="isHasManyField ? '' : addresses"
            @changeListener="changeListener"
          />
        </v-col>
      </v-row>
    </v-container>
    <div
      v-else
      class="item-show-field"
    >
      <div class="item-show-label">
        <span>{{ label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import listItemMethods from '@/methods/listItem/listItemMethods'
import ReferenceField from '@/components/Form/ReferenceField'

export default {
  name: 'AddressField',

  components: {
    ReferenceField,
  },

  props: {
    value: {
      type: Object,
      default: () => { return {} },
    },
    label: {
      type: String,
      default: null,
    },
    field: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
    resource: {
      type: String,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    layoutEditMode: {
      type: Boolean,
      default: false,
    },
    showItemPickerFor: {
      type: Function,
      default: () => {},
    },
    hasManyParentField: {
      type: Object,
      default: () => {},
    },
    showItemModal: {
      type: Function,
      default: () => {},
    },
    isHasManyField: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      focused: false,
    }
  },

  computed: {
    onSingleField () {
      return !this.focused
    },

    // When currently not editing the address, show only on single field
    // with combined address field values, separated by comma
    street1Computed: {
      get () {
        if (this.focused) {
          return this.value.street_1
        }
        const filledAddressParts = [
          'street_1',
          'street_2',
          'postal_code_string',
          'postal_office_string',
        ].map(attr => {
          return this.value[attr]
        }).filter(attr => !!attr)
        if (this.value.country?.summary) {
          filledAddressParts.push(this.value.country?.summary)
        }
        return filledAddressParts.join(', ')
      },
      set (value) {
        this.value.street_1 = value
      },
    },

    valueComputedForAddressCountry () {
      if (!this.value) { return }
      const value = JSON.parse(JSON.stringify(this.value))
      // Set target object, but only if not has-many field
      // Address inside has-many causes errors and is not supported by the back-end api,
      // therefore is agreed to select ALL countries in this case
      if (!this.isHasManyField) {
        value.targetObject = this.item
      }
      return value
    },
  },

  created () {
    window.addEventListener('mousedown', this.handleMousedown)
  },

  destroyed () {
    window.removeEventListener('mousedown', this.handleMousedown)
  },

  methods: {
    ...listItemMethods,

    // When clicked outside the component, show address with one field again
    // Mousedown is better here than click, as user may be dragging the content inside the input
    // and release mouse outside this component
    handleMousedown (e) {
      const containerDiv = this.$refs.container
      if (this.$store.state.itemPickerProps?.objectClass) { return }
      if (!containerDiv) { return }
      if (!containerDiv.contains(e.target)) {
        this.focused = false
      }
    },

    focus () {
      this.focused = true
      setTimeout(() => {
        this.$store.dispatch('closeItemPicker')
      }, 100) // Give time for reference field option pick action and destroy
    },

    showAddressOnMap (event) {
      this.$store.dispatch('showAddressModal', {
        address: this.value.summary,
      })
    },

    changeListener () {
      this.$emit('changeListener')
    },
  },
}
</script>

<style lang="scss">
.address-widget-value {
  .fa-map-marker-alt {
    float: left;
    margin: 4px 8px 0 1px;
    cursor: pointer;
  }
}
</style>
