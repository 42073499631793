import { render, staticRenderFns } from "./RestartMessage.vue?vue&type=template&id=45e7ef56&scoped=true"
import script from "./RestartMessage.vue?vue&type=script&lang=js"
export * from "./RestartMessage.vue?vue&type=script&lang=js"
import style0 from "./RestartMessage.vue?vue&type=style&index=0&id=45e7ef56&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45e7ef56",
  null
  
)

export default component.exports