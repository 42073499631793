<template>
  <v-row
    :data-current-index="field.index"
    :class="'field-chip-container ' + (' select-col-' + field.name)"
    :data-parent-id="containerName"
    align="center"
    no-gutters
    wrap
  >
    <v-chip
      class="field-chip text-left mb-1 pr-0"
      :ripple="false"
      :style="chipStyle(field)"
      rounded
      depressed
      block
      @click="$emit('toggleFieldVisibility', field)"
    >
      <v-avatar left>
        <v-icon
          class="available-icon"
          x-small
          color="green"
        >
          fa-plus
        </v-icon>
        <v-icon
          class="selected-icon"
          x-small
          color="red"
        >
          fa-minus
        </v-icon>
      </v-avatar>
      <v-icon
        v-if="containerName === 'selected-columns'"
        :color="!isSupportedListEditField(field) ? '#E0E0E0' : field.editable_in_list ? 'green' : 'grey'"
        class="pa-2 ml-n2"
        x-small
        @click.prevent.stop="isSupportedListEditField(field) ? $emit('listEditableFieldToggleHandler', field) : null"
      >
        fa-edit
      </v-icon>
      <span
        class="select-field-title"
      >
        {{ field.translation }}
      </span>
      <v-btn
        v-if="field.type === 'has_many_reference'"
        :value="field.name"
        class="field-select-btn grey--text text--darken-2"
        color="red"
        :ripple="false"
        height="32"
        small
        rounded
        depressed
        @click.prevent.stop="$emit('hasManyToggleClickHandler', field.name)"
      >
        <v-icon
          class="pr-2"
          x-small
          color="grey lighten-1"
        >
          {{ fieldIcon(field.type) }}
        </v-icon>
        {{ sampleItemFieldValue(field) }} | {{ $i18n.t('aava.show.index').toLowerCase() }}:
        <v-icon
          :color="selectedLayoutProfile.timelineConfiguration.hasManyFieldsWithData.includes(field.name) ? 'green' : 'red'"
          class="pl-1"
          x-small
        >
          {{ selectedLayoutProfile.timelineConfiguration.hasManyFieldsWithData.includes(field.name) ? 'fa-check' : 'fa-minus' }}
        </v-icon>
      </v-btn>
      <v-btn
        v-else
        class="field-select-btn grey--text text--darken-2"
        :color="sampleItemFieldValue(field) ? '' : 'transparent'"
        :ripple="false"
        height="32"
        small
        rounded
        depressed
      >
        <v-icon
          class="pr-2"
          x-small
          color="grey lighten-1"
        >
          {{ fieldIcon(field.type) }}
        </v-icon>
        {{ sampleItemFieldValue(field) }}
      </v-btn>
    </v-chip>
  </v-row>
</template>

<script>
// TODO
// - put added item as last one
// - search clearable?
// -save lp items and sort order
// smaller screen fit. mobile not important?
import sharedUtilities from '@/utilities/sharedUtilities'
import listItemCellMethods from '@/methods/listItem/listItemCellMethods'
import listCellEditMethods from '@/methods/listItem/listCellEditMethods'

export default {
  name: 'ColumnsSelectorField',

  props: {
    field: {
      type: Object,
      default: () => {},
    },
    listFirstItem: {
      type: Object,
      default: () => {},
    },
    sampleItem: {
      type: Object,
      default: () => {},
    },
    searchTerm: {
      type: String,
      default: '',
    },
    containerName: {
      type: String,
      default: '',
    },
  },

  computed: {
    selectedLayoutProfile () {
      return this.$store.state.selectedLayoutProfile
    },
  },

  methods: {
    ...listItemCellMethods,
    ...listCellEditMethods,

    chipStyle (field) {
      return {
        width: '100% !important',
        background: (field.visible ? '#E0E0E0' : ''),
      }
    },

    sampleItemFieldValue (field) {
      let value = this.getItemFieldDisplayText({
        inputItem: this.sampleItem || this.listFirstItem,
        inputField: field,
      })
      if (field.type === 'has_many_reference' && Array.isArray(value)) {
        if (value[0] && value[0].count) {
          value = '(' + value[0].count + ')'
        } else {
          value = '(' + value.length + ')'
        }
      } else if (Array.isArray(value)) {
        value = value[0] && (value[0].summary || value[0]['@class'])
      } else if (typeof value === 'object' && value) {
        value = value.summary || value['@class']
      }
      return value && typeof value === 'string'
        ? sharedUtilities.stripHtml(value).trim()
        : value
    },

    // TODO - duplicate
    fieldIcon (type) {
      switch (type) {
        case 'text':
        case 'string':
        case 'search_string':
          return 'abc'
        case 'numeric':
        case 'quantity':
          return '123'
        case 'datetime':
          return 'fa-calendar'
        case 'boolean':
          return 'fa-check-square'
        case 'reference':
        case 'polymorphic_autocomplete':
          return 'fa-link'
        case 'price':
          return 'fa-euro-sign'
        case 'has_many_reference':
          return 'fa-list-ol'
        default:
          return 'fa-bars'
      }
    },
  },
}
</script>

<style lang="scss">
.field-chip-container {
  .field-chip {
    background: #f5f5f5 !important;
    .v-chip__content {
      width: 100% !important;
    }
    .field-select-btn {
      background: #eee !important;
    }
  }
}
</style>
