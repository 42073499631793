<template>
  <v-row
    class="item-container-info-text"
  >
    <v-col
      cols="12"
    >
      <div>
        <MultiLangTextField
          v-if="edit && container.infoTextValues"
          v-model="container.infoTextValues"
          :field="{ name: 'html', type: 'richtext' }"
          :edit="true"
          :label="$t('aava.layout.form_info_text')"
          @changeListener="changeHandler"
        />
        <div
          v-else-if="container.infoTextValues && container.infoTextValues['html_' + $store.state.locale]"
          v-html="container.infoTextValues['html_' + $store.state.locale]"
        />
      </div>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import { LP } from '@/types/LP.types'
import MultiLangTextField from '@/components/Form/MultiLangTextField.vue'

export default {
  name: 'ContainerInfoText',

  components: {
    MultiLangTextField
  },

  data () {
    return {
      containerInfoTextChangeTimer: null,
    }
  },

  props: {
    container: {
      type: Object as () => LP.Container,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    changeHandler () {
      if (this.containerInfoTextChangeTimer) {
        clearTimeout(this.containerInfoTextChangeTimer)
        this.containerInfoTextChangeTimer = null
      }
      this.containerInfoTextChangeTimer = setTimeout(() => {
        this.containerInfoTextChangeTimer = null
        this.$emit('updateLayoutContainer', this.container)
      }, 3000)
    }
  }
}
</script>
