<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="290"
    overlay-opacity="1"
    content-class="blurred-dialog elevation-2"
  >
    <v-container class="white">
      <v-card class="elevation-0">
        <v-card-title class="text-h6">
          {{ $i18n.t('aava.login.title') }}
        </v-card-title>
        <v-card-text>
          {{ userInfo.id && $i18n.t('aava.messages.session_expired') }}
        </v-card-text>
        <v-container class="pt-2 pb-0 px-4">
          <v-text-field
            v-model="username"
            :label="$i18n.t('aava.login.username')"
            :disabled="enteringTFToken || showError"
            data-test="dev-username"
            persistent-placeholder
            placeholder=" "
            color="orange"
            @keyup.enter="login"
          />
          <v-text-field
            v-model="password"
            :label="$i18n.t('aava.login.password')"
            :disabled="enteringTFToken || showError"
            persistent-placeholder
            placeholder=" "
            type="password"
            color="orange"
            data-test="dev-psw"
            @keyup.enter="login"
          />
          <v-text-field
            v-if="enteringTFToken"
            v-model="tfToken"
            :label="$i18n.t('aava.login.enter_tf_token')"
            autofocus
            type="password"
            color="orange"
            data-test="dev-psw"
            @keyup.enter="login"
          />
        </v-container>
        <v-card-actions>
          <v-btn
            color="grey lighten-1"
            class="no-link-decoration"
            text
            icon
            data-test="dev-login-btn"
            href="/login"
          >
            <v-icon small>
              fa-chevron-left
            </v-icon>
          </v-btn>
          <v-spacer />
          <v-icon
            v-if="showError"
            color="red"
            class="mr-5"
          >
            fa-triangle-exclamation
          </v-icon>
          <v-btn
            v-else
            :loading="loading"
            color="green darken-1"
            text
            data-test="dev-login-btn"
            @click="login"
          >
            {{ $i18n.t('aava.login.log_in') }}
          </v-btn>
          <!-- v-btn
            color="green darken-1"
            data-test="dev-login-btn"
            text
            @click="dialog = false"
          >
            x
          </v-btn -->
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script lang="ts">

/*
* TODO - comment
* */

import api from './../store/api'
import appMethods from '@/methods/list/appMethods'
import infoChannelMethods from '@/methods/list/infoChannelMethods'
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import { AxiosResponse } from 'axios'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'LoginDialog',

  data () {
    return {
      showError: false,
      loading: false,
      dialog: true,
      username: '',
      password: '',
      tfToken: '',
      enteringTFToken: false,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    ...appMethods,
    ...infoChannelMethods,

    login () {
      this.loading = true
      const credentials: any = {
        name: this.username,
        password: this.password,
      }
      if (this.tfToken) {
        credentials.tfToken = this.tfToken
      }
      api.sendLoginRequest(credentials).then((response: AxiosResponse) => {
        this.loading = false
        if (response.data.status === 'tw_token_needed') {
          // Two Factor Token required
          this.enteringTFToken = true
        } else if (response.data.status === 'ok') {
          // Login ok > reload
          this.$store.state.showLoginDialog = false
          if (this.$route.params.itemAction === 'edit') {
            this.$store.dispatch('addFlashMessage', {
              message: this.$i18n.t('aava.messages.form_can_not_be_saved_after_re_login'),
              type: 'warning',
              expires: 5000,
            })
            setTimeout(() => {
              location.reload()
            }, 3000)
          } else {
            // For now always have to reload
            // Ideally user would be able to save item again, but token is no longer valid, after new session
            location.reload()
          }
        } else {
          this.showError = true
          setTimeout(() => {
            this.showError = false
          }, 1500)
          const message = response.data.status === 'error' ? this.$i18n.t('aava.login.messages.invalid_credentials') : response.data.status
          // this.$store.dispatch('addFlashMessage', {
          //   message,
          //   type: 'error',
          //   expires: 2000,
          // })
        }
      })
    },
  },
}
</script>

<style lang="scss">
.blurred-dialog {
  /* backdrop-filter: blur(4px); */
  border-radius: 20px !important;
}
.v-overlay__scrim {
  backdrop-filter: blur(4px);
  background-color: transparent !important;
}
</style>
