<template>
  <v-col
    cols="12"
    class="py-0 my-0"
  >
    <v-toolbar
      class="list-toolbar elevation-0"
      color="grey lighten-2"
      height="55"
      text
    >
      <div
        v-if="$vuetify.breakpoint.mdAndUp"
        id="dashboard-tabs"
      >
        <v-btn
          v-if="KPIDemoEnabled"
          tile
          text
          :ripple="false"
          :style="style({ id: 'kpi' })"
          :class="classes({ id: 'kpi' })"
          @click="openKPI"
        >
          KPI
        </v-btn>
        <v-btn
          v-for="tab in tabsComputed"
          :key="tab.id"
          tile
          text
          :ripple="false"
          :style="style(tab)"
          :class="classes(tab)"
          @click="openTab(tab)"
        >
          {{ tab.name }}
          <v-icon
            v-if="$vuetify.breakpoint.mdAndUp"
            class="ml-2"
            @click.stop="showTabMenu(tab, $event)"
          >
            fa-bars
          </v-icon>
        </v-btn>
      </div>

      <v-menu
        v-else
        v-model="showTabsMenu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            color="grey"
            text
            v-on="on"
          >
            {{ ($store.getters.selectedDashboardTab && $store.getters.selectedDashboardTab.name) || 'KPI' }}
            <v-icon
              small
              class="ml-2"
            >
              fa-caret-down
            </v-icon>
          </v-btn>
        </template>
        <div class="popup-items-container">
          <div
            v-if="KPIDemoEnabled"
            class="popup-menu-el"
            @click="openKPI"
          >
            KPI
          </div>
          <div
            v-for="tab in $store.state.dashboardTabs"
            :key="tab.id"
            class="popup-menu-el"
            @click="openTab(tab)"
          >
            {{ tab.name }}
          </div>
        </div>
      </v-menu>

      <v-btn
        v-if="$vuetify.breakpoint.mdAndUp"
        :class="classes()"
        :ripple="false"
        style="color: white;"
        tile
        text
        @click.stop="showAddNewPopup"
      >
        <div>
          <v-icon>
            fa-plus
          </v-icon>
        </div>
      </v-btn>
      <v-menu
        v-model="renderTabMenu"
        :position-x="formPosition.x"
        :position-y="formPosition.y"
        :z-index="160"
        :close-on-content-click="false"
        absolute
      >
        <DashboardTabMenu
          v-if="renderTabMenu"
          :tab="tabForEdit"
          :disable-delete="tabsComputed.length === 1"
          @showTabForm="showTabForm"
          @close="renderTabMenu = false"
          @deleteTab="deleteTab"
        />
      </v-menu>

      <v-menu
        v-model="renderTabForm"
        :position-x="formPosition.x"
        :position-y="formPosition.y"
        :close-on-content-click="false"
        :z-index="160"
        absolute
      >
        <DashboardTabForm
          v-if="renderTabForm"
          :item="tabForEdit"
          @close="renderTabForm = false"
          @save="saveTab"
        />
      </v-menu>
      <v-spacer />
      <DashboardShortcuts
        v-if="showShortcuts"
        :full-width="true"
      />
      <DashboardSettings />
    </v-toolbar>
  </v-col>
</template>

<script lang="ts">
import { DashboardTypes } from '../../types/DashboardTypes'
import DashboardTabForm from './DashboardTabForm.vue'
import DashboardTabMenu from './DashboardTabMenu.vue'
import Sortable from 'sortablejs'
import api from '../../store/api'
import dashboardMethods from '@/components/Dashboard/dashboardMethods'
import DashboardSettings from '@/components/Dashboard/DashboardSettings.vue'
import DashboardShortcuts from '@/components/Dashboard/DashboardShortcuts.vue'
import { AxiosResponse } from 'axios'

// TODO - on refresh chart is not showing

export default {
  name: 'DashboardTabs',

  props: {
    showShortcuts: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DashboardShortcuts,
    DashboardSettings,
    DashboardTabForm,
    DashboardTabMenu,
  },

  data () {
    return {
      renderTabForm: false,
      renderTabMenu: false,
      tabForEdit: null,
      sortable: null,
      formPosition: {
        x: 0,
        y: 0,
      },
      showTabsMenu: false,
    }
  },

  computed: {
    tabsComputed () {
      return this.tabs
    },

    tabs (): DashboardTypes.Tab[] {
      return this.$store.state.dashboardTabs
    },

    KPIDemoEnabled () {
      const isOmaInstance = window.location.host.includes('oma.aava')
      const isMetalDemo = window.location.host.includes('metal.aavaohjelmistot')
      return isOmaInstance || isMetalDemo || window.location.host.includes('localhost')
      // return true
    },
  },

  created () {
    this.createTabsSortables()
  },

  methods: {
    ...dashboardMethods,

    openTab (tab) {
      this.$store.state.selectedDashboardTabId = tab.id
    },

    openKPI () {
      this.$store.state.selectedDashboardTabId = 'kpi'
      this.$emit('showKPI')
    },

    saveTab (formData: DashboardTypes.TabFormData) {
      const tab: DashboardTypes.Tab = {
        name: formData.name,
        type: 'tab',
      }
      if (formData.id) {
        tab.id = formData.id
        const payload = {
          element_action: formData.name,
          config: ''
        }
        api.gorillaUpdateDashboardItem(formData.id, payload).then((response: AxiosResponse) => {
          this.$store.dispatch('globalErrorDisplay', { response, context: 'Update dashboard tab' })
          const i = this.getTabIndex(formData.id)
          this.tabs[i].name = formData.name
        })
      } else {
        this.saveNewTab(tab)
      }
    },

    saveNewTab (tab: DashboardTypes.Tab) {
      api.gorillaAddDashboardItem({
        type: 'tab',
        name: tab.name,
      }).then((response: AxiosResponse) => {
        this.$store.dispatch('globalErrorDisplay', { response, context: 'Add dashboard tab' })
        // As gorilla service does not return the id > have to reload all dashboard items
        // Then get the last Tab (hopefully this is the last one) and set it active
        this.refreshTabs().then(() => {
          this.setLastTabActive()
        })
      })
    },

    setFirstTabActive () {
      this.$store.state.selectedDashboardTabId = this.tabs[0]?.id
    },

    setLastTabActive () {
      this.$store.state.selectedDashboardTabId = this.tabs[this.tabs.length - 1].id
    },

    showTabForm (tab) {
      this.renderTabMenu = false
      this.tabForEdit = tab
      this.$nextTick(() => {
        this.renderTabForm = true
      })
    },

    deleteTab (tab) {
      const sameTabIsOpen = this.$store.state.selectedDashboardTabId === tab.id
      api.deleteItem('dashboard_items', tab.id).then((response: AxiosResponse) => {
        this.$store.dispatch('globalErrorDisplay', { response, context: 'Dashboard tab delete ' })
        if (!response) { return }
        this.closeMenu()
        this.$store.state.dashboardItems.filter(item => item.tabId === tab.id).forEach(item => {
          api.deleteItem('dashboard_items', item.id)
        })
        this.refreshTabs().then(() => {
          if (sameTabIsOpen) {
            this.setFirstTabActive()
          }
        })
      })
    },

    createTabsSortables () {
      this.$nextTick(() => {
        this.sortable = null
        const el = document.getElementById('dashboard-tabs')
        if (!el) { return }
        this.sortable = Sortable.create(el, {
          onEnd: this.tabDragEnd,
          delay: 50,
          animation: 200,
          draggable: '.draggable-tab'
        })
      })
    },

    tabDragEnd (move) {
      // When this.KPIDemoEnabled, decrease index by one, as KPI tab is not in the this.tabs
      // It is not draggable for Sortable, but still included in newIndex and oldIndex
      const temp = this.tabs.splice(move.oldIndex - (this.KPIDemoEnabled ? 1 : 0), 1)[0]
      this.tabs.splice(move.newIndex - (this.KPIDemoEnabled ? 1 : 0), 0, temp)
      api.saveTabsOrder(this.tabs)
    },

    getTabIndex (id) {
      return this.tabs.findIndex(t => t.id === id)
    },

    showTabMenu (tab, e) {
      this.tabForEdit = tab
      this.renderTabMenu = true
      this.formPosition = {
        x: e.clientX,
        y: e.clientY,
      }
    },

    classes (tab) {
      const classes = ['toolbar-tab', 'text-none']
      if (this.$store.state.selectedDashboardTabId === tab?.id) {
        classes.push('selected-layout-profile')
      }
      if (tab && tab.id !== 'kpi') {
        classes.push('draggable-tab')
      }
      return classes
    },

    style (tab) {
      const style: any = {
        color: 'white',
      }
      if (this.$store.state.selectedDashboardTabId === tab?.id) {
        style.color = 'black'
      }
      if (!style.background) {
        style.background = '#aaa'
      }
      return style
    },

    closeForm () {
      this.renderTabForm = false
    },

    closeMenu () {
      this.renderTabMenu = false
    },

    showAddNewPopup (e) {
      this.tabForEdit = null
      this.renderTabForm = true
      this.formPosition = {
        x: e.clientX,
        y: e.clientY,
      }
    }
  }
}
</script>
