import listViewAPI from './../../api'
import { AxiosResponse } from 'axios'

const notificationDataFromResponse = (state: any, response: any) => {
  state.noSeEditedNotification.identifier = response.identifier
  state.noSeEditedNotification.description = response.description
  state.noSeEditedNotification.object_type = response.object_type
  state.noSeEditedNotification.id = response.id

  if (response.trigger.type === 'event') {
    state.noSeEditedNotification.type = 'event'
    state.noSeEditedNotification.event = {
      object_process: response.trigger.process_name,
      process_event: response.trigger.event_name
    }
    state.noSeEditedNotification.layoutProfile = null
    state.noSeEditedNotification.checkInterval = 5
    state.noSeEditedNotification.maxNotifications = 15
  } else {
    state.noSeEditedNotification.type = 'group'
    state.noSeEditedNotification.layoutProfile = {
      id: response.trigger.layout_profile.id,
      name: response.trigger.layout_profile.name
    }
    state.noSeEditedNotification.checkInterval = response.trigger.check_interval
    state.noSeEditedNotification.maxNotifications = response.trigger.max_notifications
    state.noSeEditedNotification.event = null
  }
  state.noSeEditedRules = response.rules.map((rule, index) => {
    return {
      value: JSON.parse(rule.rule),
      removable: index > 0,
      identifier: rule.identifier,
      key: Math.random().toString(36).substring(2)
    }
  })
  if (state.noSeEditedRules.length === 0) {
    state.noSeEditedRules.push({ key: Math.random().toString(36).substring(2), value: [], removable: false })
  }

  state.noSeEditedRuleCombination = response.rule_combination === 'OR'

  state.noSeEditedMessages = response.notifications.map((message) => {
    return {
      type: message.message_type.identifier,
      subject: JSON.parse(message.subject),
      text: JSON.parse(message.message),
      identifier: message.identifier,
      recipients: message.object_based_recipients.split(','),
      key: Math.random().toString(36).substring(2)
    }
  })
}

const constructNotificationData = (state: any) => {
  const regexp = new RegExp('&nbsp;', 'g')
  const notif = state.noSeEditedNotification
  const data: any = {
    identifier: notif.identifier,
    description: notif.description,
    object_type: state.objectClass
  }
  if (notif.type === 'event') {
    data.event_trigger = {
      process_name: notif.event.object_process,
      event_name: notif.event.process_event
    }
  } else {
    data.group_trigger = {
      layout_profile: { id: notif.layoutProfile.id },
      check_interval: notif.checkInterval,
      max_notifications: notif.maxNotifications
    }
  }

  data.rules =
    state.noSeEditedRules.filter((rule) => rule.value.length > 0)
      .map((rule) => {
        return {
          rule: JSON.stringify(rule.value).replace(regexp, ''),
          identifier: rule.identifier
        }
      })
  data.rule_combination = state.noSeEditedRuleCombination === true ? 'OR' : 'AND'

  data.notifications =
    state.noSeEditedMessages.filter((msg) => msg.subject.length > 0)
      .map((message) => {
        return {
          message_type: message.type,
          subject: JSON.stringify(message.subject).replace(regexp, ''),
          message: JSON.stringify(message.text).replace(regexp, ''),
          object_based_recipients: message.recipients.toString(),
          identifier: message.identifier
        }
      })
  return data
}

export default {
  getNoSeItems: ({ state }, className: string) => {
    return new Promise(resolve => {
      state.noSeLoading = true
      listViewAPI.fetchNoSeItems(className)
        .then((response: AxiosResponse) => {
          if (response.data.status === 'ok') {
            state.noSeMyNotifications = response.data.own
            state.noSeSharedNotifications = response.data.shared
            state.noSeLoading = false
            resolve(response)
          } else {
            state.noSeLoading = false
            resolve(false)
          }
        })
    })
  },

  loadNoSeActions: ({ state }, className: string) => {
    return new Promise(resolve => {
      state.noSeLoading = true
      listViewAPI.loadNoSeActions(className)
        .then((response: AxiosResponse) => {
          if (response.data.status === 'ok') {
            state.noSeActions[className] = response.data.actions
            state.noSeLoading = false
            resolve(response.data)
          } else if (response.data.status === 'no_workflow') {
            state.noSeActions[className] = null
            resolve(null)
          } else {
            state.noSeLoading = false
            resolve(false)
          }
        })
    })
  },

  loadNoSeAttributes: ({ state }, className) => {
    return new Promise(resolve => {
      state.noSeLoading = true
      listViewAPI.loadNoSeAttributes(className)
        .then((response: AxiosResponse) => {
          if (response.data.status === 'ok') {
            state.noSeAttributes[className] = response.data.attributes
            state.noSeActionAttributes = response.data.action_values
            state.noSeFixedAttributes = response.data.fixed_values
            state.noSeLoading = false
            resolve(response.data)
          } else {
            state.noSeLoading = false
            resolve(false)
          }
        })
    })
  },

  loadNoSeRecipients: ({ state }, className) => {
    return new Promise(resolve => {
      state.noSeLoading = true
      listViewAPI.loadNoSeRecipients(className)
        .then((response: AxiosResponse) => {
          if (response.data.status === 'ok') {
            state.noSeRecipients[className] = response.data.recipients
            state.noSeLoading = false
            resolve(response.data)
          } else {
            state.noSeLoading = false
            resolve(false)
          }
        })
    })
  },

  noSeActivate: (_, { item, active }) => {
    return new Promise(resolve => {
      listViewAPI.noSeActivate(item.id, active)
        .then((response: AxiosResponse) => {
          if (response.data.status === 'ok') {
            resolve(true)
          } else {
            resolve(false)
          }
        })
    })
  },

  noSeShare: (_, { item, shared }) => {
    return new Promise(resolve => {
      listViewAPI.noSeShare(item.id, shared)
        .then((response: AxiosResponse) => {
          resolve(response.data.status === 'ok')
        })
    })
  },

  noSeSubscribe: (dispatch, { item, subscribe }) => {
    return new Promise(resolve => {
      listViewAPI.noSeSubscribe(item.id, subscribe)
        .then((response: AxiosResponse) => {
          if (response.data.status === 'ok') {
            resolve(true)
          } else {
            dispatch('showMessage', { message: response.data.msg, color: 'error' })
            resolve(false)
          }
        })
    })
  },

  noSeRemove: ({ state }, item) => {
    return new Promise(resolve => {
      listViewAPI.noSeRemove([{ key: 'ids[]', value: item.id }])
        .then((response: AxiosResponse) => {
          if (response.data.status === 'ok') {
            state.noSeMyNotifications = state.noSeMyNotifications.filter(function (i) {
              return i.id !== item.id
            })
            resolve(true)
          } else {
            resolve(false)
          }
        })
    })
  },

  noSeCreate: ({ state }) => {
    return new Promise(resolve => {
      state.noSeLoading = true
      const notificationData = constructNotificationData(state)
      notificationData.active = true

      listViewAPI.noSeCreate(notificationData)
        .then((response: AxiosResponse) => {
          state.noSeLoading = false
          if (response.data.status === 'ok') {
            state.noSeMyNotifications = state.noSeMyNotifications.concat([response.data])
            resolve(true)
          } else {
            resolve(false)
          }
        })
    })
  },

  noSeUpdate: ({ state }) => {
    return new Promise(resolve => {
      state.noSeLoading = true
      const notificationData = constructNotificationData(state)
      listViewAPI.noSeUpdate(notificationData)
        .then((response: AxiosResponse) => {
          state.noSeLoading = false
          if (response.data.status === 'ok') {
            state.noSeMyNotifications = state.noSeMyNotifications.map((notif) => {
              if (notif.identifier === response.data.identifier) {
                return response.data
              } else {
                return notif
              }
            })
            resolve(true)
          } else {
            resolve(false)
          }
        })
    })
  },

  // Start editing either a new (no id) listener or an existing one
  getNoSeItemForEdit: ({ state }, identifier: string) => {
    return new Promise(resolve => {
      state.noSeEditedNotification = {
        id: null,
        name: '',
        description: '',
        type: 'event',
        event: null,
        layoutProfile: null,
        checkInterval: 15,
        maxNotifications: 5
      }
      state.noSeEditedRules = []
      state.noSeEditedRules.push({ key: Math.random().toString(36).substring(2), value: [], removable: false })

      state.noSeEditedRuleCombination = false
      state.noSeEditedMessages = []

      if (state.selectedLayoutProfile) {
        state.noSeEditedNotification.layoutProfile = {
          id: state.selectedLayoutProfile.id,
          name: state.selectedLayoutProfile.name
        }
      }

      if (identifier === null) {
        resolve(true)
      } else {
        // existing listener, get data from backend
        state.noSeLoading = true
        listViewAPI.fetchNoSeItem(identifier)
          .then((response: AxiosResponse) => {
            state.noSeLoading = false
            if (response.data.status === 'ok') {
              notificationDataFromResponse(state, response.data.data)
              resolve(true)
            } else {
              resolve(false)
            }
          })
      }
    })
  }
}
