<template>
  <div
    class="process-events-button text-center"
    @mousedown="$emit('mousedown')"
  >
    <template v-if="item && sendingEventActionForIds.includes(item.id)">
      <v-progress-linear
        indeterminate
        background-color="grey lighten-3"
        color="grey lighten-2"
      />
    </template>
    <v-progress-circular
      v-else-if="(item && eventButtons.length && loading) || isSaving"
      :indeterminate="isSaving || (view !== 'list' && loading)"
      :size="20"
      :width="2"
    />
    <template v-else-if="item && eventButtons.length">
      <v-btn
        v-for="(eventButton, index) in eventButtons"
        :key="index"
        :title="eventButton.key ? $i18n.t(eventButton.key.replace(':', '.')) : eventButton.label"
        :disabled="disabled"
        :class="' elevation-0 mx-1 mb-0 ' + ('event-' + eventButton.identifier)"
        :loading="loading"
        color="grey lighten-2"
        x-small
        @click.stop="clickHandler(eventButton, $event)"
        @mouseup.stop=""
      >
        <v-icon
          :style="'color:' + eventButton.color"
          class="pr-1"
          x-small
        >
          fa-{{ iconName(eventButton.icon) }}
        </v-icon>
        {{ eventButton.key ? $i18n.t(eventButton.key.replace(':', '.')) : eventButton.label }}
      </v-btn>
    </template>
  </div>
</template>

<script lang="ts">
import sharedUtilities from '@/utilities/sharedUtilities'
import itemMethods, { getCombinedItemProcessEventsAndActions } from '@/methods/item/itemMethods'
import itemEventActionMethods from '@/methods/item/itemEventActionMethods'
import itemSaveQueueMethods from '@/methods/item/itemSaveQueueMethods'
import { EventBus } from '@/eventBus'
import { AxiosResponse } from 'axios'

export default {
  name: 'EventButton',

  data () {
    return {
      loading: false,
      listeningKeyEvents: false,
    }
  },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      default: () => {},
    },
    view: {
      type: String,
      default: 'item',
    },
    isActiveInListEdit: {
      type: Boolean,
      default: false,
    },
    itemIndex: {
      type: Number,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    isHasManyField: {
      type: Boolean,
      default: false,
    },
    updateItemBeforeEventAction: {
      type: Function,
      default: () => { Promise.resolve(true) },
    },
    hasManyParentId: {
      type: [String, Number],
      default: '',
    },
    hasManyParentClass: {
      type: String,
      default: '',
    },
    hasManyParentField: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    objectClass () {
      return sharedUtilities.objectClassUnderscoredName(this.item?.['@class'])
    },

    sendingEventActionForIds () {
      return this.$store.state.sendingEventActionForIds
    },

    eventButtons () {
      const filtered = getCombinedItemProcessEventsAndActions(this.item)?.filter(event => {
        return this.item[this.field.name].includes(event.identifier)
      })
      return filtered || []
    },
  },

  watch: {
    isSaving (val) {
      if (val) { return }
      this.loading = false
    },

    isActiveInListEdit (val) {
      if (val) {
        setTimeout(() => {
          this.listeningKeyEvents = true
        }, 50)
      } else {
        this.listeningKeyEvents = false
      }
    },
  },

  created () {
    if (this.view === 'list') {
      if (this.isActiveInListEdit) {
        this.listeningKeyEvents = true
      }
      document.addEventListener('keydown', this.keyListener)
    }
  },

  beforeDestroy () {
    if (this.view === 'list') {
      document.removeEventListener('keydown', this.keyListener)
    }
  },

  methods: {
    ...itemMethods,
    ...itemEventActionMethods,
    ...itemSaveQueueMethods,

    keyListener (e) {
      // Only for list edit
      if (this.view !== 'list') { return }
      if (!this.isActiveInListEdit || !this.listeningKeyEvents || !e.key) { return }
      switch (e.key) {
        case 'Enter':
          this.callFirstEventOnEnter(e)
          break
        case 'ArrowUp':
        case 'ArrowDown':
        case 'Escape':
        case 'Tab':
          e.preventDefault()
          this.$emit('keyPress', e)
          break
      }
    },

    // Call first event action when user presses Enter on active cell
    callFirstEventOnEnter (e) {
      if (this.eventButtons.length !== 1) { return }
      this.clickHandler(this.eventButtons[0], e)
      this.$emit('keyPress', { code: 'Tab' })
    },

    clickHandler (eventButton, e) {
      this.loading = true
      const callback = () => {
        this.loading = false
      }

      const parent = {
        '@class': this.hasManyParentClass,
        id: this.hasManyParentId,
      }

      // 1. Has-many edit
      if (this.isHasManyField && this.edit) {
        this.updateItemBeforeEventAction().then(updated => {
          if (!updated) { // Update error
            this.loading = false
            return
          }
          this.sendProcessEventForContentItem(eventButton, parent, this.hasManyParentField, e).then(() => {
            this.loading = false
          })
        })
      } else if (this.isHasManyField && !this.edit) {
        // 2. Has-many show
        this.$emit('click', {
          eventButton,
          field: this.field,
          parent,
          e,
          callback: (eventResponse: AxiosResponse) => {
            this.loading = false
            // Note: when requirement comes - check for specific fields in eventResponse
            if (eventResponse && 'reload_parent_values' in eventResponse.data) { // Only supports updating other has-many lists
              EventBus.$emit('callUpdateMainItemOnHasManyChildItemEventAction', {
                parentField: this.parentField,
                parent,
              })
              return
            }
          },
        })
      } else if (this.view === 'item') {
        // 4. and 5. Item show - call btn from toolbar (edit+show)
        EventBus.$emit('callEventFromItemToolbar', { eventButton, e, callback })
      } else if (this.view === 'list') {
        // 5. List edit or show
        // Add timeout so regular inputs can have change event triggered
        // Input now has it on blur(), but on some other components may be small delay until job is created
        setTimeout(() => {
          // this.loading = false
          this.$emit('click', { eventButton, callback })
        }, 200)
      }
    },
  },
}
</script>

<style lang="scss">
.process-events-button {
  padding: 4px 7px;
  button {
    magin-top: 1px !important;
  }
}
</style>
