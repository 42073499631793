import appState from '@/store/state/appState'
import listState from '@/store/state/listState'
import listLoadState from '@/store/state/listLoadState'
import listDisplayState from '@/store/state/listDisplayState'
import cardState from '@/store/state/cardState'
import noseState from '@/store/state/noseState'
import itemState from '@/store/state/itemState'
import dashboardState from '@/store/dashboardItems/dashboardState'
import layoutEditorState from '@/components/Item/LayoutEditor/layoutEditorState'
import LPState from '@/models/LayoutProfile/LP.state'
import LCState from '@/models/LayoutContainer/LC.state'
import LPIState from '@/models/LayoutProfileItem/LPI.state'
import itemPickerState from '@/components/ItemPicker/itemPicker.state'
import itemLayoutState from '@/components/Item/ItemLayout.state'

export const commonStateVarsUsed = [
  'itemLayoutEditMode',
]

export default {
  ...appState,
  ...listState,
  ...listLoadState,
  ...listDisplayState,
  ...cardState,
  ...noseState,
  ...itemState,
  ...dashboardState,
  ...layoutEditorState,
  ...LPState,
  ...LCState,
  ...LPIState,
  ...itemPickerState,
  ...itemLayoutState,
}
