<template>
  <v-container
    ref="imagesContainer"
    :class="[
      'images-gallery',
      'pa-0',
      !images.length && !loading && 'empty-list',
      isDragOver && 'drag-over',
    ]"
    fluid
    @drop.prevent="readImages($event)"
    @dragenter.prevent="handleDragEnter"
    @dragleave.prevent="handleDragLeave"
    @dragover.prevent=""
  >
    <v-menu
      v-model="showMenu"
      :position-x="pos.x"
      :position-y="pos.y"
      :z-index="1100"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item
          :href="imageLinkForDownload"
          download
        >
          <v-list-item-title>{{ $i18n.t('aava.download.title') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog
      v-if="imageDialog"
      v-model="imageDialog"
      class="white"
      fullscreen
    >
      <v-container
        class="white"
        fluid
      >
        <div
          style="position: absolute; z-index: 1000; top: 15px; right: 15px;"
          @click="imageDialog = false"
        >
          <v-btn
            class="close-img-btn"
            text
            fab
            small
            @click="imageDialog = false"
          >
            <v-icon>fa-times</v-icon>
          </v-btn>
        </div>
        <v-carousel
          v-model="carouselIndex"
          :show-arrows="images.length > 1"
          :height="carouselHeight"
          continuous
        >
          <template v-slot:prev="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>fa-angle-left</v-icon>
            </v-btn>
          </template>
          <template v-slot:next="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>fa-angle-right</v-icon>
            </v-btn>
          </template>
          <v-carousel-item
            v-for="(image, i) in images"
            :key="i"
            :src="'/content/attachments/' + image.id + '/' + image.filename"
            reverse-transition="fade-transition"
            transition="fade-transition"
            contain
            @contextmenu="showImageDownloadMenu($event, '/content/attachments/' + image.id + '/' + image.filename)"
          />
        </v-carousel>
      </v-container>
    </v-dialog>
    <div
      v-if="render"
      class="item-show-field show-field-images"
    >
      <template v-if="!isListView">
        <div
          :class="'item-show-label ' + (edit ? 'item-edit-label' : '')"
        >
          <span>{{ fieldLabel }}</span>
        </div>
        <div class="item-field-action-buttons" />
      </template>
      <div class="item-show-value images-type">
        <v-btn
          v-if="!readOnly && !isListView"
          :disabled="loading"
          :loading="loading"
          tabindex="-1"
          class="add-btn"
          color="grey"
          block
          text
          x-small
          @click="selectFile()"
        >
          <v-icon small>
            fa-plus
          </v-icon>
        </v-btn>
        <v-container class="px-0 pt-0 pb-1">
          <v-row
            id="images-gallery"
            dense
          >
            <!-- Existing or uploaded images -->

            <v-col
              v-for="(image, i2) in images"
              :key="i2"
              :cols="cols"
            >
              <!-- TODO can we have preview image ?
               :lazy-src="'/content/attachments/' + image.id + '/icon.png'"
                :src="'/content/attachments/' + image.id + '/preview.png'"
               -->
              <v-img
                :min-width="imageWidth"
                :max-width="imageWidth"
                :src="'/content/attachments/' + image.id + '/icon.png'"
                aspect-ratio="1.6"
                class="link"
                @click.stop="showImage(i2)"
                @mouseup.stop=""
              >
                <template v-slot:default>
                  <v-btn
                    v-if="!isListView && !readOnly"
                    :disabled="uploadingImages.length > 0"
                    :loading="deletingImageIds.includes(image.id)"
                    class="delete-btn elevation-0"
                    color="grey"
                    dark
                    x-small
                    fab
                    @click.stop="deleteImage(image.id)"
                  >
                    <v-icon small>
                      fa-times
                    </v-icon>
                  </v-btn>
                </template>
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-2"
                    />
                  </v-row>
                </template>
              </v-img>
            </v-col>

            <!-- Images being uploaded -->

            <v-col
              v-for="(uploadingImage, i1) in uploadingImages"
              :key="i1 + '_1'"
              :cols="cols"
            >
              <v-img
                aspect-ratio="1.6"
                min-width="100%"
                max-width="100%"
                :src="uploadingImage"
              >
                <template v-slot:default>
                  <v-row
                    class="fill-height ma-0 uploading-image-bg"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    />
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <input
      v-if="!isListView"
      id="upload_image"
      type="file"
      style="position: fixed; top: -2000px"
      tabindex="-1"
      multiple
      @change="readImages($event)"
    >
  </v-container>
</template>

<script lang="ts">
import Sortable from 'sortablejs'
import sharedUtilities from '@/utilities/sharedUtilities'
import Confirm from '@/methods/confirm'
import api from '@/store/api'
import { Field } from '@/types/FieldTypes'
import { AxiosResponse } from 'axios'

export default {
  name: 'Images',

  props: {
    value: {
      type: Array,
      default: () => {
        return []
      }
    },
    imagesReady: {
      type: Boolean,
      default: true,
    },
    fieldLabel: {
      type: String,
      default: '',
    },
    field: {
      type: Object,
      default: () => {}
    },
    forItem: {
      type: Object,
      default: () => {},
    },
    hasManyParentField: {
      type: Object,
      default: () => {},
    },
    hasManyParent: {
      type: Object,
      default: () => {},
    },
    isHasManyFieldAndNotFirstRow: {
      type: Boolean,
      default: false,
    },
    availableWidthPxForAField: {
      type: Number,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      render: true,
      uploadingImages: [],
      deletingImageIds: [],
      sortable: null,
      imageDialog: false,
      carouselIndex: 0,
      showMenu: false,
      imageLinkForDownload: null,
      isDragOver: false,
      pos: {
        x: 0,
        y: 0,
      },
    }
  },

  computed: {
    carouselHeight () {
      return this.$store.state.innerHeight - 24
    },

    imageWidth () {
      return this.isListView ? '70px' : '100%'
    },

    loading () {
      return this.uploadingImages.length > 0 || this.deletingImageIds.length > 0
    },

    cols () {
      const spaceForOneImage = 120
      if (this.isListView) { return }
      const imagesPerRow = Math.floor(this.availableWidthPxForAField / spaceForOneImage)
      return imagesPerRow > 10
        ? 1
        : imagesPerRow > 4
          ? 2
          : imagesPerRow === 4
            ? 3
            : imagesPerRow === 3
              ? 4
              : imagesPerRow === 2
                ? 6
                : 12
    },

    images: {
      get (): Field.Image[] {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },

    ready: {
      get () {
        return this.imagesReady
      },
      set (value) {
        this.$emit('ready', value)
      }
    },

    parentId (): number {
      return this.forItem && (this.forItem.id || this.forItem.token)
    },

    isListView () {
      return !this.parentId || this.isHasManyFieldAndNotFirstRow
    },
  },

  watch: {
    deletingImageIds () {
      this.ready = this.deletingImageIds.length === 0
    },
    parentId () {
      this.initialize()
    },
    imageDialog (display) {
      if (display) {
        document.addEventListener('keydown', this.keyDownListener)
      } else {
        this.removeKeyDownListener()
      }
    }
  },

  created () {
    this.initialize()
  },

  destroyed () {
    // Needed to keep things in check when using webpack dev server locally.
    this.removeKeyDownListener()
  },

  methods: {
    handleDragEnter (e: MouseEvent) {
      e.preventDefault()
      this.isDragOver = true
    },
    handleDragLeave (e: MouseEvent) {
      if (!this.$refs.imagesContainer.contains(e.relatedTarget)) {
        e.preventDefault()
        this.isDragOver = false
      }
    },

    initialize () {
      this.sortItemImages()
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.createImageSortables()
        })
      })
    },

    showImage (imageIndex) {
      this.carouselIndex = imageIndex
      this.imageDialog = true
    },

    // Sort images with order or with id if not sorted before
    // Order is new attribute and also not present for existing images
    // Function needed as API is only keeping a field 'order' but not doing the sorting of result
    sortItemImages () {
      this.images = this.images.sort((a, b) => {
        // Sort with order?
        if (a.order !== null && b.order !== null) {
          return a.order > b.order ? 1 : -1
        }
        // Sort with id
        return a.id > b.id ? 1 : -1
      })
    },

    createImageSortables () {
      this.$nextTick(() => {
        this.sortable = null
        const el = document.getElementById('images-gallery')
        if (!el) { return }
        this.sortable = Sortable.create(el, {
          onEnd: this.updateImagesOrderLocal,
          chosenClass: 'container-chosen',
          dragClass: 'container-drag',
          // handle: '.fa-bars',
        })
      })
    },

    updateImagesOrderLocal (move) {
      const temp = this.images.splice(move.oldIndex, 1)[0]
      this.images.splice(move.newIndex, 0, temp)
      this.render = false
      this.$nextTick(() => {
        this.render = true
        this.createImageSortables()
        api.saveItemImagesOrder(this.images)
      })
    },

    selectFile () {
      if (this.uploadingImages.length > 0) { return }
      const el = document.getElementById('upload_image')
      if (!el) { return }
      el.click()
    },

    deleteImage (id) {
      Confirm.request(this.$i18n.t('aava.edit.confirm_destroy_image'), () => {
        this.$set(this.deletingImageIds, this.deletingImageIds.length, id)
        this.$store.dispatch('deleteItem', {
          id,
          resource: 'attachments',
        }).then(success => {
          // Delete from deletingImageIds always, even if error
          const idIndex = this.deletingImageIds.indexOf(id)
          this.$delete(this.deletingImageIds, idIndex)
          // Delete from images array only if positive response
          if (success) {
            const imageIndex = this.images.map(image => image.id).indexOf(id)
            this.$delete(this.images, imageIndex)
          }
        })
      })
    },

    getDataURLs (e) {
      const files = e.target?.files || e.dataTransfer?.files // Select or drag n drop
      return Promise.all([...files].map(fileToDataURL => fileToDataURL))
    },

    // Upload all images and get tokens/ids
    // this must be done synchronous in order for api to return valid tokens
    // with async way tokens are returned, but can't be used on item save or when selecting country
    readImages (e) {
      this.ready = false
      this.isDragOver = false
      this.getDataURLs(e).then(selectedImages => {
        this.setTempUploadingImages(selectedImages)
        if (!selectedImages || !selectedImages[0]) { return }
        this.setTempUploadingImages(selectedImages)
        selectedImages
          .reduce((previousPromise, image) => {
            return previousPromise.then(() => {
              return this.readImage(image, e)
            })
          }, Promise.resolve()).then(() => {
            this.ready = true
          })
      })
    },

    setTempUploadingImages (dataURLs) {
      this.uploadingImages = dataURLs.map(dataURL => {
        return URL.createObjectURL(dataURL)
      })
    },

    readImage (image, e) {
      return new Promise(resolve => {
        if (!image) {
          resolve(false)
          return
        }
        // this.uploadImage = URL.createObjectURL(image)
        const reader = new FileReader()
        reader.onload = e => {
          // this.previewImage = e.target.result
          this.$store.dispatch('addNewFile', {
            file: image,
            files: this.images,
            forObjectClass: sharedUtilities.objectClassUnderscoredName(this.forItem['@class']),
            forFieldName: this.field.name,
            forObjectId: this.forItem.token || this.forItem.id,
            attachmentType: 'image',
            parentClass: this.hasManyParent?.['@class'],
            parentTokenOrId: this.hasManyParent?.token || this.hasManyParent?.id,
            parentFieldName: this.hasManyParentField?.name,
          }).then((response: AxiosResponse) => {
            this.uploadingImages.shift()
            resolve(response)
          })
        }
        reader.readAsText(image)
      })
    },

    showImageDownloadMenu (e, imageLink) {
      this.imageLinkForDownload = imageLink
      e.preventDefault()
      this.showMenu = false
      this.pos = {
        x: e.clientX,
        y: e.clientY,
      }
      this.$nextTick(() => {
        this.showMenu = true
      })
    },

    keyDownListener (e) {
      const current = this.carouselIndex
      const max = this.value.length - 1

      if (['Esc', 'Escape'].includes(e.key)) { // v-dialog does not always catch it1
        this.imageDialog = false
      } else if (e.key === 'ArrowRight') {
        this.carouselIndex = current < max ? current + 1 : 0
      } else if (e.key === 'ArrowLeft') {
        this.carouselIndex = current > 0 ? current - 1 : max
      }
    },

    removeKeyDownListener () {
      document.removeEventListener('keydown', this.keyDownListener)
    }
  }
}
</script>

<style lang="scss">
.uploading-image-bg {
  background: rgba(255, 255, 255, 0.75);
}
.images-gallery.empty-list {
  .item-show-field {
    padding: 4px 0 !important;
    height: 40px !important;
    .item-show-value {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.images-gallery.drag-over {
  .show-field-images {
    border: 2px solid #388E3C;
  }
}
.images-gallery {
  .item-show-field {
    padding: 5px 8px !important;
    .item-show-value {
      .add-btn {
        margin: 0 0 7px 0 !important;
      }
    }
  }
  .v-image {
    border-radius: 8px;
    .delete-btn {
      position: absolute;
      top: 5px;
      right: 5px;
      height: 24px;
      width: 24px;
      opacity: 0.7;
      background: rgba(0, 0, 0, 0.4) !important;
    }
  }
  .v-image:hover {
    .delete-btn:hover {
      opacity: 1;
      color: red;
      background: white !important;
    }
  }
}
.item-has-many-items {
  .images-gallery.empty-list {
    .item-show-field {
      padding: 2px 0 !important;
      height: 34px !important;
    }
  }
}
</style>
