<template>
  <div @mousedown="dragMoveCount = 0">
    <div class="item-show-field">
      <div
        v-if="fieldLabel"
        class="item-show-label"
      >
        <span>{{ fieldLabel }}</span>
      </div>
      <div
        v-if="item && !isLinkedAssociate(item['@class'])"
        :class="!isListView ? 'item-show-value' : ''"
      >
        {{ referenceLabel(item, field) }}
      </div>
      <template v-else>
        <!-- span
          Can't use this as Oma>messages/8 does not show sender value <some@name.com>. Conflict!?
          Look for a case where styling is used and then discuss how to separate these cases.
          v-html="value || '&nbsp;'"
        /-->

        <component
          :is="hasManyItemLink ? 'router-link' : 'div'"
          :to="hasManyItemLink"
          :class="'string-type ' + (!isListView ? 'item-show-value' : '')"
          :style="{
            display: isListView ? 'inline-flex' : '',
            cursor: isListView ? 'pointer' : '',
          }"
        >
          <a
            v-if="item"
            :href="referenceItemUrl"
            :class="' reference-label-inline assoc_link ' +
              (isLinkedAssociate(field.reference_class) ? '' : 'no-link-decoration')"
            :style="style"
            tabindex="-1"
            @click="disableLink ? null : checkAndStopSimpleMouseClick($event)"
            @mouseup="item ? mouseUpHandler($event) : null"
            @mousemove="dragMoveCount++"
          >
            <i
              v-if="field.widget === 'address' && isGeoCodable"
              class="fa reference-icon fa-map-marker-alt"
              @click="showAddressOnMap($event)"
              @mouseup.stop.prevent=""
            />
            <i
              v-if="field.widget === 'address' && isRoutable('office')"
              class="fa reference-icon fa-car"
              @click="showRouteOnMap($event)"
              @mouseup.stop.prevent=""
            />
            <i
              v-if="field.widget === 'address' && isRoutable('home')"
              class="fa reference-icon fa-home"
              @click="showRouteFromHomeOnMap($event)"
              @mouseup.stop.prevent=""
            />
            <svg-icon
              v-if="(field.widget !== 'address' ) && // || isGeocodable()
                !['main_object_process', 'main_object_state'].includes(field.name)"
              :path="iconPath"
              :size="23"
              :style="{
                color: '#1976D2',
                margin: isListView ? '-6px 0 -6px -4px !important' : '-6px -3px -6px -5px !important',
                padding: isListView ? '5px 0 3px 0 !important' : '4px 0 4px 0 !important',
              }"
              type="mdi"
            />
            <!-- template v-if="item" />
            <div
              v-else
              class="empty-cell-take-space"
            / -->
          </a>
          {{ value || '&nbsp;' }}
          <button
            v-if="item && item.main_object_state"
            type="button"
            tabindex="-1"
            class="reference-state-button"
            :style="referenceButtonStyle(item.main_object_state)"
          >
            {{ item.main_object_state.name }}
          </button>
        </component>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import util from '@/utilities/sharedUtilities'
import listItemCellMethods from '@/methods/listItem/listItemCellMethods'
import listItemMethods from '@/methods/listItem/listItemMethods'
import { isDarkColor } from '../../helperMethods'
import itemMethods, { getObjectStateBackgroundColor } from '@/methods/item/itemMethods'
import { mdiShare } from '@mdi/js'
import SvgIcon from '@jamescoyle/vue-icon'
import { Types } from '@/types/AppTypes'
import { LP } from '@/types/LP.types'
import { Field } from '@/types/FieldTypes'

export default {
  name: 'ContentReference',

  data () {
    return {
      // For user to be able to copy link text without opening the link
      // Because mouseup is used instead of click Aava-Vue-625
      dragMoveCount: 0,
      iconPath: mdiShare,
    }
  },

  props: {
    listItem: {
      type: Object as () => Types.Item,
      required: true,
    },
    fieldLabel: {
      type: String,
      default: '',
    },
    field: {
      type: Object as () => LP.Item,
      required: true,
    },
    itemIndex: {
      type: Number,
      default: null,
    },
    disableLink: { // In case is used in ItemPicker
      type: Boolean,
      default: false,
    },
    hasManyItemLink: {
      type: String,
      default: '',
    },
  },

  components: {
    SvgIcon,
  },

  computed: {
    isListView () {
      return !this.fieldLabel
    },

    style () {
      return this.item?.['@association_style'] || ''
    },

    isLinkToProcessOrState () {
      // Used to check if to open reference link or current item
      // for state and process type open current item
      return ['state', 'process'].includes(this.field.type)
    },

    referenceItemUrl (): string {
      if (!this.item) { return '' }
      if (this.isLinkToProcessOrState || !this.isLinkedAssociate(this.item['@class'])) {
        return '#' + util.objectClassUnderscoredName(this.$store.state.objectClass) + '/' + this.listItem.id
      }
      return '#' + util.objectClassUnderscoredName(this.item['@class']) + '/' + this.item.id
    },

    isGeoCodable () {
      return this.value &&
        this.value.length > 0 &&
        this.$store.state.systemConfigs.google_maps_api_disabled !== 'true'
    },

    item (): Types.Item {
      return this.listItem?.[this.field.name]
    },

    value () {
      return this.referenceLabel(this.listItem[this.field.name], this.field)
    },
  },

  methods: {
    ...listItemMethods,
    ...listItemCellMethods,
    ...itemMethods,

    isRoutable (from) {
      // TODO
      return false
      // return this.isGeocodable() &&
      //   from === 'home'
      //   ? App.session.home_address && App.session.home_address.length > 0
      //   : App.session.work_address && App.session.work_address.length > 0
    },

    showRouteOnMap () {
      const workAddress = '' // App.session.work_address
      this.$store.dispatch('showAddressModal', {
        address: this.value,
        from: workAddress
      })
    },

    showRouteFromHomeOnMap () {
      const homeAddress = ''
      // App.session.home_address
      this.$store.dispatch('showAddressModal', {
        address: this.value,
        from: homeAddress
      })
    },

    showAddressOnMap (event) {
      this.$store.dispatch('showAddressModal', {
        address: this.value
      })
    },

    referenceButtonStyle (objectState: Field.ObjectState) {
      const style = {} as CSSStyleDeclaration
      style.background = getObjectStateBackgroundColor(objectState) ?? ''
      if (isDarkColor(style.background)) {
        style.color = 'white'
      }
      return style
    },

    mouseUpHandler (event) {
      if (this.dragMoveCount > 5) {
        return
      }
      if (this.isLinkToProcessOrState || this.disableLink || this.$store.state.resizingHasManyField) {
        // Special handling for state as it should not be linked from the list
        // But it (ObjectState) is included in Linked Associations list
        return
      }
      event.stopPropagation()
      // Open in new tab or new window
      if (this.checkAndStopMetaKeyPress(event)) { return }
      // Open reference item in same tab
      this.openReferenceLink(
        this.item.id, {
          className: this.item['@class'],
          parentId: this.listItem.id,
          event,
          view: this.fieldLabel ? 'item' : 'list',
        }
      )
    },
  }
}
</script>
