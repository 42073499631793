<template>
  <v-container
    class="item-form-container ma-0 pa-5 align-content-start"
    fill-height
    fluid
  >
    <v-row>
      <v-col class="text-h5">
        {{ reportProps.title }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(report, index) in reportProps.reports"
        :key="index"
        cols="12"
        md="4"
        lg="3"
        sm="6"
      >
        <v-sheet class="elevation-2 fill-height">
          <div class="text-h6 px-5 pt-5 pb-3">
            {{ report.title }}
          </div>
          <v-container>
            <v-row dense>
              <CompatibilityViewReportFilter
                v-for="(filterName, fIndex) in report.availableFilters"
                :key="fIndex"
                :filter="{ name: filterName, ...reportProps.filterProps[filterName] }"
                :report="report"
              />
              <v-col>
                <v-btn
                  :disabled="!reportFiltersAreFilled(report)"
                  :dark="reportFiltersAreFilled(report)"
                  :text="!reportFiltersAreFilled(report)"
                  :loading="loadingReportById[report.id]"
                  color="green lighten-2"
                  depressed
                  block
                  @click="openReport(report)"
                >
                  Lataa
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import moment from 'moment'
import CompatibilityViewReportFilter from '@/components/App/CompatibilityViewReportFilter.vue'
import api from '@/store/api'
import { AxiosResponse } from 'axios'

export default {
  name: 'CompatibilityViewReports',

  components: {
    CompatibilityViewReportFilter,
  },

  props: {
    reportProps: {
      type: Object,
      default: () => {},
    },
    modelName: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      loadingReportById: {},
      content: '',
    }
  },

  methods: {
    reportFiltersAreFilled (report) {
      const filledCount = report.availableFilters.filter(availableFilterName => {
        return !!report.filterValues[availableFilterName]
      }).length
      return filledCount === report.availableFilters.length || (report.acceptAnyOneFilter && filledCount > 0)
    },

    openReport (report) {
      this.$set(this.loadingReportById, report.id, true)
      const url = '/compatibility/' + this.modelName
      const payload = {
        [report.filterObject]: {
          id: report.id,
        },
      }
      Object.keys(report.filterValues).forEach(filterName => {
        payload[report.filterObject][filterName] = report.filterValues[filterName]
      })

      api.sendPostRequestFormData(url, payload).then((response: AxiosResponse) => {
        this.$set(this.loadingReportById, report.id, false)
        if (response.data.base64pdf) {
          const filename = report.title + ' ' + moment().format('DD.MM.YYYY HH.mm.ss') + '.pdf'
          const link = document.createElement('a')
          link.setAttribute('href', 'data:application/pdf;base64,' + response.data.base64pdf)
          link.setAttribute('download', filename)
          const event = new MouseEvent('click')
          link.dispatchEvent(event)
        } else {
          this.$store.dispatch('showMessage', {
            message: response.data.error || 'Error',
            type: 'error'
          })
        }
      })
    },
  },
}
</script>
