<template>
  <div
    :class="'option_container' + (option.added ? ' option_added' : ' option_not_added')"
    :style="optionContainerStyle"
    @click="addDashboardItem(option)"
  >
    <div class="add_item_button_container">
      <v-icon
        small
        class="add_item_button"
      >
        fa-plus
      </v-icon>
    </div>
    <div class="add_item_text">
      {{ option.klass_name ? option.klass_name + ' - ' : '' }}
      {{ option.name }}
    </div>
  </div>
</template>

<script lang="ts">
import api from '../../store/api'
import { DashboardTypes } from '@/types/DashboardTypes'

export default {
  name: 'DashboardItemAddOption',

  props: {
    option: {
      default () { return {} },
      type: Object,
    },
    spaceAllocation: {
      default () { return {} },
      type: Object,
    },
  },

  computed: {
    optionContainerStyle () {
      return {
        width: (this.spaceAllocation.box_width / 2) - 40 + 'px' // 20px - padding
      }
    },
  },

  methods: {
    addDashboardItem (option) {
      if (option.added) {
        return
      }
      const type = option.id ? 'preview' : 'chart'
      this.$parent.$parent.hideForm()
      this.$store.state.dashboardAddingItemNow = true
      api.gorillaAddDashboardItem({
        type,
        name: option.identifier, // For chart
        layoutProfileId: option.id, // For preview list
        config: JSON.stringify({ tabId: this.$store.state.selectedDashboardTabId })
      } as DashboardTypes.PreviewOrChart).then(() => {
        this.$store.dispatch('loadAndPrepareDashboardContent')
      })
    },
  }
}
</script>

<style scoped>
  .option_container {
    margin: 10px auto;
    height: 30px;
    max-width: 178px;
    clear: both;
    padding: 3px 7px;
    cursor: pointer;
    border-radius: 4px;
    background: rgba(220, 220, 220, 0.15);
    border: 1px solid rgba(220, 220, 220, 0.5);
  }

  .option_added {
    background: white;
    border: 1px solid rgba(0, 150, 0, 0.4);
    cursor: auto;
  }

  .add_item_button {
    padding: 6px;
    font-size: 20px;
    width: 22px;
    height: 22px;
    color: #cccccc;
    margin: 0;
  }

  .add_item_button_container {
    float:left;
    width: 22px;
    height: 22px;
  }

  .add_item_text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    float:left;
    height: 30px;
    width: calc(100% - 30px);
    text-align: left;
    line-height: 24px;
    margin-left: 5px;
  }
</style>
