<template>
  <v-chip
    v-if="item.main_object_state"
    :color="getObjectStateBackgroundColor(item.main_object_state)"
    :dark="isDarkColor(getObjectStateBackgroundColor(item.main_object_state))"
    class="state-label mr-2"
    style="height: 28px;"
    label
  >
    {{ item.main_object_process && item.main_object_process.summary }} -
    {{ item.main_object_state.name }}
  </v-chip>
</template>

<script lang="ts">
import itemMethods, { getObjectStateBackgroundColor } from '@/methods/item/itemMethods'
import { isDarkColor } from '@/components/helperMethods'
import { Types } from '@/types/AppTypes'

export default {

  name: 'ItemStateLabel',

  props: {
    item: {
      type: Object as () => Types.Item,
      required: true,
    },
  },

  methods: {
    ...itemMethods,
    getObjectStateBackgroundColor,
    isDarkColor,
  },
}
</script>
