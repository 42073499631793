<template>
  <div class="tab-form white elevation-1 text-left">
    <v-container class="py-3 px-5 align-center elevation-0">
      <v-row>
        <v-col cols="12">
          <TextField
            v-model="formData.name"
            :field="{ type: 'string', name: 'name' }"
            :label="$i18n.t('aava.general.name')"
            :autofocus="true"
            @enter="saveForm"
          />
        </v-col>

        <v-col
          v-if="saving"
          cols="12"
        >
          <v-progress-linear
            :color="colors.progressBar"
            height="4"
            value="100"
            rounded
            indeterminate
          />
        </v-col>
        <template v-else>
          <v-col
            cols="6"
            class="pr-2"
          >
            <v-btn
              :color="'green'"
              depressed
              dark
              small
              block
              @click.stop="saveForm"
            >
              {{ $i18n.t('aava.actions.save') }}
            </v-btn>
          </v-col>

          <v-col
            cols="6"
            class="pl-2"
          >
            <v-btn
              :color="'grey'"
              depressed
              dark
              small
              block
              @click.stop="$emit('close')"
            >
              {{ $i18n.t('aava.actions.cancel') }}
            </v-btn>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import TextField from './../Form/TextField.vue'
import { DashboardTypes } from '../../types/DashboardTypes'

const { mapFields }: any = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'DashboardTabForm',

  components: {
    TextField,
  },

  props: {
    item: {
      type: Object,
      default: () => { return {} },
    },
  },

  data () {
    return {
      saving: false,
      formData: {
        name: '',
      } as DashboardTypes.TabFormData,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    isNew () {
      return !this.item.id
    },
  },

  watch: {
    'item.id' (value) {
      if (!value) {
        this.setFormData()
      }
    },
  },

  created () {
    this.setFormData()
  },

  methods: {
    ...methods,

    saveForm () {
      this.$emit('save', this.formData)
      this.closeMenu()
    },

    setFormData () : void {
      this.formData = {
        id: this.item?.id,
        name: this.item?.name
      }
    },

    closeMenu () {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.tab-form {
  padding: 18px 0 12px 0;
  width: 300px;
  .col {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .v-input--selection-controls {
    margin: 0;
    padding: 0;
  }
}
</style>
