<template>
  <v-row
    class="item-container-name"
  >
    <v-col
      cols="12"
      class="container-title"
    >
      <v-btn
        style="float: left;"
        class="ml-n1 mr-1 mt-0 mb-0"
        :disabled="disabled"
        icon
        small
        @click="$emit('toggle')"
      >
        <v-icon
          small
          color="#bbb"
        >
          {{ icon }}
        </v-icon>
      </v-btn>
      {{ name }}
    </v-col>
  </v-row>
</template>
<script lang="ts">
import { LP } from '@/types/LP.types'

export default {
  name: 'ContainerName',
  props: {
    container: {
      type: Object as () => LP.Container,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
